import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";
import { InputAdornment, TextField } from "@mui/material";

const ConEqpEditReport4 = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [assetType, setAssetType] = useState("");
  const [airConditioner, setAirConditioner] = useState("");
  const [cdCharge, setCdCharge] = useState("");
  const [seatCover, setSeatCover] = useState("");
  const [meterReading, setMeterReading] = useState('');
  const [fogLamps, setFogLamps] = useState("");
  const [transmission, setTransmission] = useState("");
  const [odometer, setOdometer] = useState("");
  const [accidental, setAccidental] = useState("");
  const [otherRepair, setotherRepair] = useState("");
  const [fitnessExpiry, setFitnessExpiry] = useState("");
  const [roadtaxValidity, setroadtaxValidity] = useState("");
  const [idvValue, setIdvValue] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [wheelType, setWheelType] = useState("");
  const [totalTyre, setTotalTyre] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [interiorCondition, setInteriorCondition] = useState("");
  const [interiorConditionError, setInteriorConditionError] = useState("");
  const [exteriorCondition, setExteriorCondition] = useState("");
  const [exteriorConditionError, setExteriorConditionError] = useState("");
  const [headLamp, setHeadLamp] = useState("");
  const [tailLamp, setTailLamp] = useState("");
  const [frontIndicators, setFrontIndicators] = useState("");
  const [rearIndicators, setRearIndicators] = useState("");
  const [maintenanceLevel, setMaintenanceLevel] = useState("");
  const [frontBrakeCondition, setFrontBreakCondition] = useState("");
  const [frontBrakeConditionError, setFrontBreakConditionError] = useState("");
  const [rearBrakeCondition, setRearBreakCondition] = useState("");
  const [rearBrakeConditionError, setRearBreakConditionError] = useState("");
  const [constructionEngineThere, setConstructionEngineThere] = useState("");
  const [constructionfluidLeaks, setConstructionFluidLeaks] = useState("");
  const [constructionEngineShield, setConstructionEngineShield] = useState("");
  const [
    constructionTransmissionCondition,
    setConstructionTransmissionCondition,
  ] = useState("");
  const [
    constructionTransmissionConditionError,
    setConstructionTransmissionConditionError,
  ] = useState("");
  const [constructionSuspension, setConstructionSuspension] = useState("");
  const [constructionSuspensionError, setConstructionSuspensionError] = useState("");
  const [constructionHandBrake, setConstructionHandBrake] = useState("");
  const [constructionTyreType, setConstructionTyreType] = useState("");
  const [constructionLhFront, setConstructionLhFront] = useState("");
  const [constructionLhFrontError, setConstructionLhFrontError] = useState("");
  const [constructionRhFront, setConstructionRhFront] = useState("");
  const [constructionRhFrontError, setConstructionRhFrontError] = useState("");
  const [constructionLhRear, setConstructionLhRear] = useState("");
  const [constructionLhRearError, setConstructionLhRearError] = useState("");
  const [constructionRhRear, setConstructionRhRear] = useState("");
  const [constructionRhRearError, setConstructionRhRearError] = useState("");
  const [constructionSpare, setConstructionSpare] = useState("");
  const [constructionSpareError, setConstructionSpareError] = useState("");
  const [constructionSteering, setConstructionSteering] = useState("");
  const [constructionSteeringCondition, setConstructionSteeringCondition] =
    useState("");
  const [constructionDashboard, setConstructionDashboard] = useState("");
  const [constructionSeat, setConstructionSeat] = useState("");
  const [constructionInterior, setConstructionInterior] = useState("");
  const [constructionAirbag, setConstructionAirbag] = useState("");
  const [constructionAntiLock, setConstructionAntiLock] = useState("");
  const [constructionPowerWindowFront, setConstructionPowerWindowFront] =
    useState("");
  const [constructionPowerWindowRear, setConstructionPowerWindowRear] =
    useState("");
  const [constructionSeatBelt, setConstructionSeatBelt] = useState("");
  const [
    constructionInsideRearViewMirror,
    setConstructionInsideRearViewMirror,
  ] = useState("");
  const [constructionBonnet, setConstructionBonnet] = useState("");
  const [constructionFrontBumper, setConstructionFrontBumper] = useState("");
  const [constructionRearBumper, setConstructionRearBumper] = useState("");
  const [constructionWindScreen, setConstructionWindScreen] = useState("");
  const [constructionWindScreenGlass, setConstructionWindScreenGlass] =
    useState("");
  const [constructionRearWindScreenGlass, setConstructionRearWindScreenGlass] =
    useState("");
  const [constructionRhDoorGlass, setConstructionRhDoorGlass] = useState("");
  const [constructionRhSideBody, setConstructionRhSideBody] = useState("");
  const [constructionLhSideBody, setConstructionLhSideBody] = useState("");
  const [constructionLhDoorGlass, setConstructionLhDoorGlass] = useState("");
  const [constructionOutsideRearView, setConstructionOutsideRearView] =
    useState("");
  const [constructionPaint, setConstructionPaint] = useState("");
  const [constructionPaintCondition, setConstructionPaintCondition] =
    useState("");
  const [
    constructionOverAllBodyCondition,
    setConstructionOverAllBodyCondition,
  ] = useState("");
  const [constructionBattery, setConstructionBattery] = useState("");
  const [constructionInstrumentCluster, setConstructionInstrumentCluster] =
    useState("");
  const [constructionFogLampCondition, setConstructionFogLampCondition] =
    useState("");
  const [constructionSunroof, setConstructionSunroof] = useState("");
  const [constructionMusicSyetem, setConstructionMusicSyetem] = useState("");
  const [constructionRoofRails, setConstructionRoofRails] = useState("");
  const [constructionAntenna, setConstructionAntenna] = useState("");
  const [constructionReverseCamera, setConstructionReverseCamera] =
    useState("");
  const [yourRemark, setYourRemark] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [depreciatedPrice, setDepreciatedPrice] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [vehicleEdetails, setVehicleEdetails] = useState('')
  const [btnPress, setBtnPress] = useState("");

  useEffect(() => {
    if (btnPress === true) {
      if (engineCondition === '') {
        setEngineConditionError('Please enter engine condition')
      } else {
        setEngineConditionError('')
      }
      if (interiorCondition === '') {
        setInteriorConditionError('Please enter engine condition')
      } else {
        setInteriorConditionError('')
      }
      if (exteriorCondition === '') {
        setExteriorConditionError('Please enter engine condition')
      } else {
        setExteriorConditionError('')
      }
      if (frontBrakeCondition === '') {
        setFrontBreakConditionError('Please enter engine condition')
      } else {
        setFrontBreakConditionError('')
      }
      if (rearBrakeCondition === '') {
        setRearBreakConditionError('Please enter engine condition')
      } else {
        setRearBreakConditionError('')
      }
      if (constructionTransmissionCondition === '') {
        setConstructionTransmissionConditionError('Please enter engine condition')
      } else {
        setConstructionTransmissionConditionError('')
      }
      if (constructionSuspension === '') {
        setConstructionSuspensionError('Please enter engine condition')
      } else {
        setConstructionSuspensionError('')
      }
      if (constructionLhFront === '') {
        setConstructionLhFrontError('Please enter engine condition')
      } else {
        setConstructionLhFrontError('')
      }
      if (constructionRhFront === '') {
        setConstructionRhFrontError('Please enter engine condition')
      } else {
        setConstructionRhFrontError('')
      }
      if (constructionLhRear === '') {
        setConstructionLhRearError('Please enter engine condition')
      } else {
        setConstructionLhRearError('')
      }
      if (constructionRhRear === '') {
        setConstructionRhRearError('Please enter engine condition')
      } else {
        setConstructionRhRearError('')
      }
      if (constructionSpare === '') {
        setConstructionSpareError('Please enter engine condition')
      } else {
        setConstructionSpareError('')
      }
      if (exShowRoomPrice === '') {
        setExShowRoomPriceError('Please enter engine condition')
      } else {
        setExShowRoomPriceError('')
      }
      if (estimatedPrice === '') {
        setEstimatedPriceError('Please enter engine condition')
      } else {
        setEstimatedPriceError('')
      }
    }
  }, [btnPress, engineCondition, interiorCondition, exteriorCondition, frontBrakeCondition,
    rearBrakeCondition, constructionTransmissionCondition,
    constructionLhFront, constructionRhFront, constructionLhRear,
    constructionRhRear, constructionSpare, exShowRoomPrice, estimatedPrice])

  const selectCaseType = [
    {
      id: 1,
      name: "Poor",
    },
    {
      id: 2,
      name: "Average",
    },
    {
      id: 3,
      name: "Satisfactory",
    },
    {
      id: 4,
      name: "Good",
    },
    {
      id: 5,
      name: "Excellent",
    },
  ];
  const selectCaseChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };

  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
  ];
  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const carSteeringTypeList = [
    { name: 'RACK AND PINION STEERING', id: 1 },
    { name: 'POWER STEERING', id: 2 },
    { name: 'RECIRCULATING BALL STEERING', id: 3 },
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];
  const typeList = [
    { id: 1, name: "Digital" },
    { id: 2, name: "Normal" },
  ];
  const Features = [
    { name: "Manual", id: 2 },
    { name: "Automatic", id: 3 },
  ];
  const attachment = [
    { name: "One Trolly", id: 1 },
    { name: "Two Trolly", id: 2 },
  ];

  const selectSeatCoverChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSeatCover(text);
  };
  const selectFogLampsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFogLamps(text);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmission(text);
  };
  const selectOdometerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOdometer(text);
  };
  const selectAccidentalChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAccidental(text);
  };
  const selectotherRepairlChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setotherRepair(text);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineCondition(text);
  };
  const selectWheelTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setWheelType(text);
  };
  const selectInteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setInteriorCondition(text);
  };
  const selectExteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setExteriorCondition(text);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHeadLamp(text);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTailLamp(text);
  };
  const selectFrontIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontIndicators(text);
  };
  const selectRearIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearIndicators(text);
  };
  const selectMaintenanceLevelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setMaintenanceLevel(text);
  };
  const selectFrontBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontBreakCondition(text);
  };
  const selectRearBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearBreakCondition(text);
  };
  const selectConstructionEngineThereChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionEngineThere(text);
  };
  const selectConstructionFluidLeaksChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionFluidLeaks(text);
  };
  const selectConstructionEngineShieldChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionEngineShield(text);
  };
  const selectConstructionTransmissionConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionTransmissionCondition(text);
  };
  const selectConstructionSuspensionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSuspension(text);
  };
  const selectConstructionHandBrakeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionHandBrake(text);
  };
  const selectConstructionLhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionLhFront(Number(text));
  };
  const selectConstructionRhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRhFront(Number(text));
  };
  const selectConstructionLhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionLhRear(Number(text));
  };
  const selectConstructionRhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRhRear(Number(text));
  };
  const selectConstructionSpareTyreChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSpare(Number(text));
  };
  const selectConstructionSteeringTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSteering(text);
  };
  const selectConstructionSteeringConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSteeringCondition(text);
  };
  const selectConstructionDashboardChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionDashboard(text);
  };
  const selectConstructionSeatChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSeat(text);
  };
  const selectConstructionInteriorChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionInterior(text);
  };
  const selectConstructionAirbagChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionAirbag(text);
  };
  const selectConstructionAntiLockChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionAntiLock(text);
  };
  const selectConstructionPowerWindowfrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionPowerWindowFront(text);
  };
  const selectConstructionPowerWindowRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionPowerWindowRear(text);
  };
  const selectConstructionSeatBeltChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSeatBelt(text);
  };
  const selectConstructionInsideRearViewMirrorChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionInsideRearViewMirror(text);
  };
  const selectConstructionBonnetChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionBonnet(text);
  };
  const selectConstructionFrontBumperChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionFrontBumper(text);
  };
  const selectConstructionRearBumperChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRearBumper(text);
  };
  const selectConstructionWindScreenChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionWindScreen(text);
  };
  const selectConstructionWindScreenGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionWindScreenGlass(text);
  };
  const selectConstructionRearWindScreenGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRearWindScreenGlass(text);
  };
  const selectConstructionRhDoorGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRhDoorGlass(text);
  };

  const selectConstructionLhSideBodyChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRhSideBody(text);
  };
  const selectConstructionLhSideBodysChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionLhSideBody(text);
  };
  const selectConstructionLhDoorGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionLhDoorGlass(text);
  };
  const selectConstructionOutsideRearViewChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionOutsideRearView(text);
  };
  const selectConstructionPaintChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionPaint(text);
  };
  const selectConstructionPaintConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionPaintCondition(text);
  };
  const selectConstructionOverAllBodyConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionOverAllBodyCondition(text);
  };
  const selectConstructionBatteryChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionBattery(text);
  };
  const selectConstructionInstrumentClusterChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionInstrumentCluster(text);
  };

  const selectConstructionFogLampConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionFogLampCondition(text);
  };
  const selectConstructionSunroofChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionSunroof(text);
  };
  const selectConstructionMusicSyetemChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionMusicSyetem(text);
  };
  const selectConstructionRoofRailsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionRoofRails(text);
  };
  const selectConstructionAntennaChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionAntenna(text);
  };
  const selectConstructionReverseCameraChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setConstructionReverseCamera(text);
  };
  const selectYourRemarkChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };

  console.log(constructionRhFront, "constructionRhFront");

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cases/individualcase/${params.id}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const data = resp.data.case;
        console.log(resp.data.case, "resp.data.case");
        setVehicleEdetails(data.vehicleEdetails)
        setAssetType(data.assetType);
        setAirConditioner(data.accessories.ac);
        setMeterReading(data?.meterReading);
        setCdCharge(data.accessories.cdCharger);
        setSeatCover(data.accessories.seatCover);
        setFogLamps(data.accessories.fogLamps);
        setTransmission(data.additionalFeatures.transmission);
        setOdometer(data.additionalFeatures.odometer);
        setIdvValue(data.otherDetails.IDVValue);
        setAccidental(data.otherDetails.accidental);
        setotherRepair(data.otherDetails.otherRepair);
        setFitnessExpiry(data.otherDetails.fitnessExpiry);
        setroadtaxValidity(data.otherDetails.roadTaxValidity);
        setInsuranceExpiry(data.otherDetails.insuranceExpiry);
        setEngineCondition(data.otherDetails.engineCondition);
        setWheelType(data.otherDetails.wheelsType);
        setTotalTyre(data.otherDetails.totalTyre);
        setMissingTyre(data.otherDetails.missingTyre);
        setSpareTyre(data.otherDetails.spareTyre);
        setAvailableTyre(data.otherDetails.availableTyre);
        setFrontBreakCondition(data.otherDetails.frontBrakeCondition);
        setRearBreakCondition(data.otherDetails.rearBrakeCondition);
        setInteriorCondition(data.otherDetails.interiorCondition);
        setExteriorCondition(data.otherDetails.exteriorCondition);
        setHeadLamp(data.otherDetails.headLamp);
        setTailLamp(data.otherDetails.tailLamp);
        setFrontIndicators(data.otherDetails.frontIndicators);
        setRearIndicators(data.otherDetails.rearIndicators);
        setMaintenanceLevel(data.otherDetails.levelOfMaintenance);

        setConstructionEngineThere(data.construction.engine.engine);
        setConstructionFluidLeaks(data.construction.engine.fluidLeak);
        setConstructionEngineShield(data.construction.engine.engineShield);
        setConstructionTransmissionCondition(
          data.construction.transmission.transmissionCondition
        );
        setConstructionSuspension(
          data.construction.suspension.suspensionCondition
        );
        setConstructionHandBrake(data.construction.brakes.handBrake);
        setConstructionTyreType(data.construction.TyresNConditions.type);
        setConstructionLhFront(data.construction.TyresNConditions.lhFront);
        setConstructionRhFront(data.construction.TyresNConditions.rhFront);
        setConstructionLhRear(data.construction.TyresNConditions.lhRear);
        setConstructionRhRear(data.construction.TyresNConditions.rhRear);
        setConstructionSpare(data.construction.TyresNConditions.spare);
        setConstructionSteering(data.construction.vehicleInterior.steering);
        setConstructionSteeringCondition(
          data.construction.vehicleInterior.steeringCondition
        );
        setConstructionDashboard(data.construction.vehicleInterior.dashboard);
        setConstructionSeat(data.construction.vehicleInterior.seats);
        setConstructionInterior(
          data.construction.vehicleInterior.interiorsTrims
        );
        setConstructionAirbag(data.construction.vehicleInterior.airBag);
        setConstructionAntiLock(
          data.construction.vehicleInterior.antilockBrakeSystem
        );
        setConstructionPowerWindowFront(
          data.construction.vehicleInterior.powerWindowFront
        );
        setConstructionPowerWindowRear(
          data.construction.vehicleInterior.powerWindowRear
        );
        setConstructionSeatBelt(data.construction.vehicleInterior.seatBelts);
        setConstructionInsideRearViewMirror(
          data.construction.vehicleInterior.insideRearViewMirror
        );
        setConstructionBonnet(data.construction.vehicleExterior.bonnet);
        setConstructionFrontBumper(
          data.construction.vehicleExterior.frontBumper
        );
        setConstructionRearBumper(data.construction.vehicleExterior.rearBumper);
        setConstructionWindScreen(data.construction.vehicleExterior.windScreen);
        setConstructionWindScreenGlass(
          data.construction.vehicleExterior.windscreenGlass
        );
        setConstructionRearWindScreenGlass(
          data.construction.vehicleExterior.rearWindScreenGlass
        );
        setConstructionRhDoorGlass(
          data.construction.vehicleExterior.rhDoorGlasses
        );
        setConstructionRhSideBody(data.construction.vehicleExterior.rhSideBody);
        setConstructionLhSideBody(data.construction.vehicleExterior.lhSideBody);
        setConstructionLhDoorGlass(
          data.construction.vehicleExterior.lhDoorGlasses
        );
        setConstructionOutsideRearView(
          data.construction.vehicleExterior.outsideRearViewMirror
        );
        setConstructionPaint(data.construction.vehicleExterior.paint);
        setConstructionPaintCondition(
          data.construction.vehicleExterior.paintCondition
        );
        setConstructionOverAllBodyCondition(
          data.construction.vehicleExterior.overallBodyCondition
        );
        setConstructionBattery(data.construction.electricals.battery);
        setConstructionInstrumentCluster(
          data.construction.electricals.instrumentClustor
        );
        setConstructionFogLampCondition(
          data.construction.electricals.fogLampCondition
        );
        setConstructionSunroof(data.construction.accessories.sunroof);
        setConstructionMusicSyetem(data.construction.accessories.musicStytem);
        setConstructionRoofRails(data.construction.accessories.roofRails);
        setConstructionAntenna(data.construction.accessories.antenna);
        setConstructionReverseCamera(
          data.construction.accessories.reverseCamera
        );

        setYourRemark(data.yourRemark);
        setExShowRoomPrice(data.marketPrice);
        setEstimatedPrice(data.valuation);
        let path = "";
        if (data.assetType === "Two Wheeler") {
          path = `https://instavaluer.com/twowheelerreportrelease/${params.id}`;
        } else if (data.assetType === "Four Wheeler") {
          path = `https://instavaluer.com/releasereport/${params.id}`;
        } else if (data.assetType === "Commercial") {
          path = `https://instavaluer.com/commercialreportrelease/${params.id}`;
        } else if (data.assetType === "Construction Equipment") {
          path = `https://instavaluer.com/constructionreportrelease/${params.id}`;
        } else if (data.assetType === 'Three Wheeler') {
          path = `https://instavaluer.com/threewheelerreportrelease/${params._id}`
        } else if (data.assetType === 'Farm Equipment') {
          path = `https://instavaluer.com/firmequipmentreportrelease/${params._id}`
        }
        setUrlPath(path);
        const imagePath = `https://instavaluer.com/imagereport/${params.id}`
        setImagePath(imagePath)
      }
    };
    fetchData();
  }, [params.id]);


  const handleSubmit = async () => {
    if (engineCondition === '') {
      setEngineConditionError('Please enter engine condition')
    } else {
      setEngineConditionError('')
    }
    if (interiorCondition === '') {
      setInteriorConditionError('Please enter engine condition')
    } else {
      setInteriorConditionError('')
    }
    if (exteriorCondition === '') {
      setExteriorConditionError('Please enter engine condition')
    } else {
      setExteriorConditionError('')
    }
    if (frontBrakeCondition === '') {
      setFrontBreakConditionError('Please enter engine condition')
    } else {
      setFrontBreakConditionError('')
    }
    if (rearBrakeCondition === '') {
      setRearBreakConditionError('Please enter engine condition')
    } else {
      setRearBreakConditionError('')
    }
    if (constructionTransmissionCondition === '') {
      setConstructionTransmissionConditionError('Please enter engine condition')
    } else {
      setConstructionTransmissionConditionError('')
    }
    if (constructionLhFront === '') {
      setConstructionLhFrontError('Please enter engine condition')
    } else {
      setConstructionLhFrontError('')
    }
    if (constructionRhFront === '') {
      setConstructionRhFrontError('Please enter engine condition')
    } else {
      setConstructionRhFrontError('')
    }
    if (constructionLhRear === '') {
      setConstructionLhRearError('Please enter engine condition')
    } else {
      setConstructionLhRearError('')
    }
    if (constructionRhRear === '') {
      setConstructionRhRearError('Please enter engine condition')
    } else {
      setConstructionRhRearError('')
    }
    if (constructionSpare === '') {
      setConstructionSpareError('Please enter engine condition')
    } else {
      setConstructionSpareError('')
    }
    if (exShowRoomPrice === '') {
      setExShowRoomPriceError('Please enter engine condition')
    } else {
      setExShowRoomPriceError('')
    }
    if (estimatedPrice === '') {
      setEstimatedPriceError('Please enter engine condition')
    } else {
      setEstimatedPriceError('')
    }
    if (engineCondition !== '' && interiorCondition !== '' && exteriorCondition !== '' && frontBrakeCondition !== '' &&
      rearBrakeCondition !== '' && constructionTransmissionCondition !== '' && constructionLhFront !== '' && constructionRhFront !== '' &&
      constructionLhRear !== '' && constructionRhRear !== '' && constructionSpare !== '' && exShowRoomPrice !== '' && estimatedPrice !== ''
    ) {

      const currentDate = new Date();
      const todayDate = currentDate.toISOString().slice(0, 10)

      const formData = {
        meterReading: meterReading,
        accessories: {
          ac: airConditioner,
          cdCharger: cdCharge,
          seatCover: seatCover,
          fogLamps: fogLamps,
        },
        additionalFeatures: {
          transmission: transmission,
          odometer: odometer,
        },
        otherDetails: {
          accidental: accidental,
          otherRepair: otherRepair,
          fitnessExpiry: fitnessExpiry,
          roadTaxValidity: roadtaxValidity,
          IDVValue: idvValue,
          insuranceExpiry: insuranceExpiry,
          engineCondition: engineCondition,
          frontBrakeCondition: frontBrakeCondition,
          rearBrakeCondition: rearBrakeCondition,
          wheelsType: wheelType,
          totalTyre: totalTyre,
          availableTyre: availableTyre,
          missingTyre: missingTyre,
          spareTyre: spareTyre,
          interiorCondition: interiorCondition,
          exteriorCondition: exteriorCondition,
          headLamp: headLamp,
          tailLamp: tailLamp,
          frontIndicators: frontIndicators,
          rearIndicators: rearIndicators,
          levelOfMaintenance: maintenanceLevel,
        },
        construction: {
          engine: {
            engine: constructionEngineThere,
            fluidLeak: constructionfluidLeaks,
            engineShield: constructionEngineShield,
          },
          transmission: {
            transmissionCondition: constructionTransmissionCondition,
          },
          suspension: {
            suspensionCondition: constructionSuspension,
          },
          brakes: {
            handBrake: constructionHandBrake,
          },
          TyresNConditions: {
            type: constructionTyreType,
            lhFront: constructionLhFront,
            rhFront: constructionRhFront,
            lhRear: constructionLhRear,
            rhRear: constructionRhRear,
            spare: constructionSpare,
          },
          vehicleInterior: {
            steering: constructionSteering,
            steeringCondition: constructionSteeringCondition,
            dashboard: constructionDashboard,
            seats: constructionSeat,
            interiorsTrims: constructionInterior,
            airBag: constructionAirbag,
            antilockBrakeSystem: constructionAntiLock,
            powerWindowFront: constructionPowerWindowFront,
            powerWindowRear: constructionPowerWindowRear,
            seatBelts: constructionSeatBelt,
            insideRearViewMirror: constructionInsideRearViewMirror,
          },
          vehicleExterior: {
            bonnet: constructionBonnet,
            frontBumper: constructionFrontBumper,
            rearBumper: constructionRearBumper,
            windScreen: constructionWindScreen,
            windscreenGlass: constructionWindScreenGlass,
            rearWindScreenGlass: constructionRearWindScreenGlass,
            rhDoorGlasses: constructionRhDoorGlass,
            rhSideBody: constructionRhSideBody,
            lhSideBody: constructionLhSideBody,
            lhDoorGlasses: constructionLhDoorGlass,
            outsideRearViewMirror: constructionOutsideRearView,
            paint: constructionPaint,
            paintCondition: constructionPaintCondition,
            overallBodyCondition: constructionOverAllBodyCondition,
          },
          electricals: {
            battery: constructionBattery,
            instrumentClustor: constructionInstrumentCluster,
            fogLampCondition: constructionFogLampCondition,
          },
          accessories: {
            sunroof: constructionSunroof,
            musicStytem: constructionMusicSyetem,
            roofRails: constructionRoofRails,
            antenna: constructionAntenna,
            reverseCamera: constructionReverseCamera,
          },
        },
        yourRemark: yourRemark,
        valuation: estimatedPrice,
        marketPrice: exShowRoomPrice,
        reportUrl: urlPath,
        imageUrl: imagePath,
        submittedAt: todayDate,
        status: 5,
        statusText: "Case Url Updated",
        currentForm: 4,
      };
      console.log(formData, "formData");
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        navigation(`/constructionreportrelease/${params.id}`);
      }
    } else {
      setBtnPress(true)
    }
  };

  useEffect(() => {
    if (engineCondition !== '' &&
      constructionTransmissionCondition !== '' &&
      constructionSuspension !== '' &&
      interiorCondition !== '' &&
      exteriorCondition !== '' &&
      frontBrakeCondition !== '' &&
      rearBrakeCondition !== '' &&
      constructionLhFront !== '' &&
      constructionRhFront !== '' &&
      constructionLhRear !== '' &&
      constructionRhRear !== '' &&
      exShowRoomPrice !== ''

    ) {
      const roundImageArray = [
        { label: "Poor", percentage: 2 },
        { label: "Average", percentage: 4 },
        { label: "Satisfactory", percentage: 5 },
        { label: "Good", percentage: 7 },
        { label: "Excellent", percentage: 9 },
      ];

      const findPercentageByLabel = (label) => {
        const item = roundImageArray.find(data => data.label === label);
        return item ? item.percentage : 0; // Default to 0 if not found
      };

      const findEngineCon = findPercentageByLabel(engineCondition);
      const findTransmissionCon = findPercentageByLabel(constructionTransmissionCondition);
      const findSuspensionCon = findPercentageByLabel(constructionSuspension);
      const findInteriorCon = findPercentageByLabel(interiorCondition);
      const findExteriorCon = findPercentageByLabel(exteriorCondition);

      const findBrakesCon = Math.round((frontBrakeCondition + rearBrakeCondition) / 2);
      const findTyreCon = Math.round((constructionLhFront + constructionRhFront + constructionLhRear + constructionRhRear) / 4)

      const getFinalCondition = (value) => {
        if (value <= 2) return 2;
        else if (value <= 4) return 4;
        else if (value <= 6) return 5;
        else if (value <= 8) return 7;
        else return 9;
      };

      const finalBreakCondition = getFinalCondition(findBrakesCon);
      const finalTyreCondition = getFinalCondition(findTyreCon);

      const overalRating =
        Number(findEngineCon) * 3 +
        Number(findTransmissionCon) +
        Number(findSuspensionCon) +
        Number(findInteriorCon) +
        Number(findExteriorCon) +
        Number(finalBreakCondition) +
        Number(finalTyreCondition);

      console.log(overalRating, 'overalRating');
      const overalavg = Number(overalRating) / Number(10);

      console.log(overalavg, 'overalavg');
      let deprePerYear = {};

      if (overalavg >= 8) {
        deprePerYear = { 1: 7, 2: 14, 3: 18, 4: 20, 5: 25, 6: 28, 7: 32, 8: 34, 9: 36, 10: 38, 11: 48 };
      } else if (overalavg >= 7 && overalavg < 8) {
        deprePerYear = { 1: 10, 2: 16, 3: 22, 4: 25, 5: 28, 6: 30, 7: 35, 8: 38, 9: 38, 10: 40, 11: 48 };
      } else if (overalavg >= 6 && overalavg < 7) {
        deprePerYear = { 1: 15, 2: 20, 3: 25, 4: 30, 5: 35, 6: 40, 7: 42, 8: 42, 9: 44, 10: 46, 11: 48 };
      } else if (overalavg >= 5 && overalavg < 6) {
        deprePerYear = { 1: 25, 2: 35, 3: 45, 4: 47, 5: 49, 6: 52, 7: 53, 8: 55, 9: 55, 10: 55, 11: 55 };
      }

      const mfg = vehicleEdetails.vehicleManufacturingMonthYear.slice(-4);
      const currentYear = new Date().getFullYear();
      const depreYear = currentYear - parseInt(mfg, 10);
      console.log(depreYear, 'depreYear');
      if (depreYear >= 11) {
        const percentageValue = (48 / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue));
      } else if (deprePerYear.hasOwnProperty(depreYear)) {
        const percentageValue = (deprePerYear[depreYear] / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        console.log(depreciationValue, 'depreciationValue');
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue))
      }

    }
  }, [engineCondition, constructionTransmissionCondition,
    constructionSuspension, interiorCondition, exteriorCondition,
    frontBrakeCondition, rearBrakeCondition, constructionLhFront,
    constructionRhFront, constructionLhRear, constructionRhRear,
    exShowRoomPrice
  ])

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div className="flex items-center justify-between py-2 px-5"
              style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            >
              <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                Edit Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="seatCover"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Cover *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="seatCover"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectSeatCoverChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Seat Cover
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === seatCover}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Meter Reading"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fogLamps"
                    className="text-sm font-medium text-default-900"
                  >
                    Fog Lamps *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fogLamps"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectFogLampsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Fog Lamps
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === fogLamps}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmission"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="transmission"
                    placeholder="This is a search placeholder"
                    value={Features.id}
                    name={Features.name}
                    onChange={(e) => selectTransmissionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Transmission
                    </option>
                    {Features.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === transmission}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="odometer"
                    placeholder="This is a search placeholder"
                    value={typeList.id}
                    name={typeList.name}
                    onChange={(e) => selectOdometerChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Odometer
                    </option>
                    {typeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === odometer}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="accidental"
                    className="text-sm font-medium text-default-900"
                  >
                    Accidental *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="accidental"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectAccidentalChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Accidental
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === accidental}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="otherRepair"
                    className="text-sm font-medium text-default-900"
                  >
                    Other Repair *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="otherRepair"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectotherRepairlChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Other Repair
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === otherRepair}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fitnessExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Fitness Expiry *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fitnessExpiry"
                    placeholder="Enter FitnessExpiry"
                    value={fitnessExpiry}
                    onChange={(e) => setFitnessExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="roadtaxValidity"
                    className="text-sm font-medium text-default-900"
                  >
                    Roadtax Validity *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="roadtaxValidity"
                    placeholder="Enter RoadtaxValidity"
                    value={roadtaxValidity}
                    onChange={(e) => setroadtaxValidity(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="idvValue"
                    className="text-sm font-medium text-default-900"
                  >
                    Idv Value *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="idvValue"
                    placeholder="Enter Idv Value"
                    value={idvValue}
                    onChange={(e) => setIdvValue(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="insuranceExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expiry *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="insuranceExpiry"
                    placeholder="Enter Insurance Expiry"
                    value={insuranceExpiry}
                    onChange={(e) => setInsuranceExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      engineConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="engineCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectEngineConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === engineCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="wheelType"
                    className="text-sm font-medium text-default-900"
                  >
                    Wheel Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="wheelType"
                    placeholder="This is a search placeholder"
                    value={wheelTypeList.id}
                    name={wheelTypeList.name}
                    onChange={(e) => selectWheelTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Wheel Type
                    </option>
                    {wheelTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === wheelType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="totalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Total Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="totalTyre"
                    placeholder="Enter Total Tyre"
                    value={totalTyre}
                    onChange={(e) => setTotalTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="availableTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Available Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fTotalTyre"
                    placeholder="Enter Available Tyre"
                    value={availableTyre}
                    onChange={(e) => setAvailableTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="missingTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Missing Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="missingTyre"
                    placeholder="Enter Missing Tyre"
                    value={missingTyre}
                    onChange={(e) => setMissingTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="spareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="spareTyre"
                    placeholder="Enter Spare Tyre"
                    value={spareTyre}
                    onChange={(e) => setSpareTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="interiorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      interiorConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="interiorCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectInteriorConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Interior Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === interiorCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="exteriorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Exterior Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      exteriorConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="exteriorCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectExteriorConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Exterior Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === exteriorCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="headLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Head Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="headLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectHeadLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Head Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === headLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tailLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Tail Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="tailLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTailLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tail Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === tailLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Indicators *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="frontIndicators"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectFrontIndicatorsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front Indicators
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontIndicators}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Indicators *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="rearIndicators"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectRearIndicatorsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Indicators
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearIndicators}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="maintenanceLevel"
                    className="text-sm font-medium text-default-900"
                  >
                    Maintenance Level *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="maintenanceLevel"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectMaintenanceLevelChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Maintenance Level
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === maintenanceLevel}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontBrakeCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Break Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      frontBrakeConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="frontBrakeCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectFrontBreakConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose FrontBreak Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontBrakeCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearBrakeCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Break Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      rearBrakeConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="rearBrakeCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectRearBreakConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Break Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearBrakeCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionEngineThere"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine There *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionEngineThere"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionEngineThereChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine There
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionEngineThere}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionfluidLeaks"
                    className="text-sm font-medium text-default-900"
                  >
                    Fluid Leaks *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionfluidLeaks"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionFluidLeaksChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Fluid Leaks
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionfluidLeaks}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionEngineShield"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Shield *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionEngineShield"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionEngineShieldChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Shield
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionEngineShield}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionTransmissionCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      constructionTransmissionConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionTransmissionCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) =>
                      selectConstructionTransmissionConditionChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Transmission Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={
                          test.name === constructionTransmissionCondition
                        }
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSuspension"
                    className="text-sm font-medium text-default-900"
                  >
                    Suspension Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      constructionSuspensionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionSuspension"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectConstructionSuspensionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Suspension
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionSuspension}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionHandBrake"
                    className="text-sm font-medium text-default-900"
                  >
                    Hand Brake *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionHandBrake"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionHandBrakeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Hand Brake
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionHandBrake}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionTyreType"
                    className="text-sm font-medium text-default-900"
                  >
                    Tyre Type *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="constructionTyreType"
                    placeholder="Enter Car Tyre Type"
                    value={constructionTyreType}
                    onChange={(e) => setConstructionTyreType(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Lh Front *
                  </label>
                  <select
                    className={
                      constructionLhFrontError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionLhFront"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectConstructionLhFrontChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Lh Front
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === constructionLhFront}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Rh Front *
                  </label>
                  <select
                    className={
                      constructionRhFrontError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionRhFront"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectConstructionRhFrontChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rh Front
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === constructionRhFront}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Lh Rear *
                  </label>
                  <select
                    className={
                      constructionLhRearError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionLhRear"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectConstructionLhRearChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Lh Rear
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === constructionLhRear}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Rh Rear *
                  </label>
                  <select
                    className={
                      constructionRhRearError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionRhRear"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectConstructionRhRearChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rh Rear
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === constructionRhRear}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSpareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre *
                  </label>
                  <select
                    className={
                      constructionSpareError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="constructionSpareTyre"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectConstructionSpareTyreChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Spare Tyre
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === constructionSpare}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSteeringType"
                    className="text-sm font-medium text-default-900"
                  >
                    Steering  Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionSteeringType"
                    placeholder="This is a search placeholder"
                    value={carSteeringTypeList.id}
                    name={carSteeringTypeList.name}
                    onChange={(e) =>
                      selectConstructionSteeringTypeChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Steering Type
                    </option>
                    {carSteeringTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionSteering}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSteeringCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Steering Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionSteeringCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) =>
                      selectConstructionSteeringConditionChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Steering Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionSteeringCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionDashboard"
                    className="text-sm font-medium text-default-900"
                  >
                    Dashboard *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionDashboard"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionDashboardChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Dashboard
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionDashboard}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSeat"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionSeat"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionSeatChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Seat
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionSeat}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionInterior"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionInterior"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionInteriorChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Interior
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionInterior}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionAirbag"
                    className="text-sm font-medium text-default-900"
                  >
                    Airbag *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionAirbag"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionAirbagChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Airbag
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionAirbag}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionAntiLock"
                    className="text-sm font-medium text-default-900"
                  >
                    AntiLock *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionAntiLock"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionAntiLockChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose AntiLock
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionAntiLock}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="constructionPowerWindowFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Window Front Available *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionPowerWindowFront"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionPowerWindowfrontChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Power Window Front
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionPowerWindowFront}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionPowerWindowRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Window Rear Available *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionPowerWindowRear"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionPowerWindowRearChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Power Window Rear
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionPowerWindowRear}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSeatBelt"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Belt *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionSeatBelt"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionSeatBeltChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Seat Belt
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionSeatBelt}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionInsideRearViewMirror"
                    className="text-sm font-medium text-default-900"
                  >
                    Inside Rear View Mirror Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionInsideRearViewMirror"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) =>
                      selectConstructionInsideRearViewMirrorChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Inside Rear View Mirror Condition
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={
                          test.name === constructionInsideRearViewMirror
                        }
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionBonnet"
                    className="text-sm font-medium text-default-900"
                  >
                    Bonnet *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionBonnet"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionBonnetChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Bonnet
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionBonnet}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionFrontBumper"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Bumper *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionFrontBumper"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionFrontBumperChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Bonnet
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionFrontBumper}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRearBumper"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Bumper *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionRearBumper"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionRearBumperChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Bumper
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionRearBumper}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionWindScreen"
                    className="text-sm font-medium text-default-900"
                  >
                    Wind Screen *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionWindScreen"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionWindScreenChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Wind Screen
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionWindScreen}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionWindScreenGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Wind Screen Glass *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionWindScreenGlass"
                    placeholder="This is a search placeholder"
                    value={qualityList.id}
                    name={qualityList.name}
                    onChange={(e) => selectConstructionWindScreenGlassChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Wind Screen Glass
                    </option>
                    {qualityList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionWindScreenGlass}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRearWindScreenGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Wind Screen Glass *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionRearWindScreenGlass"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) =>
                      selectConstructionRearWindScreenGlassChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Rear Wind Screen Glass
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionRearWindScreenGlass}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhDoorGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    RhDoor Glass *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionRhDoorGlass"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionRhDoorGlassChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose RhDoor Glass
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionRhDoorGlass}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRhSideBody"
                    className="text-sm font-medium text-default-900"
                  >
                    RhSide Body *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionRhSideBody"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionLhSideBodyChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose RhSide Body
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionRhSideBody}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhSideBody"
                    className="text-sm font-medium text-default-900"
                  >
                    LhSide Body *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionLhSideBody"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionLhSideBodysChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose LhSide Body
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionLhSideBody}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionLhDoorGlass"
                    className="text-sm font-medium text-default-900"
                  >
                    LhDoor Glass *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionLhDoorGlass"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionLhDoorGlassChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose LhDoor Glass
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionLhDoorGlass}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionOutsideRearView"
                    className="text-sm font-medium text-default-900"
                  >
                    Outside Rear View *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionOutsideRearView"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionOutsideRearViewChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Outside Rear View
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionOutsideRearView}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionPaint"
                    className="text-sm font-medium text-default-900"
                  >
                    Paint *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionPaint"
                    placeholder="This is a search placeholder"
                    value={qualityList.id}
                    name={qualityList.name}
                    onChange={(e) => selectConstructionPaintChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Paint
                    </option>
                    {qualityList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionPaint}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionPaintCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Paint Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionPaintCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionPaintConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Paint Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionPaintCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionOverAllBodyCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Over AllBody Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionOverAllBodyCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) =>
                      selectConstructionOverAllBodyConditionChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Over AllBody Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={
                          test.name === constructionOverAllBodyCondition
                        }
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionBattery"
                    className="text-sm font-medium text-default-900"
                  >
                    Battery *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionBattery"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectConstructionBatteryChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Battery
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionBattery}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionInstrumentCluster"
                    className="text-sm font-medium text-default-900"
                  >
                    Instrument Cluster *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionInstrumentCluster"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) =>
                      selectConstructionInstrumentClusterChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose Instrument Cluster
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionInstrumentCluster}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="constructionFogLampCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    FogLamp Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionFogLampCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) =>
                      selectConstructionFogLampConditionChange(e)
                    }
                  >
                    <option value="0" hidden selected>
                      Choose FogLamp Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionFogLampCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionSunroof"
                    className="text-sm font-medium text-default-900"
                  >
                    Sunroof *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionSunroof"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionSunroofChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Sunroof
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionSunroof}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionMusicSyetem"
                    className="text-sm font-medium text-default-900"
                  >
                    Music Syetem *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionMusicSyetem"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionMusicSyetemChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Music Syetem
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionMusicSyetem}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionRoofRails"
                    className="text-sm font-medium text-default-900"
                  >
                    Roof Rails *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionRoofRails"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionRoofRailsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Roof Rails
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionRoofRails}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionAntenna"
                    className="text-sm font-medium text-default-900"
                  >
                    Antenna *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionAntenna"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionAntennaChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Antenna
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionAntenna}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="constructionReverseCamera"
                    className="text-sm font-medium text-default-900"
                  >
                    Reverse Camera *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="constructionReverseCamera"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectConstructionReverseCameraChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Reverse Camera
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === constructionReverseCamera}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Your Remark On Vehicle *
                  </label>
                  <input
                    type="text"
                    className={
                      `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter your remark"
                    value={yourRemark}
                    onChange={(e) => setYourRemark(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Ex-ShowRoom Price <span style={{ color: 'red' }}>**</span>
                  </label>
                  <input
                    type="text"
                    className={
                      exShowRoomPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter Ex-ShowRoom Price"
                    value={exShowRoomPrice}
                    onChange={(e) => setExShowRoomPrice(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEstimatedPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Estimated Price <span style={{ color: 'red' }}>**</span>
                  </label>
                  <input
                    type="text"
                    className={
                      estimatedPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fEstimatedPrice"
                    placeholder="Enter Estimated Price"
                    value={estimatedPrice}
                    onChange={(e) => setEstimatedPrice(e.target.value)}
                  />
                  {
                    depreciatedPrice ?
                      <div>The recommended estimated price for the vehicle is <span style={{ color: 'red' }}>₹{depreciatedPrice}</span></div> : <></>
                  }
                </div>
              </div>
              <div
                className="flex items-center gap-2 justify-end"
                style={{ marginBottom: "80px" }}
              >
                <button
                  style={{
                    background: "#ef4444",
                    color: "#fff",
                    border: "none",
                    outline: "none",
                  }}
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                  onClick={() => navigation(`/admin/editreport3/${params.id}`)}
                >
                  <i className="ph-bold ph-x text-lg" />
                  Go Back
                </button>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => handleSubmit()}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  View Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConEqpEditReport4;
