import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  isBrandLoading: true,
  brandLogo: "",
  logoId:""
};

export const uploadBrandLogo = createAsyncThunk(
  "logo/brandLogo",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brandlogo/uploadlogo`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      thunkAPI.rejectWithValue("Brand image not able to upload");
    }
  }
);
export const createBrandLogo = createAsyncThunk(
  "logo/createBrandLogo",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brandlogo/createlogo`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      thunkAPI.rejectWithValue("Brand image not able to upload");
    }
  }
);
export const getLogo = createAsyncThunk(
  "logo/getLogo",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/brandlogo/getlogo`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Brand Logo not able to fetch");
    }
  }
);
export const updateLogo=createAsyncThunk(
  "logo/updateLogo",
  async(formData,thunkAPI)=>{
    try{
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brandlogo/updatelogo/${formData._id}`;
      const resp = await axios.put(url,formData,config);
      return resp.data;
    }catch(error){
      return thunkAPI.rejectWithValue("Brand Logo not able to update");
    }
  }
)

const logoSlice = createSlice({
  name: "brandLogo",
  initialState,
  reducers: {
    removeBrndLogo(state) {
      state.isBrandLoading = true;
      state.brandLogo = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadBrandLogo.pending, (state) => {
        state.isBrandLoading = true;
      })
      .addCase(uploadBrandLogo.fulfilled, (state, action) => {
        state.brandLogo = action.payload.logo;
        state.isBrandLoading = false;
      })
      .addCase(uploadBrandLogo.rejected, (state) => {
        state.isBrandLoading = true;
      })
      .addCase(createBrandLogo.pending, (state) => {
        state.isBrandLoading = true;
      })
      .addCase(createBrandLogo.fulfilled, (state, action) => {
        state.brandLogo = action.payload.logo;
        state.isBrandLoading = false;
      })
      .addCase(createBrandLogo.rejected, (state) => {
        state.isBrandLoading = true;
      })
      .addCase(getLogo.pending,(state)=>{
        state.isBrandLoading=true
      })
      .addCase(getLogo.fulfilled,(state,action)=>{
        state.brandLogo=action.payload.logo[0].logo
        state.logoId=action.payload.logo[0]._id
        state.isBrandLoading=false
      })
      .addCase(getLogo.rejected,(state)=>{
        state.isBrandLoading=true
      })
      .addCase(updateLogo.pending,(state)=>{
        state.isBrandLoading=true
      })
      .addCase(updateLogo.fulfilled,(state,action)=>{
        state.brandLogo=action.payload.logo.logo;
        state.isBrandLoading=false
      })
      .addCase(updateLogo.rejected,(state)=>{
        state.isBrandLoading=true
      })
  },
});

export const logoActions = logoSlice.actions;
export default logoSlice.reducer;
