import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import { InputAdornment, TextField } from "@mui/material";

const FourWheelerEditReport4 = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [assetType, setAssetType] = useState("");
  const [airConditioner, setAirConditioner] = useState("");
  const [cdCharge, setCdCharge] = useState("");
  const [seatCover, setSeatCover] = useState("");
  const [meterReading, setMeterReading] = useState('');
  const [fogLamps, setFogLamps] = useState("");
  const [transmission, setTransmission] = useState("");
  const [odometer, setOdometer] = useState("");
  const [accidental, setAccidental] = useState("");
  const [otherRepair, setotherRepair] = useState("");
  const [fitnessExpiry, setFitnessExpiry] = useState("");
  const [roadtaxValidity, setroadtaxValidity] = useState("");
  const [idvValue, setIdvValue] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [wheelType, setWheelType] = useState("");
  const [totalTyre, setTotalTyre] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [interiorCondition, setInteriorCondition] = useState("");
  const [interiorConditionError, setInteriorConditionError] = useState("");
  const [exteriorCondition, setExteriorCondition] = useState("");
  const [exteriorConditionError, setExteriorConditionError] = useState("");
  const [headLamp, setHeadLamp] = useState("Satisfactory");
  const [tailLamp, setTailLamp] = useState("Satisfactory");
  const [frontIndicators, setFrontIndicators] = useState("Satisfactory");
  const [rearIndicators, setRearIndicators] = useState("Satisfactory");
  const [maintenanceLevel, setMaintenanceLevel] = useState("Satisfactory");
  const [frontBreakCondition, setFrontBreakCondition] = useState("Satisfactory");
  const [frontBreakConditionError, setFrontBreakConditionError] = useState("");
  const [rearBreakCondition, setRearBreakCondition] = useState("Satisfactory");
  const [rearBreakConditionError, setRearBreakConditionError] = useState("");
  const [engineThere, setEngineThere] = useState("");
  const [fluidLeaks, setFluidLeaks] = useState("");
  const [engineShield, setEngineShield] = useState("Satisfactory");
  const [transmissionCondition, setTransmissionCondition] = useState("Satisfactory");
  const [transmissionConditionError, setTransmissionConditionError] = useState("");
  const [suspension, setSuspension] = useState("Satisfactory");
  const [suspensionError, setSuspensionError] = useState("");
  const [handBrake, setHandBrake] = useState("Satisfactory");
  const [carTyreType, setCarTyreType] = useState("");
  const [carLhFront, setCarLhFront] = useState("");
  const [carLhFrontError, setCarLhFrontError] = useState("");
  const [carRhFront, setCarRhFront] = useState("");
  const [carRhFrontError, setCarRhFrontError] = useState("");
  const [carLhRear, setCarLhRear] = useState("");
  const [carLhRearError, setCarLhRearError] = useState("");
  const [carRhRear, setCarRhRear] = useState("");
  const [carRhRearError, setCarRhRearError] = useState("");
  const [carSpareTyre, setCarSpareTyre] = useState("");
  const [carSpareTyreError, setCarSpareTyreError] = useState("");
  const [carSteering, setCarSteering] = useState("");
  const [carSteeringCondition, setCarSteeringCondition] = useState("Satisfactory");
  const [carDashboard, setCarDashboard] = useState("Satisfactory");
  const [carSeat, setCarSeat] = useState("Satisfactory");
  const [carInterior, setCarInterior] = useState("Satisfactory");
  const [carAirbag, setCarAirbag] = useState("");
  const [carAntiLock, setCarAntiLock] = useState("");
  const [carPowerWindowFront, setCarPowerWindowFront] = useState("");
  const [carPowerWindowRear, setCarPowerWindowRear] = useState("");
  const [carSeatBelt, setCarSeatBelt] = useState("");
  const [carInsideRearViewMirror, setCarInsideRearViewMirror] = useState("");
  const [carBonnet, setCarBonnet] = useState("Satisfactory");
  const [carFrontBumper, setCarFrontBumper] = useState("Satisfactory");
  const [carRearBumper, setCarRearBumper] = useState("Satisfactory");
  const [carWindScreen, setCarWindScreen] = useState("Satisfactory");
  const [carWindScreenGlass, setCarWindScreenGlass] = useState("");
  const [carRearWindScreenGlass, setCarRearWindScreenGlass] = useState("Satisfactory");
  const [carRhDoorGlass, setCarRhDoorGlass] = useState("Satisfactory");
  const [carRhSideBody, setCarRhSideBody] = useState("Satisfactory");
  const [carLhSideBody, setCarLhSideBody] = useState("Satisfactory");
  const [carLhDoorGlass, setCarLhDoorGlass] = useState("Satisfactory");
  const [carOutsideRearView, setcarOutsideRearView] = useState("Satisfactory");
  const [carPaint, setcarPaint] = useState("");
  const [carPaintCondition, setcarPaintCondition] = useState("Satisfactory");
  const [carOverAllBodyCondition, setcarOverAllBodyCondition] = useState("Satisfactory");
  const [carBattery, setcarBattery] = useState("Satisfactory");
  const [carInstrumentCluster, setCarInstrumentCluster] = useState("Satisfactory");
  const [carFogLamp, setCarFogLamp] = useState("");
  const [carFogLampCondition, setCarFogLampCondition] = useState("Satisfactory");
  const [carSunroof, setCarSunroof] = useState("");
  const [carMusicSyetem, setCarMusicSyetem] = useState("");
  const [carRoofRails, setCarRoofRails] = useState("");
  const [carAntenna, setCarAntenna] = useState("");
  const [carReverseCamera, setCarReverseCamera] = useState("");
  const [yourRemark, setYourRemark] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [depreciatedPrice, setDepreciatedPrice] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [imagePath, setImagePath] = useState("");

  const [pressBtn, setPressBtn] = useState(false);
  const [vehicleEdetails, setVehicleEdetails] = useState('')


  useEffect(() => {
    if (pressBtn) {
      if (engineCondition === '') {
        setEngineConditionError('Please enter engine condition')
      } else {
        setEngineConditionError('')
      }
      if (interiorCondition === '') {
        setInteriorConditionError('Please enter engine condition')
      } else {
        setInteriorConditionError('')
      }
      if (exteriorCondition === '') {
        setExteriorConditionError('Please enter engine condition')
      } else {
        setExteriorConditionError('')
      }
      if (frontBreakCondition === '') {
        setFrontBreakConditionError('Please enter engine condition')
      } else {
        setFrontBreakConditionError('')
      }
      if (rearBreakCondition === '') {
        setRearBreakConditionError('Please enter engine condition')
      } else {
        setRearBreakConditionError('')
      }
      if (transmissionCondition === '') {
        setTransmissionConditionError('Please enter engine condition')
      } else {
        setTransmissionConditionError('')
      }
      if (suspension === '') {
        setSuspensionError('Please enter engine condition')
      } else {
        setSuspensionError('')
      }
      if (carLhFront === "") {
        setCarLhFrontError('Please enter lh front tyre condition')
      } else {
        setCarLhFrontError('')
      }
      if (exShowRoomPrice === "") {
        setExShowRoomPriceError("Please enter Exshowroom Price");
      } else {
        setExShowRoomPriceError("");
      }
      if (estimatedPrice === "") {
        setEstimatedPriceError("Please enter Estimated Price");
      } else {
        setEstimatedPriceError("");
      }
    }
  }, [pressBtn, engineCondition, interiorCondition, exteriorCondition, frontBreakCondition, rearBreakCondition, transmissionCondition, suspension, carLhFront, exShowRoomPrice, estimatedPrice]);
  const selectCaseType = [
    {
      id: 1,
      name: "Poor",
    },
    {
      id: 2,
      name: "Average",
    },
    {
      id: 3,
      name: "Satisfactory",
    },
    {
      id: 4,
      name: "Good",
    },
    {
      id: 5,
      name: "Excellent",
    },
  ];
  const selectCaseChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };
  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 }
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];
  const typeList = [
    { id: 1, name: "Manual" },
    { id: 2, name: "Auto" },
  ];
  const Features = [
    { name: "Manual", id: 1 },
    { name: "Automatic", id: 2 },
  ];
  const Odometer = [
    { name: "Digital", id: 1 },
    { name: "Normal", id: 2 },
  ];
  const attachment = [
    { name: "One Trolly", id: 1 },
    { name: "Two Trolly", id: 2 },
  ];
  const selectAirConditionerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAirConditioner(text);
  };
  const selectCdChargeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCdCharge(text);
  };
  const selectSeatCoverChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSeatCover(text);
  };
  const selectFogLampsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFogLamps(text);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmission(text);
  };
  const selectOdometerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOdometer(text);
  };
  const selectAccidentalChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAccidental(text);
  };
  const selectotherRepairlChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setotherRepair(text);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineCondition(text);
  };
  const selectWheelTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setWheelType(text);
  };
  const selectInteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setInteriorCondition(text);
  };
  const selectExteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setExteriorCondition(text);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHeadLamp(text);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTailLamp(text);
  };
  const selectFrontIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontIndicators(text);
  };
  const selectRearIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearIndicators(text);
  };
  const selectMaintenanceLevelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setMaintenanceLevel(text);
  };
  const selectFrontBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontBreakCondition(text);
  };
  const selectRearBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearBreakCondition(text);
  };
  const selectEngineThereChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineThere(text);
  };
  const selectFluidLeaksChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFluidLeaks(text);
  };
  const selectEngineShieldChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineShield(text);
  };
  const selectTransmissionConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmissionCondition(text);
  };
  const selectSuspensionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSuspension(text);
  };
  const selectHandBrakeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHandBrake(text);
  };
  const selectCarLhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarLhFront(text);
  };
  const selectCarRhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRhFront(text);
  };
  const selectCarLhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarLhRear(text);
  };
  const selectCarRhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRhRear(text);
  };
  const selectCarSpareTyreChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarSpareTyre(text);
  };
  const selectCarSteeringConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarSteeringCondition(text);
  };
  const selectCarDashboardChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarDashboard(text);
  };
  const selectCarSeatChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarSeat(text);
  };
  const selectCarInteriorChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarInterior(text);
  };
  const selectCarAirbagChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarAirbag(text);
  };
  const selectCarAntiLockChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarAntiLock(text);
  };
  const selectCarPowerWindowFront = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarPowerWindowFront(text);
  };
  const selectCarPowerWindowRear = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarPowerWindowRear(text);
  };
  const selectCarSeatBeltChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarSeatBelt(text);
  };
  const selectCarInsideRearViewMirrorChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarInsideRearViewMirror(text);
  };
  const selectCarBonnetChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarBonnet(text);
  };
  const selectCarFrontBumperChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarFrontBumper(text);
  };
  const selectCarRearBumperChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRearBumper(text);
  };
  const selectCarWindScreenChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarWindScreen(text);
  };
  const selectCarWindScreenGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarWindScreenGlass(text);
  };
  const selectCarRearWindScreenGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRearWindScreenGlass(text);
  };
  const selectCarRhDoorGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRhDoorGlass(text);
  };
  const selectCarRhSideBodyChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRhSideBody(text);
  };
  const selectCarLhSideBodyChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarLhSideBody(text);
  };
  const selectCarLhDoorGlassChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarLhDoorGlass(text);
  };
  const selectcarOutsideRearViewChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setcarOutsideRearView(text);
  };
  const selectcarPaintChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setcarPaint(text);
  };
  const selectcarPaintConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setcarPaintCondition(text);
  };
  const selectcarOverAllBodyConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setcarOverAllBodyCondition(text);
  };
  const selectcarBatteryChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setcarBattery(text);
  };
  const selectCarInstrumentClusterChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarInstrumentCluster(text);
  };
  const selectCarFogLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarFogLamp(text);
  };
  const selectCarFogLampConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarFogLampCondition(text);
  };
  const selectCarSunroofChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarSunroof(text);
  };
  const selectCarMusicSyetemChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarMusicSyetem(text);
  };
  const selectCarRoofRailsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarRoofRails(text);
  };
  const selectCarAntennaChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarAntenna(text);
  };
  const selectCarReverseCameraChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCarReverseCamera(text);
  };
  const selectYourRemarkChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cases/individualcase/${params.id}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const data = resp.data.case;
        console.log(resp.data.case, "resp.data.case");
        setVehicleEdetails(data.vehicleEdetails)
        setAssetType(data.assetType);
        setAirConditioner(data.accessories.ac);
        setCdCharge(data.accessories.cdCharger);
        setMeterReading(data?.meterReading);
        setSeatCover(data.accessories.seatCover);
        setFogLamps(data.accessories.fogLamps);
        setTransmission(data.additionalFeatures.transmission);
        setOdometer(data.additionalFeatures.odometer);
        setAccidental(data.otherDetails.accidental);
        setotherRepair(data.otherDetails.otherRepair);
        setFitnessExpiry(data.otherDetails.fitnessExpiry);
        setroadtaxValidity(data.otherDetails.roadTaxValidity);
        setIdvValue(data.otherDetails.IDVValue);
        setInsuranceExpiry(data.otherDetails.insuranceExpiry);
        setEngineCondition(data.otherDetails.engineCondition);
        setWheelType(data.otherDetails.wheelsType);
        setTotalTyre(data.otherDetails.totalTyre);
        setAvailableTyre(data.otherDetails.availableTyre);
        setMissingTyre(data.otherDetails.missingTyre);
        setSpareTyre(data.otherDetails.spareTyre);
        setInteriorCondition(data.otherDetails.interiorCondition);
        setExteriorCondition(data.otherDetails.exteriorCondition);
        setHeadLamp(data.otherDetails.headLamp);
        setTailLamp(data.otherDetails.tailLamp);
        setFrontIndicators(data.otherDetails.frontIndicators);
        setRearIndicators(data.otherDetails.rearIndicators);
        setMaintenanceLevel(data.otherDetails.levelOfMaintenance);

        setFrontBreakCondition(data.otherDetails.frontBrakeCondition);
        setRearBreakCondition(data.otherDetails.rearBrakeCondition);

        setEngineThere(data.privateCar.engine.engine);
        setFluidLeaks(data.privateCar.engine.fluidLeak);
        setEngineShield(data.privateCar.engine.engineShield);
        setTransmissionCondition(
          data.privateCar.transmission.transmissionCondition
        );
        setSuspension(data.privateCar.suspension.suspensionCondition);
        setHandBrake(data.privateCar.brakes.handBrake);
        setCarTyreType(data.privateCar.TyresNConditions.type);
        setCarLhFront(data.privateCar.TyresNConditions.lhFront);
        setCarRhFront(data.privateCar.TyresNConditions.rhfront);
        setCarLhRear(data.privateCar.TyresNConditions.lhRear);
        setCarRhRear(data.privateCar.TyresNConditions.rhRear);
        setCarSpareTyre(data.privateCar.TyresNConditions.spareTyre);
        setCarSteering(data.privateCar.vehicleInterior.steering);
        setCarSteeringCondition(
          data.privateCar.vehicleInterior.steeringCondition
        );
        setCarDashboard(data.privateCar.vehicleInterior.dashboard);
        setCarSeat(data.privateCar.vehicleInterior.seats);
        setCarInterior(data.privateCar.vehicleInterior.interiorsTrims);
        setCarAirbag(data.privateCar.vehicleInterior.airBag);
        setCarAntiLock(data.privateCar.vehicleInterior.antilockBrakeSystem);
        setCarPowerWindowFront(
          data.privateCar.vehicleInterior.powerWindowFront
        );
        setCarPowerWindowRear(data.privateCar.vehicleInterior.powerWindowRear);
        setCarSeatBelt(data.privateCar.vehicleInterior.seatBelts);
        setCarInsideRearViewMirror(
          data.privateCar.vehicleInterior.insideRearViewMirror
        );
        setCarBonnet(data.privateCar.vehicleExterior.bonnet);
        setCarFrontBumper(data.privateCar.vehicleExterior.frontBumper);
        setCarRearBumper(data.privateCar.vehicleExterior.rearBumper);
        setCarWindScreen(data.privateCar.vehicleExterior.windScreen);
        setCarWindScreenGlass(data.privateCar.vehicleExterior.windscreenGlass);
        setCarRearWindScreenGlass(
          data.privateCar.vehicleExterior.rearWindScreen
        );
        setCarRhDoorGlass(data.privateCar.vehicleExterior.rhDoorGlasses);
        setCarRhSideBody(data.privateCar.vehicleExterior.rhSideBody);
        setCarLhSideBody(data.privateCar.vehicleExterior.lhSideBody);
        setCarLhDoorGlass(data.privateCar.vehicleExterior.lhDoorGlasses);
        setcarOutsideRearView(
          data.privateCar.vehicleExterior.outsideRearViewMirror
        );
        setcarPaint(data.privateCar.vehicleExterior.paint);
        setcarPaintCondition(data.privateCar.vehicleExterior.paintCondition);
        setcarOverAllBodyCondition(
          data.privateCar.vehicleExterior.overallBodyCondition
        );
        setcarBattery(data.privateCar.electricals.battery);
        setCarInstrumentCluster(data.privateCar.electricals.instrumentClustor);
        setCarFogLamp(data.privateCar.electricals.fogLamps);
        setCarFogLampCondition(data.privateCar.electricals.fogLampCondition);
        setCarSunroof(data.privateCar.accessories.sunroof);
        setCarMusicSyetem(data.privateCar.accessories.musicStytem);
        setCarRoofRails(data.privateCar.accessories.roofRails);
        setCarAntenna(data.privateCar.accessories.antenna);
        setCarReverseCamera(data.privateCar.accessories.reverseCamera);

        setYourRemark(data.yourRemark);
        setExShowRoomPrice(data.marketPrice);
        setEstimatedPrice(data.valuation);

        let path = "";
        if (data.assetType === "Two Wheeler") {
          path = `https://instavaluer.com/twowheelerreportrelease/${params.id}`;
        } else if (data.assetType === "Four Wheeler") {
          path = `https://instavaluer.com/releasereport/${params.id}`;
        } else if (data.assetType === "Commercial") {
          path = `https://instavaluer.com/commercialreportrelease/${params.id}`;
        } else if (data.assetType === "Construction Equipment") {
          path = `https://instavaluer.com/constructionreportrelease/${params.id}`;
        } else if (data.assetType === 'Three Wheeler') {
          path = `https://instavaluer.com/threewheelerreportrelease/${params._id}`
        } else if (data.assetType === 'Farm Equipment') {
          path = `https://instavaluer.com/firmequipmentreportrelease/${params._id}`
        }
        const imagePath = `https://instavaluer.com/imagereport/${params.id}`
        setImagePath(imagePath)
        setUrlPath(path);
      }
    };
    fetchData();
  }, [params.id]);

  const handleSubmit = async () => {
    if (engineCondition === '') {
      setEngineConditionError('Please enter engine condition')
    } else {
      setEngineConditionError('')
    }
    if (interiorCondition === '') {
      setInteriorConditionError('Please enter engine condition')
    } else {
      setInteriorConditionError('')
    }
    if (exteriorCondition === '') {
      setExteriorConditionError('Please enter engine condition')
    } else {
      setExteriorConditionError('')
    }
    if (frontBreakCondition === '') {
      setFrontBreakConditionError('Please enter engine condition')
    } else {
      setFrontBreakConditionError('')
    }
    if (rearBreakCondition === '') {
      setRearBreakConditionError('Please enter engine condition')
    } else {
      setRearBreakConditionError('')
    }
    if (transmissionCondition === '') {
      setTransmissionConditionError('Please enter engine condition')
    } else {
      setTransmissionConditionError('')
    }
    if (suspension === '') {
      setSuspensionError('Please enter engine condition')
    } else {
      setSuspensionError('')
    }
    if (carLhFront === "") {
      setCarLhFrontError('Please enter lh front tyre condition')
    } else {
      setCarLhFrontError('')
    }
    if (exShowRoomPrice === "") {
      setExShowRoomPriceError("Please enter Exshowroom Price");
    } else {
      setExShowRoomPriceError("");
    }
    if (estimatedPrice === "") {
      setEstimatedPriceError("Please enter Estimated Price");
    } else {
      setEstimatedPriceError("");
    }
    if (engineCondition !== '' && interiorCondition !== '' && exteriorCondition !== '' && frontBreakCondition !== '' && rearBreakCondition !== '' && transmissionCondition !== '' && suspension !== '' && carLhFront !== '' && exShowRoomPrice !== "" && estimatedPrice !== "") {

      const currentDate = new Date();
      const todayDate = currentDate.toISOString().slice(0, 10)

      const formData = {
        meterReading:meterReading,
        accessories: {
          ac: airConditioner,
          cdCharger: cdCharge,
          seatCover: seatCover,
          fogLamps: fogLamps,
        },
        additionalFeatures: {
          transmission: transmission,
          odometer: odometer,
        },
        otherDetails: {
          accidental: accidental,
          otherRepair: otherRepair,
          fitnessExpiry: fitnessExpiry,
          roadTaxValidity: roadtaxValidity,
          IDVValue: idvValue,
          insuranceExpiry: insuranceExpiry,
          engineCondition: engineCondition,
          frontBrakeCondition: frontBreakCondition,
          rearBrakeCondition: rearBreakCondition,
          wheelsType: wheelType,
          totalTyre: totalTyre,
          availableTyre: availableTyre,
          missingTyre: missingTyre,
          spareTyre: spareTyre,
          interiorCondition: interiorCondition,
          exteriorCondition: exteriorCondition,
          headLamp: headLamp,
          tailLamp: tailLamp,
          frontIndicators: frontIndicators,
          rearIndicators: rearIndicators,
          levelOfMaintenance: maintenanceLevel,
        },
        privateCar: {
          engine: {
            engine: engineThere,
            fluidLeak: fluidLeaks,
            engineShield: engineShield,
          },
          transmission: {
            transmissionCondition: transmissionCondition,
          },
          suspension: {
            suspensionCondition: suspension,
          },
          brakes: {
            handBrake: handBrake,
          },
          TyresNConditions: {
            type: carTyreType,
            lhFront: carLhFront,
            rhfront: carRhFront,
            lhRear: carLhRear,
            rhRear: carRhRear,
            spareTyre: carSpareTyre,
          },
          vehicleInterior: {
            steering: carSteering,
            steeringCondition: carSteeringCondition,
            dashboard: carDashboard,
            seats: carSeat,
            interiorsTrims: carInterior,
            airBag: carAirbag,
            antilockBrakeSystem: carAntiLock,
            powerWindowRear: carPowerWindowRear,
            powerWindowFront: carPowerWindowFront,
            seatBelts: carSeatBelt,
            insideRearViewMirror: carInsideRearViewMirror,
          },
          vehicleExterior: {
            bonnet: carBonnet,
            frontBumper: carFrontBumper,
            rearBumper: carRearBumper,
            windScreen: carWindScreen,
            windscreenGlass: carWindScreenGlass,
            rearWindScreen: carRearWindScreenGlass,
            rhSideBody: carRhSideBody,
            lhSideBody: carLhSideBody,
            lhDoorGlasses: carLhDoorGlass,
            outsideRearViewMirror: carOutsideRearView,
            paint: carPaint,
            paintCondition: carPaintCondition,
            overallBodyCondition: carOverAllBodyCondition,
          },
          electricals: {
            battery: carBattery,
            instrumentClustor: carInstrumentCluster,
            fogLamps: carFogLamp,
            fogLampCondition: carFogLampCondition,
          },
          accessories: {
            sunroof: carSunroof,
            musicStytem: carMusicSyetem,
            roofRails: carRoofRails,
            antenna: carAntenna,
            reverseCamera: carReverseCamera,
          },
        },
        yourRemark: yourRemark,
        valuation: estimatedPrice,
        marketPrice: exShowRoomPrice,
        reportUrl: urlPath,
        imageUrl: imagePath,
        submittedAt: todayDate,
        status: 5,
        statusText: "Case Url Updated",
        currentForm: 4,
      };

      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        navigation(`/releasereport/${params.id}`);
      }
    } else {
      setPressBtn(true);
    }
  };

  useEffect(() => {
    if (transmissionCondition !== '' &&
      engineCondition !== '' &&
      suspension !== '' &&
      handBrake !== '' &&
      frontBreakCondition !== '' &&
      rearBreakCondition !== '' &&
      carLhFront !== '' &&
      carRhFront !== '' &&
      carLhRear !== '' &&
      carRhRear !== '' &&
      carSpareTyre !== '' &&
      interiorCondition !== '' &&
      exteriorCondition !== '' &&
      exShowRoomPrice !== '') {
      const roundImageArray = [
        { label: "Poor", percentage: 2 },
        { label: "Average", percentage: 4 },
        { label: "Satisfactory", percentage: 5 },
        { label: "Good", percentage: 7 },
        { label: "Excellent", percentage: 9 }
      ];

      const findPercentageByLabel = (label) => {
        const item = roundImageArray.find(data => data.label === label);
        return item ? item.percentage : 0
      }

      const findEngineCon = findPercentageByLabel(engineCondition);
      const findTransmissionCon = findPercentageByLabel(transmissionCondition);
      const findSuspensionCon = findPercentageByLabel(suspension);
      const findInteriorCon = findPercentageByLabel(interiorCondition);
      const findExteriorCon = findPercentageByLabel(exteriorCondition);

      const findTyreCon = Math.round((carLhFront + carRhFront + carLhRear + carRhRear + carSpareTyre) / 50);
      const findBreakCon = Math.round((frontBreakCondition + rearBreakCondition) / 2);

      // Determine final conditions based on ranges
      const getFinalCondition = (value) => {
        if (value <= 2) return 2;
        else if (value <= 4) return 4;
        else if (value <= 6) return 5;
        else if (value <= 8) return 7;
        else return 9;
      };

      const finalTyreCondition = getFinalCondition(findTyreCon);
      const finalBreakCondition = getFinalCondition(findBreakCon);


      const overalRating =
        Number(findEngineCon) * 3 +
        Number(findTransmissionCon) +
        Number(findSuspensionCon) +
        Number(findInteriorCon) +
        Number(findExteriorCon) +
        Number(finalBreakCondition) +
        Number(finalTyreCondition);

      console.log(overalRating, 'overalRating');
      const overalavg = Number(overalRating) / Number(10);

      console.log(overalavg, 'overalavg');
      let deprePerYear = {};

      if (overalavg >= 8) {
        deprePerYear = { 1: 7, 2: 14, 3: 18, 4: 20, 5: 25, 6: 28, 7: 32, 8: 34, 9: 36, 10: 38, 11: 48 };
      } else if (overalavg >= 7 && overalavg < 8) {
        deprePerYear = { 1: 10, 2: 16, 3: 22, 4: 25, 5: 28, 6: 30, 7: 35, 8: 38, 9: 38, 10: 40, 11: 48 };
      } else if (overalavg >= 6 && overalavg < 7) {
        deprePerYear = { 1: 15, 2: 20, 3: 25, 4: 30, 5: 35, 6: 40, 7: 42, 8: 42, 9: 44, 10: 46, 11: 48 };
      } else if (overalavg >= 5 && overalavg < 6) {
        deprePerYear = { 1: 25, 2: 35, 3: 45, 4: 47, 5: 49, 6: 52, 7: 53, 8: 55, 9: 55, 10: 55, 11: 55 };
      }

      const mfg = vehicleEdetails.vehicleManufacturingMonthYear.slice(-4);
      const currentYear = new Date().getFullYear();
      const depreYear = currentYear - parseInt(mfg, 10);
      console.log(depreYear, 'depreYear');
      if (depreYear >= 11) {
        const percentageValue = (48 / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue));
      } else if (deprePerYear.hasOwnProperty(depreYear)) {
        const percentageValue = (deprePerYear[depreYear] / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        console.log(depreciationValue, 'depreciationValue');
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue))
      }

    }
  }, [transmissionCondition, engineCondition, suspension, handBrake,
    frontBreakCondition, rearBreakCondition, carLhFront, carRhFront,
    carLhRear, carRhRear, carSpareTyre, interiorCondition, exteriorCondition,
    exShowRoomPrice
  ])

  return (
    <div className="min-h-screen flex flex-col lg:ps-64 w-full">
      <div className="p-2 space-y-6">
        <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
          <div className="flex items-center justify-between py-2 px-5"
            style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
          >
            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
              Edit Case Details
            </h4>
          </div>
          <div className="p-5 border-t border-dashed border-default-200">
            <div className="grid md:grid-cols-3 gap-4">
              <div className="space-y-1">
                <label
                  htmlFor="airConditioner"
                  className="text-sm font-medium text-default-900"
                >
                  Air Conditioner *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="airConditioner"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectAirConditionerChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Air Conditioner
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === airConditioner}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="cdCharge"
                  className="text-sm font-medium text-default-900"
                >
                  Cd Charge *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="cdCharge"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCdChargeChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Cd Charge
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === cdCharge}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="seatCover"
                  className="text-sm font-medium text-default-900"
                >
                  Seat Cover *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="seatCover"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectSeatCoverChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Seat Cover
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === seatCover}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Meter Reading"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
              <div className="space-y-1">
                <label
                  htmlFor="fogLamps"
                  className="text-sm font-medium text-default-900"
                >
                  Fog Lamps *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="fogLamps"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectFogLampsChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Fog Lamps
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === fogLamps}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="transmission"
                  className="text-sm font-medium text-default-900"
                >
                  Transmission *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="transmission"
                  placeholder="This is a search placeholder"
                  value={Features.id}
                  name={Features.name}
                  onChange={(e) => selectTransmissionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Transmission
                  </option>
                  {Features.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === transmission}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="odometer"
                  className="text-sm font-medium text-default-900"
                >
                  Odometer *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="odometer"
                  placeholder="This is a search placeholder"
                  value={Features.id}
                  name={Features.name}
                  onChange={(e) => selectOdometerChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Odometer
                  </option>
                  {Odometer.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === odometer}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="accidental"
                  className="text-sm font-medium text-default-900"
                >
                  Accidental *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="accidental"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectAccidentalChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Accidental
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === accidental}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="otherRepair"
                  className="text-sm font-medium text-default-900"
                >
                  Other Repair *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="otherRepair"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectotherRepairlChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Other Repair
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === otherRepair}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-1">
                <label
                  htmlFor="fitnessExpiry"
                  className="text-sm font-medium text-default-900"
                >
                  Fitness Expiry *
                </label>
                <input
                  type="Date"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fitnessExpiry"
                  placeholder="Enter FitnessExpiry"
                  value={fitnessExpiry}
                  onChange={(e) => setFitnessExpiry(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="roadtaxValidity"
                  className="text-sm font-medium text-default-900"
                >
                  Roadtax Validity *
                </label>
                <input
                  type="Date"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="roadtaxValidity"
                  placeholder="Enter RoadtaxValidity"
                  value={roadtaxValidity}
                  onChange={(e) => setroadtaxValidity(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fIdvValue"
                  className="text-sm font-medium text-default-900"
                >
                  Idv Value *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fIdvValue"
                  placeholder="Enter Idv Value"
                  value={idvValue}
                  onChange={(e) => setIdvValue(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fInsuranceExpiry"
                  className="text-sm font-medium text-default-900"
                >
                  Insurance Expiry *
                </label>
                <input
                  type="date"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fInsuranceExpiry"
                  placeholder="Enter Insurance Expiry"
                  value={insuranceExpiry}
                  onChange={(e) => setInsuranceExpiry(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="engineCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Engine Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    engineConditionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="engineCondition"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectEngineConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Engine Condition
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === engineCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="wheelType"
                  className="text-sm font-medium text-default-900"
                >
                  Wheel Type *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="wheelType"
                  placeholder="This is a search placeholder"
                  value={wheelTypeList.id}
                  name={wheelTypeList.name}
                  onChange={(e) => selectWheelTypeChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Wheel Type
                  </option>
                  {wheelTypeList.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === wheelType}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-1">
                <label
                  htmlFor="fTotalTyre"
                  className="text-sm font-medium text-default-900"
                >
                  Total Tyre *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fTotalTyre"
                  placeholder="Enter Total Tyre"
                  value={totalTyre}
                  onChange={(e) => setTotalTyre(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="availableTyre"
                  className="text-sm font-medium text-default-900"
                >
                  Available Tyre *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="availableTyre"
                  placeholder="Enter Available Tyre"
                  value={availableTyre}
                  onChange={(e) => setAvailableTyre(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fMissingTyre"
                  className="text-sm font-medium text-default-900"
                >
                  Missing Tyre *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fMissingTyre"
                  placeholder="Enter Missing Tyre"
                  value={missingTyre}
                  onChange={(e) => setMissingTyre(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fSpareTyre"
                  className="text-sm font-medium text-default-900"
                >
                  Spare Tyre *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fSpareTyre"
                  placeholder="Enter Spare Tyre"
                  value={spareTyre}
                  onChange={(e) => setSpareTyre(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="interiorCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Interior Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    interiorConditionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="interiorCondition"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectInteriorConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Interior Condition
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === interiorCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="exteriorCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Exterior Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    exteriorConditionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="exteriorCondition"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectExteriorConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Exterior Condition
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === exteriorCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="headLamp"
                  className="text-sm font-medium text-default-900"
                >
                  Head Lamp *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="headLamp"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectHeadLampChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Head Lamp
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === headLamp}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="tailLamp"
                  className="text-sm font-medium text-default-900"
                >
                  Tail Lamp *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="tailLamp"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectTailLampChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Tail Lamp
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === tailLamp}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="frontIndicators"
                  className="text-sm font-medium text-default-900"
                >
                  Front Indicators *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="frontIndicators"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectFrontIndicatorsChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Front Indicators
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === frontIndicators}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="rearIndicators"
                  className="text-sm font-medium text-default-900"
                >
                  Rear Indicators *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="rearIndicators"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectRearIndicatorsChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Rear Indicators
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === rearIndicators}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="maintenanceLevel"
                  className="text-sm font-medium text-default-900"
                >
                  Maintenance Level *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="maintenanceLevel"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectMaintenanceLevelChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Maintenance Level
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === maintenanceLevel}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="frontBreakCondition"
                  className="text-sm font-medium text-default-900"
                >
                  FrontBreak Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    frontBreakConditionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="frontBreakCondition"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectFrontBreakConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose FrontBreak Condition
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === frontBreakCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="rearBreakCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Rear Break Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    rearBreakConditionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="rearBreakCondition"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectRearBreakConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Rear Break Condition
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === rearBreakCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="engineThere"
                  className="text-sm font-medium text-default-900"
                >
                  Engine There *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="engineThere"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectEngineThereChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Engine There
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === engineThere}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fluidLeaks"
                  className="text-sm font-medium text-default-900"
                >
                  Fluid Leaks *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="fluidLeaks"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectFluidLeaksChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Fluid Leaks
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === fluidLeaks}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="engineShield"
                  className="text-sm font-medium text-default-900"
                >
                  Rear Engine Shield *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="engineShield"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectEngineShieldChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Engine Shield
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === engineShield}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="transmissionCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Transmission Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    transmissionConditionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="transmissionCondition"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectTransmissionConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Transmission Condition
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === transmissionCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="suspension"
                  className="text-sm font-medium text-default-900"
                >
                  Suspension Condition <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    suspensionError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="suspension"
                  placeholder="This is a search placeholder"
                  value={reqCondition.id}
                  name={reqCondition.name}
                  onChange={(e) => selectSuspensionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Suspension
                  </option>
                  {reqCondition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === suspension}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="handBrake"
                  className="text-sm font-medium text-default-900"
                >
                  Hand Brake *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="handBrake"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectHandBrakeChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Hand Brake
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === handBrake}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fCarTyreType"
                  className="text-sm font-medium text-default-900"
                >
                  Car Tyre Type *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fCarTyreType"
                  placeholder="Enter Car Tyre Type"
                  value={carTyreType}
                  onChange={(e) => setCarTyreType(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carLhFront"
                  className="text-sm font-medium text-default-900"
                >
                  Car Lh Front <span style={{ color: 'red' }}>**</span>
                </label>
                {/* <div style={{ color: 'red' }} >{carLhFrontError}</div> */}
                <select
                  className={
                    carLhFrontError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="carLhFront"
                  placeholder="This is a search placeholder"
                  value={list.id}
                  name={list.name}
                  onChange={(e) => selectCarLhFrontChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Lh Front
                  </option>
                  {list.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={Number(test.name) === carLhFront}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRhFront"
                  className="text-sm font-medium text-default-900"
                >
                  Car Rh Front <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    carRhFrontError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="carRhFront"
                  placeholder="This is a search placeholder"
                  value={list.id}
                  name={list.name}
                  onChange={(e) => selectCarRhFrontChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Rh Front
                  </option>
                  {list.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={Number(test.name) === carRhFront}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carLhRear"
                  className="text-sm font-medium text-default-900"
                >
                  Car Lh Rear <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    carLhRearError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="carLhRear"
                  placeholder="This is a search placeholder"
                  value={list.id}
                  name={list.name}
                  onChange={(e) => selectCarLhRearChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Lh Rear
                  </option>
                  {list.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={Number(test.name) === carLhRear}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRhRear"
                  className="text-sm font-medium text-default-900"
                >
                  Car Rh Rear <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    carRhRearError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="carRhRear"
                  placeholder="This is a search placeholder"
                  value={list.id}
                  name={list.name}
                  onChange={(e) => selectCarRhRearChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Rh Rear
                  </option>
                  {list.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={Number(test.name) === carRhRear}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carSpareTyre"
                  className="text-sm font-medium text-default-900"
                >
                  Car Spare Tyre <span style={{ color: 'red' }}>**</span>
                </label>
                <select
                  className={
                    carSpareTyreError === ""
                      ? `border-default-200 w-full`
                      : `border-default-200 w-full error_class`
                  }
                  data-trigger
                  id="carSpareTyre"
                  placeholder="This is a search placeholder"
                  value={list.id}
                  name={list.name}
                  onChange={(e) => selectCarSpareTyreChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Spare Tyre
                  </option>
                  {list.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={Number(test.name) === carSpareTyre}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fCarSteering"
                  className="text-sm font-medium text-default-900"
                >
                  Car Steering *
                </label>
                <input
                  type="text"
                  className={
                    "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                  }
                  id="fCarSteering"
                  placeholder="Enter Car Steering"
                  value={carSteering}
                  onChange={(e) => setCarSteering(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carSteeringCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Car Steering Condition *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carSteeringCondition"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarSteeringConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Steering Condition
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carSteeringCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carDashboard"
                  className="text-sm font-medium text-default-900"
                >
                  Car Dashboard *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carDashboard"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarDashboardChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Dashboard
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carDashboard}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carSeat"
                  className="text-sm font-medium text-default-900"
                >
                  Car Seat *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carSeat"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarSeatChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Seat
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carSeat}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carInterior"
                  className="text-sm font-medium text-default-900"
                >
                  Car Interior *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carInterior"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarInteriorChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Interior
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carInterior}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carAirbag"
                  className="text-sm font-medium text-default-900"
                >
                  Car Airbag *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carAirbag"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarAirbagChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Airbag
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carAirbag}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carAntiLock"
                  className="text-sm font-medium text-default-900"
                >
                  Car AntiLock *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carAirbag"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarAntiLockChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car AntiLock
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carAntiLock}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fCarPowerWindowFront"
                  className="text-sm font-medium text-default-900"
                >
                  Power Window Front  *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="fCarPowerWindowFront"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarPowerWindowFront(e)}
                >
                  <option value="0" hidden selected>
                    Choose Power Window Front Available
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carPowerWindowFront}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fCarPowerWindowRear"
                  className="text-sm font-medium text-default-900"
                >
                  Power Window Rear Available  *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="fCarPowerWindowRear"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarPowerWindowRear(e)}
                >
                  <option value="0" hidden selected>
                    Choose Power Window Rear
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carPowerWindowRear}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carSeatBelt"
                  className="text-sm font-medium text-default-900"
                >
                  Car Seat Belt *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carSeatBelt"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarSeatBeltChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Seat Belt
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carSeatBelt}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carInsideRearViewMirror"
                  className="text-sm font-medium text-default-900"
                >
                  Inside Rear View Mirror Condition *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carInsideRearViewMirror"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarInsideRearViewMirrorChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Inside Rear View Mirror Condition
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carInsideRearViewMirror}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carBonnet"
                  className="text-sm font-medium text-default-900"
                >
                  Car Bonnet *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carBonnet"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarBonnetChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Bonnet
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carBonnet}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carFrontBumper"
                  className="text-sm font-medium text-default-900"
                >
                  Car Front Bumper *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carFrontBumper"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarFrontBumperChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Bonnet
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carFrontBumper}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRearBumper"
                  className="text-sm font-medium text-default-900"
                >
                  Car Rear Bumper *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carRearBumper"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarRearBumperChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Rear Bumper
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carRearBumper}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carWindScreen"
                  className="text-sm font-medium text-default-900"
                >
                  Car Wind Screen *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carWindScreen"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarWindScreenChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Wind Screen
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carWindScreen}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carWindScreenGlass"
                  className="text-sm font-medium text-default-900"
                >
                  Car Wind Screen Glass *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carWindScreenGlass"
                  placeholder="This is a search placeholder"
                  value={qualityList.id}
                  name={qualityList.name}
                  onChange={(e) => selectCarWindScreenGlassChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Wind Screen Glass
                  </option>
                  {qualityList.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carWindScreenGlass}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRearWindScreenGlass"
                  className="text-sm font-medium text-default-900"
                >
                  Car Rear Wind Screen Glass *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carRearWindScreenGlass"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarRearWindScreenGlassChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Rear Wind Screen Glass
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carRearWindScreenGlass}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRhDoorGlass"
                  className="text-sm font-medium text-default-900"
                >
                  Car RhDoor Glass *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carRhDoorGlass"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarRhDoorGlassChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car RhDoor Glass
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carRhDoorGlass}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRhSideBody"
                  className="text-sm font-medium text-default-900"
                >
                  Car RhSide Body *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carRhSideBody"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarRhSideBodyChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car RhSide Body
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carRhSideBody}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carLhSideBody"
                  className="text-sm font-medium text-default-900"
                >
                  Car LhSide Body *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carLhSideBody"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarLhSideBodyChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car LhSide Body
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carLhSideBody}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carLhDoorGlass"
                  className="text-sm font-medium text-default-900"
                >
                  Car LhDoor Glass *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carLhDoorGlass"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarLhDoorGlassChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car LhDoor Glass
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carLhDoorGlass}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carOutsideRearView"
                  className="text-sm font-medium text-default-900"
                >
                  Car Outside Rear View *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carOutsideRearView"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectcarOutsideRearViewChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Outside Rear View
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carOutsideRearView}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carPaint"
                  className="text-sm font-medium text-default-900"
                >
                  Car Paint *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carPaint"
                  placeholder="This is a search placeholder"
                  value={qualityList.id}
                  name={qualityList.name}
                  onChange={(e) => selectcarPaintChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Paint
                  </option>
                  {qualityList.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carPaint}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carPaintCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Car Paint Condition *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carPaintCondition"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectcarPaintConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Paint Condition
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carPaintCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carOverAllBodyCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Car Over AllBody Condition *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carOverAllBodyCondition"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectcarOverAllBodyConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car  Over AllBody Condition
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carOverAllBodyCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carBattery"
                  className="text-sm font-medium text-default-900"
                >
                  Car Battery *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carBattery"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectcarBatteryChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Battery
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carBattery}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carInstrumentCluster"
                  className="text-sm font-medium text-default-900"
                >
                  Car Instrument Cluster *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carInstrumentCluster"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarInstrumentClusterChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose Car Instrument Cluster
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carInstrumentCluster}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carFogLamp"
                  className="text-sm font-medium text-default-900"
                >
                  Car Fog Lamp *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carFogLamp"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarFogLampChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car Fog Lamp
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carFogLamp}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carFogLampCondition"
                  className="text-sm font-medium text-default-900"
                >
                  Car FogLamp Condition *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carFogLampCondition"
                  placeholder="This is a search placeholder"
                  value={condition.id}
                  name={condition.name}
                  onChange={(e) => selectCarFogLampConditionChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car FogLamp Condition
                  </option>
                  {condition.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carFogLampCondition}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carSunroof"
                  className="text-sm font-medium text-default-900"
                >
                  Car Sunroof *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carSunroof"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarSunroofChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car Sunroof
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carSunroof}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carMusicSyetem"
                  className="text-sm font-medium text-default-900"
                >
                  Car Music Syetem *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carMusicSyetem"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarMusicSyetemChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car Music Syetem
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carMusicSyetem}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carRoofRails"
                  className="text-sm font-medium text-default-900"
                >
                  Car Roof Rails *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carRoofRails"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarRoofRailsChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car Roof Rails
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carRoofRails}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carAntenna"
                  className="text-sm font-medium text-default-900"
                >
                  Car Antenna *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carAntenna"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarAntennaChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car Antenna
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carAntenna}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="carReverseCamera"
                  className="text-sm font-medium text-default-900"
                >
                  Car Reverse Camera *
                </label>
                <select
                  className={`border-default-200 w-full`}
                  data-trigger
                  id="carReverseCamera"
                  placeholder="This is a search placeholder"
                  value={options.id}
                  name={options.name}
                  onChange={(e) => selectCarReverseCameraChange(e)}
                >
                  <option value="0" hidden selected>
                    Choose  Car Reverse Camera
                  </option>
                  {options.map((test, index) => (
                    <option
                      key={index}
                      value={test.id}
                      name={test.name}
                      required
                      selected={test.name === carReverseCamera}
                    >
                      {test.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fExShowRoomPrice"
                  className="text-sm font-medium text-default-900"
                >
                  Your Remark On Vehicle *
                </label>
                <input
                  type="text"
                  className={
                    `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                  }
                  id="fExShowRoomPrice"
                  placeholder="Enter your remark"
                  value={yourRemark}
                  onChange={(e) => setYourRemark(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fExShowRoomPrice"
                  className="text-sm font-medium text-default-900"
                >
                  Ex-ShowRoom Price <span style={{ color: 'red' }}>**</span>
                </label>
                <input
                  type="text"
                  className={
                    exShowRoomPriceError === ""
                      ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                      : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                  }
                  id="fExShowRoomPrice"
                  placeholder="Enter Ex-ShowRoom Price"
                  value={exShowRoomPrice}
                  onChange={(e) => setExShowRoomPrice(e.target.value)}
                />
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="fEstimatedPrice"
                  className="text-sm font-medium text-default-900"
                >
                  Estimated Price <span style={{ color: 'red' }}>**</span>
                </label>
                <input
                  type="text"
                  className={
                    estimatedPriceError === ""
                      ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                      : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                  }
                  id="fEstimatedPrice"
                  placeholder="Enter Estimated Price"
                  value={estimatedPrice}
                  onChange={(e) => setEstimatedPrice(e.target.value)}
                />
                {
                  depreciatedPrice ?
                    <div>The recommended estimated price for the vehicle is <span style={{ color: 'red' }}>₹{depreciatedPrice}</span></div> : <></>
                }
              </div>
            </div>
            <div
              className="flex items-center gap-2 justify-end"
              style={{ marginBottom: "80px" }}
            >
              <button
                style={{
                  background: "#ef4444",
                  color: "#fff",
                  border: "none",
                  outline: "none",
                }}
                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                onClick={() => navigation(`/admin/editreport3/${params.id}`)}
              >
                <i className="ph-bold ph-x text-lg" />
                Go Back
              </button>
              <button
                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                style={{
                  background: "#4D44B5",
                  border: "none",
                  outline: "none",
                }}
                onClick={() => handleSubmit()}
              >
                <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                View Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourWheelerEditReport4;
