import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";
import { InputAdornment, TextField } from "@mui/material";

const FarmEquipmentEditReport4 = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [assetType, setAssetType] = useState("");
  const [tractorApplication, setTractorApplication] = useState("");
  const [powerHp, setPowerHp] = useState("");
  const [meterReading, setMeterReading] = useState('');
  const [unladenWeight, setUnladenWeight] = useState("");
  const [fitnessExpiryDate, setFitnessExpiryDate] = useState("");
  const [fitnessExpiryDateError, setFitnessExpiryDateError] = useState("");
  const [roadtaxValidityDate, setRoadtaxValidityDate] = useState("");
  const [roadtaxValidityDateError, setRoadtaxValidityDateError] = useState("");
  const [engineStatus, setEngineStatus] = useState("");
  const [engineStatusError, setEngineStatusError] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [transmission, setTransmission] = useState("");
  const [transmissionError, setTransmissionError] = useState("");
  const [transmissionCondition, setTransmissionCondition] = useState("");
  const [transmissionConditionError, setTransmissionConditionError] =
    useState("");
  const [steering, setSteering] = useState("");
  const [steeringError, setSteeringError] = useState("");
  const [brakes, setBrakes] = useState("");
  const [brakesError, setBrakesError] = useState("");
  const [seatCondition, setSeatCondition] = useState("");
  const [seatConditionError, setSeatConditionError] = useState("");
  const [bodyCondition, setBodyCondition] = useState("");
  const [bodyConditionError, setBodyConditionError] = useState("");
  const [wheelsType, setWheelType] = useState("");
  const [wheelsTypeError, setWheelTypeError] = useState("");
  const [undercarriageCondition, setUndercarriageCondition] = useState("");
  const [undercarriageConditionError, setUndercarriageConditionError] =
    useState("");
  const [paintCondition, setPaintCondition] = useState("");
  const [paintConditionError, setPaintConditionError] = useState("");
  const [lhFront, setLhFront] = useState("");
  const [lhFrontError, setLhFrontError] = useState("");
  const [rhFront, setRhFront] = useState("");
  const [rhFrontError, setRhFrontError] = useState("");
  const [lhRear, setLhRear] = useState("");
  const [lhRearError, setLhRearError] = useState("");
  const [rhRear, setRhRear] = useState("");
  const [rhRearError, setRhRearError] = useState("");
  const [spare, setSpare] = useState("");
  const [spareError, setSpareError] = useState("");
  const [totalTyres, setTotalTyres] = useState("");
  const [totalTyresSpareError, setTotalTyresSpareError] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [availableTyreError, setAvailableTyreError] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [missingTyreError, setMissingTyreError] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [spareTyreError, setSpareTyreError] = useState("");
  const [battery, setBattery] = useState("");
  const [batteryError, setBatteryError] = useState("");
  const [batteryNumber, setBatteryNumber] = useState("");
  const [batteryNumberError, setBatteryNumberError] = useState("");
  const [starterMotor, setStarterMotor] = useState("");
  const [starterMotorError, setStarterMotorError] = useState("");
  const [instrumentPanel, setInstrumentPanel] = useState("");
  const [instrumentPanelError, setInstrumentPanelError] = useState("");
  const [headLamp, setHeadLamp] = useState("");
  const [headLampError, setHeadLampError] = useState("");
  const [tailLamp, setTailLamp] = useState("");
  const [tailLampError, setTailLampError] = useState("");
  const [workLampsNumbers, setWorkLampsNumbers] = useState("");
  const [workLampsNumbersError, setWorkLampsNumbersError] = useState("");
  const [workLampsCondition, setWorkLampsCondition] = useState("");
  const [workLampsConditionError, setWorkLampsConditionError] = useState("");
  const [electricalsCondition, setElectricalsCondition] = useState("");
  const [electricalsConditionError, setElectricalsConditionError] =
    useState("");
  const [odometerType, setOdometerType] = useState("");
  const [odometerTypeError, setOdometerTypeError] = useState("");
  const [anyAttachmentImplement, setAnyAttachmentImplement] = useState("");
  const [anyAttachmentImplementError, setAnyAttachmentImplementError] =
    useState("");
  const [nameOfAttachmentImplement, setNameOfAttachmentImplement] =
    useState("");
  const [nameOfAttachmentImplementError, setNameOfAttachmentImplementError] =
    useState("");
  const [others, setOthers] = useState("");
  const [othersError, setOthersError] = useState("");
  const [airCompressor, setAirCompressor] = useState("");
  const [airCompressorError, setAirCompressorError] = useState("");
  const [toolKit, setToolKit] = useState("");
  const [toolKitError, setToolKitError] = useState("");
  const [lift, setLift] = useState("");
  const [liftError, setLiftError] = useState("");
  const [tipper, setTipperStatus] = useState("");
  const [tipperError, setTipperStatusError] = useState("");
  const [hitch, setHitch] = useState("");
  const [hitchError, setHitchError] = useState("");
  const [powerSteering, setPowerSteering] = useState("");
  const [powerSteeringError, setPowerSteeringError] = useState("");
  const [whetherAccidental, setWhetherAccidental] = useState("");
  const [whetherAccidentalError, setWhetherAccidentalError] = useState("");
  const [levelOfMaintenance, setLevelOfMaintenance] = useState("");
  const [levelOfMaintenanceError, setLevelOfMaintenanceError] = useState("");
  const [otherRepairsIfAny, setOtherRepairsIfAny] = useState("");
  const [otherRepairsIfAnyError, setOtherRepairsIfAnyError] = useState("");
  const [btnPress, setBtnPress] = useState(false);


  const [yourRemark, setYourRemark] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [depreciatedPrice, setDepreciatedPrice] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [pressBtn, setPressBtn] = useState(false);
  const [vehicleEdetails, setVehicleEdetails] = useState('')


  useEffect(() => {
    if (pressBtn) {
      if (engineCondition === "") {
        setEngineConditionError("Please enter engine condition")
      } else {
        setEngineConditionError("")
      }
      if (transmissionCondition === "") {
        setTransmissionConditionError("Please enter engine condition")
      } else {
        setTransmissionConditionError("")
      }
      if (brakes === "") {
        setBrakesError("Please enter engine condition")
      } else {
        setBrakesError("")
      }
      if (bodyCondition === "") {
        setBodyConditionError("Please enter engine condition")
      } else {
        setBodyConditionError("")
      }
      if (lhFront === "") {
        setLhFrontError("Please enter engine condition")
      } else {
        setLhFrontError("")
      }
      if (rhFront === "") {
        setRhFrontError("Please enter engine condition")
      } else {
        setRhFrontError("")
      }
      if (lhRear === "") {
        setLhRearError("Please enter engine condition")
      } else {
        setLhRearError("")
      }
      if (rhRear === "") {
        setRhRearError("Please enter engine condition")
      } else {
        setRhRearError("")
      }
      if (spare === "") {
        setSpareError("Please enter engine condition")
      } else {
        setSpareError("")
      }
      if (exShowRoomPrice === "") {
        setExShowRoomPriceError("Please enter Exshowroom Price");
      } else {
        setExShowRoomPriceError("");
      }
      if (estimatedPrice === "") {
        setEstimatedPriceError("Please enter Estimated Price");
      } else {
        setEstimatedPriceError("");
      }
    }
  }, [pressBtn, engineCondition, transmissionCondition, brakes, bodyCondition, lhFront, rhFront, lhRear, rhRear, spare, exShowRoomPrice, estimatedPrice]);


  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 }
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];
  const typeList = [
    { id: 1, name: "Manual" },
    { id: 2, name: "Auto" },
  ];
  const application = [{ name: 'Agriculture', id: 1 }];
  const Features = [
    { name: "Manual", id: 2 },
    { name: "Automatic", id: 3 },
  ];
  const attachment = [
    { name: "One Trolly", id: 1 },
    { name: "Two Trolly", id: 2 },
  ];
  const odometer = [
    { name: "Digital", id: 1 },
    { name: "Normal", id: 2 },
    { name: "Mechanical", id: 3 }
  ];

  const selecttractorApplicationChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTractorApplication(text);
  };
  const selectEngineAvailableChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineStatus(text);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineCondition(text);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmission(text);
  };
  const selectTransmissionConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmissionCondition(text);
  };
  const selectSteeringChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSteering(text);
  };
  const selectBrakesChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setBrakes(text);
  };
  const selectSeatConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSeatCondition(text);
  };
  const selectBodyConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setBodyCondition(text);
  };
  const selectUndercarriageConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setUndercarriageCondition(text);
  };
  const selectPaintConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setPaintCondition(text);
  };
  const selectLhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setLhFront(text);
  };
  const selectRhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRhFront(text);
  };
  const selectLhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setLhRear(text);
  };
  const selectRhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRhRear(text);
  };
  const selectSpareChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSpare(text);
  };
  const selectBatteryChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setBattery(text);
  };
  const selectStarterMotorChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setStarterMotor(text);
  };
  const selectInstrumentPanelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setInstrumentPanel(text);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHeadLamp(text);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTailLamp(text);
  };
  const selectWorkLampsConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setWorkLampsCondition(text);
  };
  const selectElectricalsConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setElectricalsCondition(text);
  };
  const selectOdometerTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOdometerType(text);
  };
  const selectAnyAttachmentImplementChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAnyAttachmentImplement(text);
  };
  const selectNameOfAttachmentImplementChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setNameOfAttachmentImplement(text);
  };
  const selectOthersChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOthers(text);
  };
  const selectAirCompressorChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAirCompressor(text);
  };
  const selectToolKitChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setToolKit(text);
  };
  const selectLiftChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setLift(text);
  };
  const selectTipperStatusChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTipperStatus(text);
  };
  const selectHitchChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHitch(text);
  };
  const selectPowerSteeringChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setPowerSteering(text);
  };
  const selectWhetherAccidentalChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setWhetherAccidental(text);
  };
  const selectLevelOfMaintenanceChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setLevelOfMaintenance(text);
  };
  const selectOtherRepairsIfAnyChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOtherRepairsIfAny(text);
  };

  const selectCaseType = [
    {
      id: 1,
      name: "Poor",
    },
    {
      id: 2,
      name: "Average",
    },
    {
      id: 3,
      name: "Satisfactory",
    },
    {
      id: 4,
      name: "Good",
    },
    {
      id: 5,
      name: "Excellent",
    },
  ];

  const selectCaseChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cases/individualcase/${params.id}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const data = resp.data.case;
        console.log(resp.data.case, "resp.data.case");
        setVehicleEdetails(data.vehicleEdetails)
        setAssetType(data.assetType);
        setTractorApplication(data.farmEquipment.tractorApplication)
        setPowerHp(data.farmEquipment.powerHp)
        setMeterReading(data?.meterReading);
        setUnladenWeight(data.farmEquipment.unladenWeight)
        setFitnessExpiryDate(data.farmEquipment.fitnessExpiryDate)
        setEngineStatus(data.farmEquipment.engineStatus)
        setEngineCondition(data.farmEquipment.engineCondition)
        setTransmission(data.farmEquipment.transmissionType)
        setTransmissionCondition(data.farmEquipment.transmissionCondition)
        setSteering(data.farmEquipment.steering)
        setBrakes(data.farmEquipment.brakes)
        setSeatCondition(data.farmEquipment.seatCondition)
        setBodyCondition(data.farmEquipment.bodyCondition)
        setUndercarriageCondition(data.farmEquipment.undercarriageCondition)
        setPaintCondition(data.farmEquipment.paintCondition)
        setLhFront(data.farmEquipment.lhFront)
        setRhFront(data.farmEquipment.rhFront)
        setLhRear(data.farmEquipment.lhRear)
        setRhRear(data.farmEquipment.rhRear)
        setSpare(data.farmEquipment.spare)
        setTotalTyres(data.farmEquipment.totalTyre)
        setAvailableTyre(data.farmEquipment.availableTyre)
        setMissingTyre(data.farmEquipment.missingTyre)
        setSpareTyre(data.farmEquipment.spareTyre)
        setBattery(data.farmEquipment.battery)
        setBatteryNumber(data.farmEquipment.batteryNo)
        setStarterMotor(data.farmEquipment.starterMotor)
        setInstrumentPanel(data.farmEquipment.instrumentPanel)
        setHeadLamp(data.farmEquipment.headLamp)
        setTailLamp(data.farmEquipment.tailLamp)
        setWorkLampsNumbers(data.farmEquipment.workLampNo)
        setWorkLampsCondition(data.farmEquipment.workLampCondition)
        setElectricalsCondition(data.farmEquipment.electricalCondition)
        setOdometerType(data.farmEquipment.odometerType)
        setAnyAttachmentImplement(data.farmEquipment.attachment)
        setNameOfAttachmentImplement(data.farmEquipment.attachmentName)
        setOthers(data.farmEquipment.otherAttachment)
        setAirCompressor(data.farmEquipment.airCompressor)
        setToolKit(data.farmEquipment.ToolKit)
        setLift(data.farmEquipment.lift)
        setTipperStatus(data.farmEquipment.tipper)
        setHitch(data.farmEquipment.hitch)
        setPowerSteering(data.farmEquipment.powerSteering)
        setWhetherAccidental(data.farmEquipment.accidental)
        setLevelOfMaintenance(data.farmEquipment.maintenanceLevel)
        setOtherRepairsIfAny(data.farmEquipment.otherRepair)


        setYourRemark(data.yourRemark);
        setEstimatedPrice(data.valuation);
        setExShowRoomPrice(data.marketPrice);


        let path = "";
        if (data.assetType === "Two Wheeler") {
          path = `https://instavaluer.com/twowheelerreportrelease/${params.id}`;
        } else if (data.assetType === "Four Wheeler") {
          path = `https://instavaluer.com/releasereport/${params.id}`;
        } else if (data.assetType === "Commercial") {
          path = `https://instavaluer.com/commercialreportrelease/${params.id}`;
        } else if (data.assetType === "Construction Equipment") {
          path = `https://instavaluer.com/constructionreportrelease/${params.id}`;
        } else if (data.assetType === 'Three Wheeler') {
          path = `https://instavaluer.com/threewheelerreportrelease/${params._id}`
        } else if (data.assetType === 'Farm Equipment') {
          path = `https://instavaluer.com/firmequipmentreportrelease/${params._id}`
        }
        setUrlPath(path);
        const imagePath = `https://instavaluer.com/imagereport/${params.id}`
        setImagePath(imagePath)
      }
    };
    fetchData();
  }, [params.id]);


  const handleSubmit = async () => {
    const currentDate = new Date();
    const todayDate = currentDate.toISOString().slice(0, 10)
    if (engineCondition === "") {
      setEngineConditionError("Please enter engine condition")
    } else {
      setEngineConditionError("")
    }
    if (transmissionCondition === "") {
      setTransmissionConditionError("Please enter engine condition")
    } else {
      setTransmissionConditionError("")
    }
    if (brakes === "") {
      setBrakesError("Please enter engine condition")
    } else {
      setBrakesError("")
    }
    if (bodyCondition === "") {
      setBodyConditionError("Please enter engine condition")
    } else {
      setBodyConditionError("")
    }
    if (lhFront === "") {
      setLhFrontError("Please enter engine condition")
    } else {
      setLhFrontError("")
    }
    if (rhFront === "") {
      setRhFrontError("Please enter engine condition")
    } else {
      setRhFrontError("")
    }
    if (lhRear === "") {
      setLhRearError("Please enter engine condition")
    } else {
      setLhRearError("")
    }
    if (rhRear === "") {
      setRhRearError("Please enter engine condition")
    } else {
      setRhRearError("")
    }
    if (spare === "") {
      setSpareError("Please enter engine condition")
    } else {
      setSpareError("")
    }
    if (exShowRoomPrice === "") {
      setExShowRoomPriceError("Please enter Exshowroom Price");
    } else {
      setExShowRoomPriceError("");
    }
    if (estimatedPrice === "") {
      setEstimatedPriceError("Please enter Estimated Price");
    } else {
      setEstimatedPriceError("");
    }
    if (engineCondition !== '' && transmissionCondition !== '' && brakes !== '' && bodyCondition !== '' && lhFront !== '' && rhFront !== '' && lhRear !== '' && rhRear !== '' && spare !== '' && exShowRoomPrice !== "" && estimatedPrice !== "") {

      const formData = {
        meterReading:meterReading,
        farmEquipment: {
          tractorApplication: tractorApplication,
          powerHp: powerHp,
          unladenWeight: unladenWeight,
          fitnessExpiryDate: fitnessExpiryDate,
          roadtaxValidityDate: roadtaxValidityDate,
          engineCondition: engineCondition,
          transmissionType: transmission,
          transmissionCondition: transmissionCondition,
          steering: steering,
          brakes: brakes,
          seatCondition: seatCondition,
          bodyCondition: bodyCondition,
          wheelsType: wheelsType,
          undercarriageCondition: undercarriageCondition,
          paintCondition: paintCondition,
          lhFront: lhFront,
          rhFront: rhFront,
          lhRear: lhRear,
          rhRear: rhRear,
          spare: spare,
          totalTyre: totalTyres,
          availableTyre: availableTyre,
          missingTyre: missingTyre,
          spareTyre: spareTyre,
          battery: battery,
          batteryNo: batteryNumber,
          starterMotor: starterMotor,
          instrumentPanel: instrumentPanel,
          headLamp: headLamp,
          tailLamp: tailLamp,
          workLampNo: workLampsNumbers,
          workLampCondition: workLampsCondition,
          electricalCondition: electricalsCondition,
          odometerType: odometerType,
          attachment: anyAttachmentImplement,
          attachmentName: nameOfAttachmentImplement,
          otherAttachment: others,
          airCompressor: airCompressor,
          ToolKit: toolKit,
          lift: lift,
          tipper: tipper,
          hitch: hitch,
          powerSteering: powerSteering,
          accidental: whetherAccidental,
          maintenanceLevel: levelOfMaintenance,
          otherRepair: otherRepairsIfAny
        },
        yourRemark: yourRemark,
        valuation: estimatedPrice,
        marketPrice: exShowRoomPrice,
        reportUrl: urlPath,
        imageUrl: imagePath,
        submittedAt: todayDate,
        status: 5,
        statusText: "Case Url Updated",
        currentForm: 4,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      console.log(formData, "formData");

      const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        navigation(`/firmequipmentreportrelease/${params.id}`);
      }
    } else {
      setPressBtn(true);
    }
  };

  useEffect(() => {
    if (engineCondition !== '' &&
      transmissionCondition !== '' &&
      bodyCondition !== '' &&
      electricalsCondition !== '' &&
      brakes !== '' &&
      lhRear !== '' &&
      rhRear !== '' &&
      lhFront !== '' &&
      rhFront !== '' &&
      spare !== '' &&
      exShowRoomPrice !== ''
    ) {
      const roundImageArray = [
        { label: "Poor", percentage: 2 },
        { label: "Average", percentage: 4 },
        { label: "Satisfactory", percentage: 5 },
        { label: "Good", percentage: 7 },
        { label: "Excellent", percentage: 9 },
      ];
      const findPercentageByLabel = (label) => {
        const findPercentage = roundImageArray.find((item) => item.label === label);
        return findPercentage ? findPercentage.percentage : 0;
      }
      const findEngineCon = findPercentageByLabel(engineCondition);
      const findTransmissionCon = findPercentageByLabel(transmissionCondition);
      const findElectricalCon = findPercentageByLabel(electricalsCondition);
      const findExteriorCon = findPercentageByLabel(bodyCondition);
      const findTyreCon = Math.floor((lhRear + rhRear + lhFront + rhFront + spare) / 5);

      const getFinalCondition = (value) => {
        if (value <= 2) return 2;
        else if (value <= 4) return 4;
        else if (value <= 6) return 5;
        else if (value <= 8) return 7;
        else return 9;
      };

      const findTyreCondition = getFinalCondition(findTyreCon)

      const overalRating =
        Number(findEngineCon) * 4 +
        Number(findTransmissionCon) * 1.5 +
        Number(findElectricalCon) * 1.5 +
        Number(findExteriorCon) * 1.5 +
        Number(findTyreCondition) * 1.5;

      const overalavg = Number(overalRating) / Number(10);

      console.log(overalavg, 'overalavg');
      let deprePerYear = {};

      if (overalavg >= 8) {
        deprePerYear = { 1: 7, 2: 14, 3: 18, 4: 20, 5: 25, 6: 28, 7: 32, 8: 34, 9: 36, 10: 38, 11: 48 };
      } else if (overalavg >= 7 && overalavg < 8) {
        deprePerYear = { 1: 10, 2: 16, 3: 22, 4: 25, 5: 28, 6: 30, 7: 35, 8: 38, 9: 38, 10: 40, 11: 48 };
      } else if (overalavg >= 6 && overalavg < 7) {
        deprePerYear = { 1: 15, 2: 20, 3: 25, 4: 30, 5: 35, 6: 40, 7: 42, 8: 42, 9: 44, 10: 46, 11: 48 };
      } else if (overalavg >= 5 && overalavg < 6) {
        deprePerYear = { 1: 25, 2: 35, 3: 45, 4: 47, 5: 49, 6: 52, 7: 53, 8: 55, 9: 55, 10: 55, 11: 55 };
      }

      const mfg = vehicleEdetails.vehicleManufacturingMonthYear.slice(-4);
      const currentYear = new Date().getFullYear();
      const depreYear = currentYear - parseInt(mfg, 10);
      console.log(depreYear, 'depreYear');
      if (depreYear >= 11) {
        const percentageValue = (48 / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue));
      } else if (deprePerYear.hasOwnProperty(depreYear)) {
        const percentageValue = (deprePerYear[depreYear] / 100) * exShowRoomPrice;
        const depreciationValue = exShowRoomPrice - percentageValue;
        console.log(depreciationValue, 'depreciationValue');
        setEstimatedPrice(Math.floor(depreciationValue));
        setDepreciatedPrice(Math.floor(depreciationValue))
      }
    }
  }, [engineCondition, transmissionCondition, bodyCondition,
    electricalsCondition, lhRear, rhRear, lhFront,
    rhFront, spare, exShowRoomPrice
  ])

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div className="flex items-center justify-between py-2 px-5"
              style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            >
              <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                Edit Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="fTractorApplication"
                    className="text-sm font-medium text-default-900"
                  >
                    Tractor Application *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fTractorApplication"
                    placeholder="This is a search placeholder"
                    value={application.id}
                    name={application.name}
                    onChange={(e) => selecttractorApplicationChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tractor Application
                    </option>
                    {application.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === tractorApplication}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fPowerHp"
                    className="text-sm font-medium text-default-900"
                  >
                    Power Hp *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fPowerHp"
                    placeholder="Enter Power Hp"
                    value={powerHp}
                    onChange={(e) => setPowerHp(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Meter Reading"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fUnladenWeight"
                    className="text-sm font-medium text-default-900"
                  >
                    Unladen Weight *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fUnladenWeight"
                    placeholder="Enter Unladen Weight"
                    value={unladenWeight}
                    onChange={(e) => setUnladenWeight(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fFitnessExpiryDate"
                    className="text-sm font-medium text-default-900"
                  >
                    Fitness Expiry Date *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fFitnessExpiryDate"
                    placeholder="Enter Fitness Expiry Date"
                    value={fitnessExpiryDate}
                    onChange={(e) => setFitnessExpiryDate(e.target.value)}
                  />

                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fRoadTaxValidity"
                    className="text-sm font-medium text-default-900"
                  >
                    Road Tax Validity Date *
                  </label>
                  <input
                    type="date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fRoadTaxValidity"
                    placeholder="Enter Road Tax Validity"
                    value={roadtaxValidityDate}
                    onChange={(e) => setRoadtaxValidityDate(e.target.value)}
                  />

                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEngineAvailable"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Available *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fEngineAvailable"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectEngineAvailableChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Status
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === engineStatus}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      engineConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="engineCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectEngineConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === engineCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmission"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="transmission"
                    placeholder="This is a search placeholder"
                    value={Features.id}
                    name={Features.name}
                    onChange={(e) => selectTransmissionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Transmission Type
                    </option>
                    {Features.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === transmission}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmissionCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      transmissionConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="transmissionCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectTransmissionConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Transmission Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === transmissionCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="steering"
                    className="text-sm font-medium text-default-900"
                  >
                    Steering *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="steering"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectSteeringChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Steering
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === steering}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="brakes"
                    className="text-sm font-medium text-default-900"
                  >
                    Brakes <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      brakesError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="brakes"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectBrakesChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Brakes
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === brakes}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="seatCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="seatCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectSeatConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Seat Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === seatCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="bodyCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Body Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      bodyConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="bodyCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectBodyConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Body Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === bodyCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="undercarriageCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Undercarriage Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="undercarriageCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectUndercarriageConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Undercarriage Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === undercarriageCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="paintCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Paint Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="paintCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectPaintConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Paint Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === paintCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Lh Front <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      lhFrontError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="lhFront"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectLhFrontChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Lh Front
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === lhFront}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Rh Front <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      rhFrontError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="rhFront"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectRhFrontChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rh Front
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rhFront}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Lh Rear <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      lhRearError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="lhRear"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectLhRearChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Lh Rear
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === lhRear}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Rh Rear <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      rhRearError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="rhRear"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectRhRearChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rh Rear
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rhRear}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="spare"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      spareError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="spare"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectSpareChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Spare
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === spare}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fTotalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Total Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fTotalTyre"
                    placeholder="Enter Total Tyre"
                    value={totalTyres}
                    onChange={(e) => setTotalTyres(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="availableTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Available Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="availableTyre"
                    placeholder="Enter Available Tyre"
                    value={availableTyre}
                    onChange={(e) => setAvailableTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="missingTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Missing Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="missingTyre"
                    placeholder="Enter Available Tyre"
                    value={missingTyre}
                    onChange={(e) => setMissingTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="spareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="spareTyre"
                    placeholder="Enter Available Tyre"
                    value={spareTyre}
                    onChange={(e) => setSpareTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="battery"
                    className="text-sm font-medium text-default-900"
                  >
                    Battery *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="battery"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectBatteryChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Battery
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === battery}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="batteryNumber"
                    className="text-sm font-medium text-default-900"
                  >
                    Battery Number *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="batteryNumber"
                    placeholder="Enter Battery Number"
                    value={batteryNumber}
                    onChange={(e) => setBatteryNumber(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="starterMotor"
                    className="text-sm font-medium text-default-900"
                  >
                    Starter Motor *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="starterMotor"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectStarterMotorChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Starter Motor
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === starterMotor}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="instrumentPanel"
                    className="text-sm font-medium text-default-900"
                  >
                    Instrument Panel *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="instrumentPanel"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectInstrumentPanelChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Instrument Panel
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === instrumentPanel}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="headLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Head Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="headLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectHeadLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Head Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === headLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tailLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Tail Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="tailLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTailLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tail Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === tailLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="workLampsNumbers"
                    className="text-sm font-medium text-default-900"
                  >
                    Work Lamps Numbers *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="workLampsNumbers"
                    placeholder="Enter Work Lamps Numbers"
                    value={workLampsNumbers}
                    onChange={(e) => setWorkLampsNumbers(e.target.value)}
                  />
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="workLampsCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Work Lamps Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="workLampsCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectWorkLampsConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Work Lamps Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === workLampsCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="electricalsCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Electricals Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      electricalsConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="electricalsCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectElectricalsConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Electricals Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === electricalsCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometerType"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="odometerType"
                    placeholder="This is a search placeholder"
                    value={odometer.id}
                    name={odometer.name}
                    onChange={(e) => selectOdometerTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Odometer Type
                    </option>
                    {odometer.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === odometerType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="anyAttachmentImplement"
                    className="text-sm font-medium text-default-900"
                  >
                    Any Attachment/Implement *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="anyAttachmentImplement"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectAnyAttachmentImplementChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Any Attachment/Implement
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === anyAttachmentImplement}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="nameOfAttachmentImplement"
                    className="text-sm font-medium text-default-900"
                  >
                    Name Of Attachment/Implement *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="nameOfAttachmentImplement"
                    placeholder="This is a search placeholder"
                    value={attachment.id}
                    name={attachment.name}
                    onChange={(e) => selectNameOfAttachmentImplementChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Name Of Attachment/Implement
                    </option>
                    {attachment.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === nameOfAttachmentImplement}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fOthers"
                    className="text-sm font-medium text-default-900"
                  >
                    Others *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fOthers"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectOthersChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Others
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === others}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="airCompressor"
                    className="text-sm font-medium text-default-900"
                  >
                    Air Compressor *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="airCompressor"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectAirCompressorChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Air Compressor
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === airCompressor}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="toolKit"
                    className="text-sm font-medium text-default-900"
                  >
                    Tool Kit *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="toolKit"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectToolKitChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tool Kit
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === toolKit}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lift"
                    className="text-sm font-medium text-default-900"
                  >
                    Lift *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="lift"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectLiftChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Lift
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === lift}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="tipper"
                    className="text-sm font-medium text-default-900"
                  >
                    Tipper *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="tipper"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectTipperStatusChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tipper
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === tipper}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="hitch"
                    className="text-sm font-medium text-default-900"
                  >
                    Hitch *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="hitch"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectHitchChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Hitch
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === hitch}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="powerSteering"
                    className="text-sm font-medium text-default-900"
                  >
                    PowerSteering *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="powerSteering"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectPowerSteeringChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose PowerSteering
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === powerSteering}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="whetherAccidental"
                    className="text-sm font-medium text-default-900"
                  >
                    Whether Accidental *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="whetherAccidental"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectWhetherAccidentalChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Whether Accidental
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === whetherAccidental}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="levelOfMaintenance"
                    className="text-sm font-medium text-default-900"
                  >
                    Level Of Maintenance *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="levelOfMaintenance"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectLevelOfMaintenanceChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Level Of Maintenance
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === levelOfMaintenance}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="otherRepairsIfAny"
                    className="text-sm font-medium text-default-900"
                  >
                    Other RepairsIfAny *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="otherRepairsIfAny"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectOtherRepairsIfAnyChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Other Repairs Status
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === otherRepairsIfAny}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                <label
                  htmlFor="fExShowRoomPrice"
                  className="text-sm font-medium text-default-900"
                >
                  Your Remark On Vehicle *
                </label>
                <input
                  type="text"
                  className={
                    `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                  }
                  id="fExShowRoomPrice"
                  placeholder="Enter your remark"
                  value={yourRemark}
                  onChange={(e) => setYourRemark(e.target.value)}
                />
              </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Ex-ShowRoom Price *
                  </label>
                  <input
                    type="text"
                    className={
                      exShowRoomPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter Ex-ShowRoom Price"
                    value={exShowRoomPrice}
                    onChange={(e) => setExShowRoomPrice(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEstimatedPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Estimated Price *
                  </label>
                  <input
                    type="text"
                    className={
                      estimatedPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fEstimatedPrice"
                    placeholder="Enter Estimated Price"
                    value={estimatedPrice}
                    onChange={(e) => setEstimatedPrice(e.target.value)}
                  />
                  {
                    depreciatedPrice ?
                      <div>The recommended estimated price for the vehicle is <span style={{ color: 'red' }}>₹{depreciatedPrice}</span></div> : <></>
                  }
                </div>
              </div>
              <div
                className="flex items-center gap-2 justify-end"
                style={{ marginBottom: "80px" }}
              >
                <button
                  style={{
                    background: "#ef4444",
                    color: "#fff",
                    border: "none",
                    outline: "none",
                  }}
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                  onClick={() => navigation(`/admin/editreport3/${params.id}`)}
                >
                  <i className="ph-bold ph-x text-lg" />
                  Go Back
                </button>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => handleSubmit()}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  View Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FarmEquipmentEditReport4;
