import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl,Imageurl } from "../../config/BaseUrl";

const initialState = {
  isLoading: true,
  notifications: [],
};

export const createNotification = createAsyncThunk(
  "notification/createNotification",
  async (formData, thunAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/notification/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunAPI.rejectWithValue("Not able to create notification");
    }
  }
);

export const getAllNotification = createAsyncThunk(
  "notification/getNotification",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/notification/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Not able to get all the notifications");
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notification,updateNotification",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/notification/updatenotification/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Not able to update the notification");
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/notification/deletenotification/${formData._id}`;
      const resp = await axios.delete(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Not able to delete the notification");
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.notifications = [
          action.payload.notification,
          ...state.notifications,
        ];
        state.isLoading = false;
      })
      .addCase(createNotification.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllNotification.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications;
        state.isLoading = false;
      })
      .addCase(getAllNotification.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          (individualNotification) =>
            individualNotification._id !== action.payload.notification._id
        );
        state.notifications = [
          action.payload.notification,
          ...state.notifications,
        ];
        state.isLoading = false;
      })
      .addCase(updateNotification.rejected, (state) => {
        state.isLoading = true;
      });
    //   .addCase(deleteNotification.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(deleteNotification.fulfilled, (state, action) => {
    //     state.notifications = state.notifications.filter(
    //       (individualNotification) =>
    //         individualNotification._id === action.payload.notification._id
    //         );
    //         state.isLoading = false;
    //   })
    //   .addCase(deleteNotification.rejected, (state) => {
    //     state.isLoading = true;
    //   });
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
