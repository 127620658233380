import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";
import { InputAdornment, TextField } from "@mui/material";

const TwoWheelerEditReport4 = () => {
  const params = useParams();
  const navigation = useNavigate();
  const [assetType, setAssetType] = useState("");
  const [airConditioner, setAirConditioner] = useState("");
  const [cdCharge, setCdCharge] = useState("");
  const [seatCover, setSeatCover] = useState("");
  const [meterReading, setMeterReading] = useState('');
  const [fogLamps, setFogLamps] = useState("");
  const [transmission, setTransmission] = useState("");
  const [odometer, setOdometer] = useState("");
  const [accidental, setAccidental] = useState("");
  const [otherRepair, setotherRepair] = useState("");
  const [fitnessExpiry, setFitnessExpiry] = useState("");
  const [roadtaxValidity, setroadtaxValidity] = useState("");
  const [idvValue, setIdvValue] = useState("");
  const [insuranceExpiry, setInsuranceExpiry] = useState("");
  const [engineCondition, setEngineCondition] = useState("");
  const [engineConditionError, setEngineConditionError] = useState("");
  const [wheelType, setWheelType] = useState("");
  const [totalTyre, setTotalTyre] = useState("");
  const [availableTyre, setAvailableTyre] = useState("");
  const [missingTyre, setMissingTyre] = useState("");
  const [spareTyre, setSpareTyre] = useState("");
  const [interiorCondition, setInteriorCondition] = useState("");
  const [exteriorCondition, setExteriorCondition] = useState("");
  const [exteriorConditionError, setExteriorConditionError] = useState("");
  const [headLamp, setHeadLamp] = useState("Satisfactory");
  const [tailLamp, setTailLamp] = useState("Satisfactory");
  const [frontIndicators, setFrontIndicators] = useState("Satisfactory");
  const [rearIndicators, setRearIndicators] = useState("Satisfactory");
  const [maintenanceLevel, setMaintenanceLevel] = useState("Satisfactory");
  const [coolingSystem, setCoolingSystem] = useState("Satisfactory");
  const [coolingSystemCondition, setCoolingSystemCondition] = useState("Satisfactory");
  const [fuelTank, setFuelTank] = useState("Satisfactory");
  const [silencer, setSilencer] = useState("Satisfactory");
  const [clutch, setClutch] = useState("Satisfactory");
  const [gearbox, setGearBox] = useState("Satisfactory");
  const [gearboxError, setGearBoxError] = useState("");
  const [frontSuspension, setFrontSuspension] = useState("Satisfactory");
  const [frontSuspensionError, setFrontSuspensionError] = useState("");
  const [rearSuspension, setRearSuspension] = useState("Satisfactory");
  const [rearSuspensionError, setRearSuspensionError] = useState("");
  const [frontBreakType, setFrontBreakType] = useState("Satisfactory");
  const [frontBreakCondition, setFrontBreakCondition] = useState("Satisfactory");
  const [frontBreakConditionError, setFrontBreakConditionError] = useState("");
  const [rearBreakType, setRearBreakType] = useState("Satisfactory");
  const [rearBreakCondition, setRearBreakCondition] = useState("Satisfactory");
  const [rearBreakConditionError, setRearBreakConditionError] = useState("");
  const [twoWheelerTyreType, setTwoWheelerTyreType] = useState("");
  const [twoWheelerLhFront, setTwoWheelerLhFront] = useState("");
  const [twoWheelerLhFrontError, setTwoWheelerLhFrontError] = useState("");
  const [twoWheelerLhRear, setTwoWheelerLhRear] = useState("");
  const [twoWheelerLhRearError, setTwoWheelerLhRearError] = useState("");
  const [twoWheelerBatteryCondtion, setTwoWheelerBaterryCondition] =
    useState("Satisfactory");
  const [twoWheelerIgnition, setTwoWheelerIngnition] = useState("");
  const [twoWheelerElecCon, setTwoWheelerElecCon] = useState("Satisfactory");
  const [twoWheelerElecConError, setTwoWheelerElecConError] = useState("");
  const [twoWheelerSpeedometer, setTwoWheelerSpeedometer] = useState("");
  const [twoWheelerAccessories, setTwoWheelerAccessories] = useState("");
  const [twoWheelerBodyPaint, setTwoWheelerBodyPaint] = useState("");
  const [twoWheelerPaintCondition, setTwoWheelerPaintCondition] = useState("Satisfactory");
  const [twoWheelerBodyCondition, setTwoWheelerBodyCondition] = useState("Satisfactory");
  const [twoWheelerToolKit, setTwoWheelerToolKit] = useState("");

  const [yourRemark, setYourRemark] = useState("");
  const [exShowRoomPrice, setExShowRoomPrice] = useState("");
  const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState("");
  const [estimatedPriceError, setEstimatedPriceError] = useState("");
  const [depreciatedPrice, setDepreciatedPrice] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [pressBtn, setPressBtn] = useState(false);
  const [vehicleEdetails, setVehicleEdetails] = useState('')

  useEffect(() => {
    if (pressBtn) {
      if (engineCondition === '') {
        setEngineConditionError('Please enter engine condition')
      } else {
        setEngineConditionError('')
      }
      if (exteriorCondition === '') {
        setExteriorConditionError('Please enter exterior condition')
      } else {
        setExteriorConditionError('')
      }
      if (gearbox === '') {
        setGearBoxError('Please enter gearbox condition')
      } else {
        setGearBoxError('')
      }
      if (frontSuspension === '') {
        setFrontSuspensionError('Please enter front suspension condition')
      } else {
        setFrontSuspensionError('')
      }
      if (rearSuspension === '') {
        setRearSuspensionError('Please enter rear suspension condition')
      } else {
        setRearSuspensionError('')
      }
      if (frontBreakCondition === '') {
        setFrontBreakConditionError('Please enter front break condition')
      } else {
        setFrontBreakConditionError('')
      }
      if (rearBreakCondition === '') {
        setRearBreakConditionError('Please enter rear break condition')
      } else {
        setRearBreakConditionError('')
      }
      if (twoWheelerLhFront === '') {
        setTwoWheelerLhRearError('Please enter lh front tyre condition')
      } else {
        setTwoWheelerLhRearError('')
      }
      if (twoWheelerLhRear === '') {
        setTwoWheelerLhFrontError('Please enter lh rear tyre condition')
      } else {
        setTwoWheelerLhFrontError('')
      }
      if (exShowRoomPrice === "") {
        setExShowRoomPriceError("Please enter Exshowroom Price");
      } else {
        setExShowRoomPriceError("");
      }
      if (estimatedPrice === "") {
        setEstimatedPriceError("Please enter Estimated Price");
      } else {
        setEstimatedPriceError("");
      }
    }
  }, [pressBtn, engineCondition, exteriorCondition, gearbox, frontSuspension, rearSuspension, frontBreakCondition, rearBreakCondition, twoWheelerLhFront, twoWheelerLhRear, exShowRoomPrice, estimatedPrice]);

  const selectCaseType = [
    {
      id: 1,
      name: "Poor",
    },
    {
      id: 2,
      name: "Average",
    },
    {
      id: 3,
      name: "Satisfactory",
    },
    {
      id: 4,
      name: "Good",
    },
    {
      id: 5,
      name: "Excellent",
    },
  ];

  const condition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
    { name: "NA", id: 6 },
  ];
  const reqCondition = [
    { name: "Poor", id: 1 },
    { name: "Average", id: 2 },
    { name: "Satisfactory", id: 3 },
    { name: "Good", id: 4 },
    { name: "Excellent", id: 5 },
  ];
  const list = [
    { name: "10", id: 1 },
    { name: "20", id: 2 },
    { name: "30", id: 3 },
    { name: "40", id: 4 },
    { name: "50", id: 5 },
    { name: "60", id: 6 },
    { name: "70", id: 7 },
    { name: "80", id: 8 },
    { name: "90", id: 9 },
    { name: "100", id: 10 },
  ];
  const qualityList = [
    { name: "Original", id: 1 },
    { name: "Replaced", id: 2 },
  ];
  const options = [
    { name: "Yes", id: 1 },
    { name: "No", id: 2 },
  ];
  const wheelTypeList = [
    { name: "Alloy", id: 1 },
    { name: "Steel", value: 2 },
    { name: "Rim", value: 3 },
  ];
  const typeList = [
    { id: 1, name: "Manual" },
    { id: 2, name: "Auto" },
  ];
  const Features = [
    { name: "Digital", id: 2 },
    { name: "Normal", id: 3 },
  ];
  const attachment = [
    { name: "One Trolly", id: 1 },
    { name: "Two Trolly", id: 2 },
  ];
  const coolingSystemList = [
    { name: "Air", id: 1 },
    { name: "Oil", id: 2 },
    { name: "Liquid", id: 3 },
  ];
  const breakTypeList = [
    { name: "Drum", id: 1 },
    { name: "Disc", id: 2 },
  ];
  const twoWheelerTyreList = [
    { name: "Sport", id: 1 },
    { name: "Touring", id: 2 },
    { name: "Cruiser", id: 3 },
    { name: "ADV/Dual Sport", id: 4 },
    { name: "Off-road", id: 5 },
  ];
  const twoIgnitionTypeList = [
    { name: "Self Start", id: 1 },
    { name: "Kick Start", id: 2 },
  ];

  const selectCaseChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setYourRemark(text);
  };
  const selectSeatCoverChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSeatCover(text);
  };
  const selectFogLampsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFogLamps(text);
  };
  const selectTransmissionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTransmission(text);
  };
  const selectOdometerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setOdometer(text);
  };
  const selectAccidentalChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAccidental(text);
  };
  const selectotherRepairChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setotherRepair(text);
  };
  const selectEngineConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setEngineCondition(text);
  };
  const selectWheelTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setWheelType(text);
  };
  const selectExteriorConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setExteriorCondition(text);
  };
  const selectHeadLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setHeadLamp(text);
  };
  const selectTailLampChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTailLamp(text);
  };
  const selectFrontIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontIndicators(text);
  };
  const selectRearIndicatorsChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearIndicators(text);
  };
  const selectMaintenanceLevelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setMaintenanceLevel(text);
  };
  const selectCoolingSystemChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCoolingSystem(text);
  };
  const selectCoolingSystemConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCoolingSystemCondition(text);
  };
  const selectFuelTankChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFuelTank(text);
  };
  const selectSilencerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setSilencer(text);
  };
  const selectClutchChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setClutch(text);
  };
  const selectGearBoxChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setGearBox(text);
  };
  const selectFrontSuspensionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontSuspension(text);
  };
  const selectRearSuspensionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearSuspension(text);
  };
  const selectFrontBreakTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontBreakType(text);
  };
  const selectFrontBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFrontBreakCondition(text);
  };
  const selectRearBreakTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearBreakType(text);
  };
  const selectRearBreakConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setRearBreakCondition(text);
  };
  const selectTwoWheelerTyreTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerTyreType(text);
  };
  const selectTwoWheelerLhFrontChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerLhFront(text);
  };
  const selectTwoWheelerLhRearChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerLhRear(text);
  };
  const selectTwoWheelerBaterryConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerBaterryCondition(text);
  };
  const selectTwoWheelerIngnitionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerIngnition(text);
  };
  const selectTwoWheelerElecConChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerElecCon(text);
  };
  const selectTwoWheelerSpeedometerChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerSpeedometer(text);
  };
  const selectTwoWheelerAccessoriesChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerAccessories(text);
  };
  const selectTwoWheelerBodyPaintChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerBodyPaint(text);
  };
  const selectTwoWheelerPaintConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerPaintCondition(text);
  };
  const selectTwoWheelerBodyConditionChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerBodyCondition(text);
  };
  const selectTwoWheelerToolKitChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setTwoWheelerToolKit(text);
  };
  const selectYourRemarkChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cases/individualcase/${params.id}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const data = resp.data.case;
        console.log(resp.data.case, "resp.data.case");
        setVehicleEdetails(data.vehicleEdetails)
        setAssetType(data.assetType);
        setMeterReading(data?.meterReading);
        setSeatCover(data.accessories.seatCover);
        setFogLamps(data.accessories.fogLamps);
        setTransmission(data.additionalFeatures.transmission);
        setOdometer(data.additionalFeatures.odometer);
        setAccidental(data.otherDetails.accidental);
        setotherRepair(data.otherDetails.otherRepair);
        setFitnessExpiry(data.otherDetails.fitnessExpiry);
        setroadtaxValidity(data.otherDetails.roadTaxValidity);
        setIdvValue(data.otherDetails.IDVValue);
        setInsuranceExpiry(data.otherDetails.insuranceExpiry);
        setEngineCondition(data.otherDetails.engineCondition);
        setWheelType(data.otherDetails.wheelsType);
        setTotalTyre(data.otherDetails.totalTyre);
        setAvailableTyre(data.otherDetails.availableTyre);
        setMissingTyre(data.otherDetails.missingTyre);
        setSpareTyre(data.otherDetails.spareTyre);
        setInteriorCondition(data.otherDetails.interiorCondition);
        setExteriorCondition(data.otherDetails.exteriorCondition);
        setHeadLamp(data.otherDetails.headLamp);
        setTailLamp(data.otherDetails.tailLamp);
        setFrontIndicators(data.otherDetails.frontIndicators);
        setRearIndicators(data.otherDetails.rearIndicators);
        setMaintenanceLevel(data.otherDetails.levelOfMaintenance);

        setFrontBreakCondition(data.otherDetails.frontBrakeCondition);
        setRearBreakCondition(data.otherDetails.rearBrakeCondition);

        setFrontBreakType(data.twoWheeler.brakes.frontBrakeType);
        setRearBreakType(data.twoWheeler.brakes.rearBrakeType);
        setTwoWheelerTyreType(data.twoWheeler.TyresNConditions.type);
        setTwoWheelerLhFront(data.twoWheeler.TyresNConditions.lhFront);
        setTwoWheelerLhRear(data.twoWheeler.TyresNConditions.lhRear);
        setTwoWheelerBaterryCondition(data.twoWheeler.electricals.battery);
        setTwoWheelerIngnition(data.twoWheeler.electricals.ignitionType);
        setTwoWheelerElecCon(data.twoWheeler.electricals.electricalCondition);
        setTwoWheelerSpeedometer(data.twoWheeler.electricals.speedometerPanel);
        setTwoWheelerAccessories(data.twoWheeler.electricals.accessories);
        setTwoWheelerBodyPaint(data.twoWheeler.vehicleExterior.bodyPaint);
        setTwoWheelerPaintCondition(
          data.twoWheeler.vehicleExterior.paintCondition
        );
        setTwoWheelerBodyCondition(
          data.twoWheeler.vehicleExterior.bodyCondition
        );
        setTwoWheelerToolKit(data.twoWheeler.vehicleExterior.toolKit);
        setCoolingSystem(data.twoWheeler.engine.coolingSystem);
        setCoolingSystemCondition(
          data.twoWheeler.engine.coolingSystemCondition
        );
        setFuelTank(data.twoWheeler.engine.fuelTank);
        setSilencer(data.twoWheeler.engine.silencer);
        setFrontSuspension(data.twoWheeler.suspension.front);
        setRearSuspension(data.twoWheeler.suspension.rear);
        setClutch(data.twoWheeler.transmission.clutchCondition);
        setGearBox(data.twoWheeler.transmission.gearboxCondition);

        setYourRemark(data.yourRemark);
        setExShowRoomPrice(data.marketPrice);
        setEstimatedPrice(data.valuation);

        let path = "";
        if (data.assetType === "Two Wheeler") {
          path = `https://instavaluer.com/twowheelerreportrelease/${params.id}`;
        } else if (data.assetType === "Four Wheeler") {
          path = `https://instavaluer.com/releasereport/${params.id}`;
        } else if (data.assetType === "Commercial") {
          path = `https://instavaluer.com/commercialreportrelease/${params.id}`;
        } else if (data.assetType === "Construction Equipment") {
          path = `https://instavaluer.com/constructionreportrelease/${params.id}`;
        } else if (data.assetType === 'Three Wheeler') {
          path = `https://instavaluer.com/threewheelerreportrelease/${params._id}`
        } else if (data.assetType === 'Farm Equipment') {
          path = `https://instavaluer.com/firmequipmentreportrelease/${params._id}`
        }
        setUrlPath(path);
        const imagePath = `https://instavaluer.com/imagereport/${params.id}`
        setImagePath(imagePath)
      }
    };
    fetchData();
  }, [params.id]);

  const handleSubmit = async () => {
    if (engineCondition === '') {
      setEngineConditionError('Please enter engine condition')
    } else {
      setEngineConditionError('')
    }
    if (exteriorCondition === '') {
      setExteriorConditionError('Please enter exterior condition')
    } else {
      setExteriorConditionError('')
    }
    if (gearbox === '') {
      setGearBoxError('Please enter gearbox condition')
    } else {
      setGearBoxError('')
    }
    if (frontSuspension === '') {
      setFrontSuspensionError('Please enter front suspension condition')
    } else {
      setFrontSuspensionError('')
    }
    if (rearSuspension === '') {
      setRearSuspensionError('Please enter rear suspension condition')
    } else {
      setRearSuspensionError('')
    }
    if (frontBreakCondition === '') {
      setFrontBreakConditionError('Please enter front break condition')
    } else {
      setFrontBreakConditionError('')
    }
    if (rearBreakCondition === '') {
      setRearBreakConditionError('Please enter rear break condition')
    } else {
      setRearBreakConditionError('')
    }
    if (twoWheelerLhFront === '') {
      setTwoWheelerLhRearError('Please enter lh front tyre condition')
    } else {
      setTwoWheelerLhRearError('')
    }
    if (twoWheelerLhRear === '') {
      setTwoWheelerLhFrontError('Please enter lh rear tyre condition')
    } else {
      setTwoWheelerLhFrontError('')
    }
    if (exShowRoomPrice === "") {
      setExShowRoomPriceError("Please enter Exshowroom Price");
    } else {
      setExShowRoomPriceError("");
    }
    if (estimatedPrice === "") {
      setEstimatedPriceError("Please enter Estimated Price");
    } else {
      setEstimatedPriceError("");
    }
    if (engineCondition !== '' && exteriorCondition !== '' && gearbox !== '' && frontSuspension !== '' && rearSuspension !== '' && frontBreakCondition !== '' && rearBreakCondition !== '' && twoWheelerLhFront !== '' && twoWheelerLhRear !== '' && exShowRoomPrice !== "" && estimatedPrice !== "") {

      const currentDate = new Date();
      const todayDate = currentDate.toISOString().slice(0, 10)

      const formData = {
                    meterReading: meterReading,
        accessories: {
          ac: airConditioner,
          cdCharger: cdCharge,
          seatCover: seatCover,
          fogLamps: fogLamps,
        },
        additionalFeatures: {
          transmission: transmission,
          odometer: odometer,
        },
        otherDetails: {
          accidental: accidental,
          otherRepair: otherRepair,
          fitnessExpiry: fitnessExpiry,
          roadTaxValidity: roadtaxValidity,
          IDVValue: idvValue,
          insuranceExpiry: insuranceExpiry,
          engineCondition: engineCondition,
          frontBrakeCondition: frontBreakCondition,
          rearBrakeCondition: rearBreakCondition,
          wheelsType: wheelType,
          totalTyre: totalTyre,
          availableTyre: availableTyre,
          missingTyre: missingTyre,
          spareTyre: spareTyre,
          exteriorCondition: exteriorCondition,
          headLamp: headLamp,
          tailLamp: tailLamp,
          frontIndicators: frontIndicators,
          rearIndicators: rearIndicators,
          levelOfMaintenance: maintenanceLevel,
        },
        twoWheeler: {
          engine: {
            coolingSystem: coolingSystem,
            coolingSystemCondition: coolingSystemCondition,
            fuelTank: fuelTank,
            silencer: silencer,
          },
          transmission: {
            clutchCondition: clutch,
            gearboxCondition: gearbox,
          },
          suspension: {
            front: frontSuspension,
            rear: rearSuspension,
          },
          brakes: {
            frontBrakeType: frontBreakType,
            rearBrakeType: rearBreakType,
          },
          TyresNConditions: {
            type: twoWheelerTyreType,
            lhFront: twoWheelerLhFront,
            lhRear: twoWheelerLhRear,
          },
          electricals: {
            battery: twoWheelerBatteryCondtion,
            ignitionType: twoWheelerIgnition,
            speedometerPanel: twoWheelerSpeedometer,
            accessories: twoWheelerAccessories,
            electricalCondition: twoWheelerElecCon,
          },
          vehicleExterior: {
            bodyPaint: twoWheelerBodyPaint,
            paintCondition: twoWheelerPaintCondition,
            bodyCondition: twoWheelerBodyCondition,
            toolKit: twoWheelerToolKit,
          },
        },

        yourRemark: yourRemark,
        valuation: estimatedPrice,
        marketPrice: exShowRoomPrice,
        reportUrl: urlPath,
        imageUrl: imagePath,
        submittedAt: todayDate,
        status: 5,
        statusText: "Case Url Updated",
        currentForm: 4,
      };
      console.log(formData, 'formData');
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        navigation(`/twowheelerreportrelease/${params.id}`);
      }
    } else {
      setPressBtn(true);
    }
  };

  console.log(twoWheelerLhFront, 'twoWheelerLhFront');
  console.log(twoWheelerLhRear, 'twoWheelerLhRear');

  useEffect(() => {
    if (
      gearbox !== '' &&
      engineCondition !== '' &&
      frontSuspension !== '' &&
      rearSuspension !== '' &&
      twoWheelerElecCon !== '' &&
      exteriorCondition !== '' &&
      frontBreakCondition !== '' &&
      rearBreakCondition !== '' &&
      twoWheelerLhFront !== '' &&
      twoWheelerLhRear !== '' &&
      exShowRoomPrice !== ''
    ) {
      const roundImageArray = [
        { label: "Poor", percentage: 2 },
        { label: "Average", percentage: 4 },
        { label: "Satisfactory", percentage: 5 },
        { label: "Good", percentage: 7 },
        { label: "Excellent", percentage: 9 },
      ];

      // Function to find percentage by label from roundImageArray
      const findPercentageByLabel = (label) => {
        const item = roundImageArray.find(data => data.label === label);
        return item ? item.percentage : 0; // Default to 0 if not found
      };

      // Calculate scores and conditions
      const findEngineCon = findPercentageByLabel(engineCondition);
      const findTransmissionCon = findPercentageByLabel(gearbox);
      const findFrontSuspensionCon = findPercentageByLabel(frontSuspension);
      const findRearSuspensionCon = findPercentageByLabel(rearSuspension);
      const findElectricalCon = findPercentageByLabel(twoWheelerElecCon);
      const findExteriorCon = findPercentageByLabel(exteriorCondition);
      const findFrontBrakesCon = findPercentageByLabel(frontBreakCondition);
      const findRearBrakesCon = findPercentageByLabel(rearBreakCondition);

      // Calculate average conditions
      const findSuspensionCon = Math.round((findFrontSuspensionCon + findRearSuspensionCon) / 2);
      const findTyreCon = Math.round((Number(twoWheelerLhFront) + Number(twoWheelerLhRear)) / 20);
      const findBrakesCon = Math.round((findFrontBrakesCon + findRearBrakesCon) / 2);

      // Determine final conditions based on ranges
      const getFinalCondition = (value) => {
        if (value <= 2) return 2;
        else if (value <= 4) return 4;
        else if (value <= 6) return 5;
        else if (value <= 8) return 7;
        else return 9;
      };

      const finalSuspensionCon = getFinalCondition(findSuspensionCon);
      const finalTyreCondition = getFinalCondition(findTyreCon);
      const finalBreakCondition = getFinalCondition(findBrakesCon);

      // Calculate overall rating and average
      const overalRating =
        findEngineCon * 3 +
        findTransmissionCon +
        finalSuspensionCon +
        findElectricalCon +
        findExteriorCon +
        finalBreakCondition +
        finalTyreCondition;

      const overalavg = overalRating / 10;

      // Define depreciation percentages based on overall average
      let deprePerYear = {};
      if (overalavg >= 8) {
        deprePerYear = { 1: 7, 2: 14, 3: 18, 4: 20, 5: 25, 6: 28, 7: 32, 8: 34, 9: 36, 10: 38, 11: 48 };
      } else if (overalavg >= 7) {
        deprePerYear = { 1: 10, 2: 16, 3: 22, 4: 25, 5: 28, 6: 30, 7: 35, 8: 38, 9: 38, 10: 40, 11: 48 };
      } else if (overalavg >= 6) {
        deprePerYear = { 1: 15, 2: 20, 3: 25, 4: 30, 5: 35, 6: 40, 7: 42, 8: 42, 9: 44, 10: 46, 11: 48 };
      } else if (overalavg >= 5) {
        deprePerYear = { 1: 25, 2: 35, 3: 45, 4: 47, 5: 49, 6: 52, 7: 53, 8: 55, 9: 55, 10: 55, 11: 55 };
      } else {
        deprePerYear = { 1: 60, 2: 60, 3: 60, 4: 60, 5: 60, 6: 60, 7: 60, 8: 60, 9: 60, 10: 60, 11: 60 };
      }

      // Calculate manufacturing year and depreciation
      const mfg = vehicleEdetails.vehicleManufacturingMonthYear.slice(-4);
      const currentYear = new Date().getFullYear();
      const depreYear = currentYear - parseInt(mfg, 10);

      // Apply depreciation based on year or set a default
      let depreciationValue = 0;
      if (depreYear >= 11) {
        depreciationValue = exShowRoomPrice * (1 - 0.48); // 48% depreciation
      } else if (deprePerYear.hasOwnProperty(depreYear)) {
        const percentageValue = deprePerYear[depreYear] / 100;
        depreciationValue = exShowRoomPrice * (1 - percentageValue);
      }

      // Update state with rounded down values
      setEstimatedPrice(Math.floor(depreciationValue));
      setDepreciatedPrice(Math.floor(depreciationValue));
    }
  }, [
    gearbox, engineCondition, frontSuspension, rearSuspension,
    twoWheelerElecCon, exteriorCondition, frontBreakCondition,
    rearBreakCondition, twoWheelerLhFront, twoWheelerLhRear,
    exShowRoomPrice
  ]);

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div className="flex items-center justify-between py-2 px-5"
              style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            >
              <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                Edit Case Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="seatCover"
                    className="text-sm font-medium text-default-900"
                  >
                    Seat Cover *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="seatCover"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectSeatCoverChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Seat Cover
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === seatCover}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMeterReading"
                    className="text-sm font-medium text-default-900"
                  >
                    Meter Reading *
                  </label>
                  <div className="textfieldcontainer">
                    <TextField
                      label="Meter Reading"
                      variant="outlined"
                      className={
                        "textfield"
                      }
                      style={{ marginTop: '5px' }}
                      type="text"
                      id="text"
                      value={meterReading}
                      onChange={(e) => setMeterReading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fogLamps"
                    className="text-sm font-medium text-default-900"
                  >
                    Fog Lamps *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fogLamps"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectFogLampsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Fog Lamps
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === fogLamps}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="transmission"
                    className="text-sm font-medium text-default-900"
                  >
                    Transmission *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="transmission"
                    placeholder="This is a search placeholder"
                    value={typeList.id}
                    name={typeList.name}
                    onChange={(e) => selectTransmissionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Transmission
                    </option>
                    {typeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === transmission}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="odometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Odometer *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="odometer"
                    placeholder="This is a search placeholder"
                    value={Features.id}
                    name={Features.name}
                    onChange={(e) => selectOdometerChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Odometer
                    </option>
                    {Features.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === odometer}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="accidental"
                    className="text-sm font-medium text-default-900"
                  >
                    Accidental *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="accidental"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectAccidentalChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Accidental
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === accidental}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="otherRepair"
                    className="text-sm font-medium text-default-900"
                  >
                    Other Repair *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="otherRepair"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectotherRepairChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Other Repair
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === otherRepair}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fFitnessExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Fitness Expiry *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fFitnessExpiry"
                    placeholder="Enter Fitness Expiry"
                    value={fitnessExpiry}
                    onChange={(e) => setFitnessExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fRoadtaxValidity"
                    className="text-sm font-medium text-default-900"
                  >
                    Roadtax Validity *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fRoadtaxValidity"
                    placeholder="Enter Roadtax Validity"
                    value={roadtaxValidity}
                    onChange={(e) => setroadtaxValidity(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fIdvValue"
                    className="text-sm font-medium text-default-900"
                  >
                    Idv Value *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fIdvValue"
                    placeholder="Enter Idv Value"
                    value={idvValue}
                    onChange={(e) => setIdvValue(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fInsuranceExpiry"
                    className="text-sm font-medium text-default-900"
                  >
                    Insurance Expiry *
                  </label>
                  <input
                    type="Date"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fInsuranceExpiry"
                    placeholder="Enter Insurance Expiry"
                    value={insuranceExpiry}
                    onChange={(e) => setInsuranceExpiry(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="engineCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Engine Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      engineConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="engineCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectEngineConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Engine Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === engineCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="wheelType"
                    className="text-sm font-medium text-default-900"
                  >
                    Wheel Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="wheelType"
                    placeholder="This is a search placeholder"
                    value={wheelTypeList.id}
                    name={wheelTypeList.name}
                    onChange={(e) => selectWheelTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Wheel Type
                    </option>
                    {wheelTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === wheelType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fTotalTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Total Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fTotalTyre"
                    placeholder="Enter Total Tyre"
                    value={totalTyre}
                    onChange={(e) => setTotalTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="availableTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Available Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="availableTyre"
                    placeholder="Enter Available Tyre"
                    value={availableTyre}
                    onChange={(e) => setAvailableTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fMissingTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Missing Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fMissingTyre"
                    placeholder="Enter Missing Tyre"
                    value={missingTyre}
                    onChange={(e) => setMissingTyre(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fSpareTyre"
                    className="text-sm font-medium text-default-900"
                  >
                    Spare Tyre *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fSpareTyre"
                    placeholder="Enter Spare Tyre"
                    value={spareTyre}
                    onChange={(e) => setSpareTyre(e.target.value)}
                  />
                </div>
                {/* <div className="space-y-1">
                  <label
                    htmlFor="fInteriorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Interior Condition *
                  </label>
                  <input
                    type="text"
                    className={
                      "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fInteriorCondition"
                    placeholder="Enter Interior Condition"
                    value={interiorCondition}
                    onChange={(e) => setInteriorCondition(e.target.value)}
                  />
                </div> */}
                <div className="space-y-1">
                  <label
                    htmlFor="exteriorCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Exterior Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      exteriorConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="exteriorCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectExteriorConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Exterior Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === exteriorCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="headLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Head Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="headLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectHeadLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Head Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        def={test.name==="Satisfactory"?true:false}
                        selected={test.name === headLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="tailLamp"
                    className="text-sm font-medium text-default-900"
                  >
                    Tail Lamp *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="tailLamp"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTailLampChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Tail Lamp
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === tailLamp}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Indicators *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="frontIndicators"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectFrontIndicatorsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front Indicators
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontIndicators}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearIndicators"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Indicators *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="rearIndicators"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectRearIndicatorsChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Indicators
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearIndicators}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="maintenanceLevel"
                    className="text-sm font-medium text-default-900"
                  >
                    Maintenance Level *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="maintenanceLevel"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectMaintenanceLevelChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Maintenance Level
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === maintenanceLevel}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="coolingSystem"
                    className="text-sm font-medium text-default-900"
                  >
                    Cooling System *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="coolingSystem"
                    placeholder="This is a search placeholder"
                    value={coolingSystemList.id}
                    name={coolingSystemList.name}
                    onChange={(e) => selectCoolingSystemChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Cooling System
                    </option>
                    {coolingSystemList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === coolingSystem}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="coolingSystemCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Cooling System Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="coolingSystemCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectCoolingSystemConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Cooling System Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === coolingSystemCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fuelTank"
                    className="text-sm font-medium text-default-900"
                  >
                    Fuel Tank *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="fuelTank"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectFuelTankChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Fuel Tank
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === fuelTank}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="silencer"
                    className="text-sm font-medium text-default-900"
                  >
                    Silencer *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="silencer"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectSilencerChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Silencer
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === silencer}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="clutch"
                    className="text-sm font-medium text-default-900"
                  >
                    Clutch *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="clutch"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectClutchChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Clutch
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === clutch}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="gearbox"
                    className="text-sm font-medium text-default-900"
                  >
                    Gearbox <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      gearboxError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="gearbox"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectGearBoxChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Gearbox
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === gearbox}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontSuspension"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Suspension <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      frontSuspensionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="frontSuspension"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectFrontSuspensionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front Suspension
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontSuspension}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearSuspension"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Suspension <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      rearSuspensionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="rearSuspension"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectRearSuspensionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Suspension
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearSuspension}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontBreakType"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Break Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="frontBreakType"
                    placeholder="This is a search placeholder"
                    value={breakTypeList.id}
                    name={breakTypeList.name}
                    onChange={(e) => selectFrontBreakTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front Break Type
                    </option>
                    {breakTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontBreakType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="frontBreakCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Front Break Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      frontBreakConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="frontBreakCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectFrontBreakConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Front Break Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === frontBreakCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearBreakType"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Break Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="rearBreakType"
                    placeholder="This is a search placeholder"
                    value={breakTypeList.id}
                    name={breakTypeList.name}
                    onChange={(e) => selectRearBreakTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Break Type
                    </option>
                    {breakTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearBreakType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="rearBreakCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Rear Break Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      rearBreakConditionError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="rearBreakCondition"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectRearBreakConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Rear Break Condition
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === rearBreakCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerTyreType"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Tyre Type *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerTyreType"
                    placeholder="This is a search placeholder"
                    value={twoWheelerTyreList.id}
                    name={twoWheelerTyreList.name}
                    onChange={(e) => selectTwoWheelerTyreTypeChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Tyre Type
                    </option>
                    {twoWheelerTyreList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerTyreType}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerLhFront"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Lh Front <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      twoWheelerLhFrontError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="twoWheelerLhFront"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectTwoWheelerLhFrontChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Lh Front
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === twoWheelerLhFront}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerLhRear"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Lh Rear <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      twoWheelerLhRearError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="twoWheelerLhRear"
                    placeholder="This is a search placeholder"
                    value={list.id}
                    name={list.name}
                    onChange={(e) => selectTwoWheelerLhRearChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Lh Rear
                    </option>
                    {list.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={Number(test.name) === twoWheelerLhRear}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerBatteryCondtion"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Battery Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerBatteryCondtion"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTwoWheelerBaterryConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Battery Condtion
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerBatteryCondtion}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerIgnition"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Ignition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerIgnition"
                    placeholder="This is a search placeholder"
                    value={twoIgnitionTypeList.id}
                    name={twoIgnitionTypeList.name}
                    onChange={(e) => selectTwoWheelerIngnitionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Ignition
                    </option>
                    {twoIgnitionTypeList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerIgnition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerElecCon"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Electrical Condition <span style={{ color: 'red' }}>**</span>
                  </label>
                  <select
                    className={
                      twoWheelerElecConError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="twoWheelerElecCon"
                    placeholder="This is a search placeholder"
                    value={reqCondition.id}
                    name={reqCondition.name}
                    onChange={(e) => selectTwoWheelerElecConChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler ElecCon
                    </option>
                    {reqCondition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerElecCon}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerAccessories"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Accessories *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerAccessories"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectTwoWheelerAccessoriesChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Accessories
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerAccessories}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerSpeedometer"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler Speedometer *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerSpeedometer"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectTwoWheelerSpeedometerChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler Speedometer
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerSpeedometer}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerBodyPaint"
                    className="text-sm font-medium text-default-900"
                  >
                    Two Wheeler BodyPaint *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerBodyPaint"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectTwoWheelerBodyPaintChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Two Wheeler BodyPaint
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerBodyPaint}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerPaintCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Paint Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerPaintCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTwoWheelerPaintConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Paint Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerPaintCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerBodyCondition"
                    className="text-sm font-medium text-default-900"
                  >
                    Body Condition *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerBodyCondition"
                    placeholder="This is a search placeholder"
                    value={condition.id}
                    name={condition.name}
                    onChange={(e) => selectTwoWheelerBodyConditionChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Body Condition
                    </option>
                    {condition.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerBodyCondition}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="twoWheelerToolKit"
                    className="text-sm font-medium text-default-900"
                  >
                    ToolKit *
                  </label>
                  <select
                    className={`border-default-200 w-full`}
                    data-trigger
                    id="twoWheelerToolKit"
                    placeholder="This is a search placeholder"
                    value={options.id}
                    name={options.name}
                    onChange={(e) => selectTwoWheelerToolKitChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose ToolKit
                    </option>
                    {options.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                        selected={test.name === twoWheelerToolKit}
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Your Remark On Vehicle *
                  </label>
                  <input
                    type="text"
                    className={
                      `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter your remark"
                    value={yourRemark}
                    onChange={(e) => setYourRemark(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fExShowRoomPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Ex-ShowRoom Price *
                  </label>
                  <input
                    type="text"
                    className={
                      exShowRoomPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fExShowRoomPrice"
                    placeholder="Enter Ex-ShowRoom Price"
                    value={exShowRoomPrice}
                    onChange={(e) => setExShowRoomPrice(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fEstimatedPrice"
                    className="text-sm font-medium text-default-900"
                  >
                    Estimated Price *
                  </label>
                  <input
                    type="text"
                    className={
                      estimatedPriceError === ""
                        ? `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                        : `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                    }
                    id="fEstimatedPrice"
                    placeholder="Enter Estimated Price"
                    value={estimatedPrice}
                    onChange={(e) => setEstimatedPrice(e.target.value)}
                  />
                  {
                    depreciatedPrice ?
                      <div>The recommended estimated price for the vehicle is <span style={{ color: 'red' }}>₹{depreciatedPrice}</span></div> : <></>
                  }
                </div>
              </div>
              <div
                className="flex items-center gap-2 justify-end"
                style={{ marginBottom: "80px" }}
              >
                <button
                  style={{
                    background: "#ef4444",
                    color: "#fff",
                    border: "none",
                    outline: "none",
                  }}
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                  onClick={() => navigation(`/admin/editreport3/${params.id}`)}
                >
                  <i className="ph-bold ph-x text-lg" />
                  Go Back
                </button>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => handleSubmit()}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  View Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoWheelerEditReport4;
