import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  isOrganisationLoading: true,
  organisationLogo: "",
  logoId:""
};

export const uploadOrganisationLogo = createAsyncThunk(
  "logo/ornisationLogo",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/oranisationlogo/uploadlogo`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      thunkAPI.rejectWithValue("Organisation image not able to upload");
    }
  }
);
export const createOrganisationLogo = createAsyncThunk(
  "logo/createOrganisationLogo",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/oranisationlogo/createlogo`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      thunkAPI.rejectWithValue("oranisation image not able to upload");
    }
  }
);
export const getOrgLogo = createAsyncThunk(
    "logo/getOrgLogo",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/oranisationlogo/getlogo`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("Brand Logo not able to fetch");
      }
    }
  );
  export const updateLogo=createAsyncThunk(
    "logo/updateLogo",
    async(formData,thunkAPI)=>{
      try{
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/oranisationlogo/updatelogo/${formData._id}`;
        const resp = await axios.put(url,formData,config);
        return resp.data;
      }catch(error){
        return thunkAPI.rejectWithValue("Brand Logo not able to update");
      }
    }
  )
const orgLogoSlice = createSlice({
  name: "orgLogo",
  initialState,
  reducers: {
    removeOrgLogo(state) {
      state.isOrganisationLoading = true;
      state.organisationLogo = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadOrganisationLogo.pending, (state) => {
        state.isOrganisationLoading = true;
      })
      .addCase(uploadOrganisationLogo.fulfilled, (state, action) => {
        state.organisationLogo = action.payload.logo;
        state.isOrganisationLoading = false;
      })
      .addCase(uploadOrganisationLogo.rejected, (state) => {
        state.isOrganisationLoading = true;
      })
      .addCase(createOrganisationLogo.pending,(state)=>{
        state.isOrganisationLoading=true
      })
      .addCase(createOrganisationLogo.fulfilled,(state,action)=>{
        state.organisationLogo=action.payload.logo
        state.isOrganisationLoading=false
      })
      .addCase(createOrganisationLogo.rejected,(state)=>{
        state.isOrganisationLoading=true
      })
      .addCase(getOrgLogo.pending,(state)=>{
        state.isOrganisationLoading=true
      })
      .addCase(getOrgLogo.fulfilled,(state,action)=>{
        state.organisationLogo=action.payload.logo[0].logo
        state.logoId=action.payload.logo[0]._id
        state.isOrganisationLoading=false
      })
      .addCase(getOrgLogo.rejected,(state)=>{
        state.isOrganisationLoading=true
      })
      .addCase(updateLogo.pending,(state)=>{
        state.isOrganisationLoading=true
      })
      .addCase(updateLogo.fulfilled,(state,action)=>{
        state.organisationLogo=action.payload.logo.logo;
        state.isOrganisationLoading=false
      })
      .addCase(updateLogo.rejected,(state)=>{
        state.isOrganisationLoading=true
      })
  },
});
export const orgLogoActions = orgLogoSlice.actions;
export default orgLogoSlice.reducer;