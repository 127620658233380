import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TodaySubmitCase from "./MisReport/TodaySubmitCase";
import { CaseActions } from "../redux/Cases/CaseSlice";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((store) => store.auth);
  const { employeeList } = useSelector((store) => store.employee);
  const { totalrevenue, totalCost } = useSelector((store) => store.client);
  const { caseCreated, pendingCases, submittedCases, readyReport } =
    useSelector((store) => store.cases);
  const [todaySubmitCase, setTodaySubmitCase] = useState([]);
  const [todayReadyReport, setTodayReadyReport] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState([]);
  const [filterToBeAssignCases, setFilterToBeAssignCases] = useState([]);
  const [filterPendingCases, setFilterPendingCases] = useState([]);
  const [filterSubmittedCases, setFilterSubmittedCases] = useState([]);
  const [filterReadyReport, setFilterReadyReport] = useState([]);
  useEffect(() => {
    if (loginData?.role === "businessAssociate") {
      const data = caseCreated.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      setFilterToBeAssignCases(data);
    } else {
      setFilterToBeAssignCases(caseCreated);
    }
    if (loginData?.role === "businessAssociate") {
      const data = pendingCases.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      setFilterPendingCases(data);
    } else {
      setFilterPendingCases(pendingCases);
    }
    if (loginData?.role === "businessAssociate") {
      const data = submittedCases.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      setFilterSubmittedCases(data);
    } else {
      setFilterSubmittedCases(submittedCases);
    }
    if (loginData?.role === "businessAssociate") {
      const data = readyReport.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      setFilterReadyReport(data);
    } else {
      setFilterReadyReport(readyReport);
    }
  }, [caseCreated, pendingCases, submittedCases, readyReport]);
  console.log(readyReport.length, 'readyReport');

  useEffect(() => {
    if (
      loginData &&
      loginData.permittedState &&
      loginData.role === "businessAssociate"
    ) {
      const data = employeeList.filter((indEmployee) =>
        loginData.permittedState.some((state) =>
          indEmployee.permittedState.includes(state)
        )
      );
      setFilterEmployee(data);
    } else {
      setFilterEmployee(employeeList);
    }
  }, [employeeList]);

  useEffect(() => {
    let date = new Date();
    if (loginData?.role === "businessAssociate") {
      const data = submittedCases.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      const todayCases = data.filter((individualCase) => {
        const date2 = new Date(individualCase.createdAt);
        // Compare the dates
        return (
          date.getDate() === date2.getDate() &&
          date.getMonth() === date2.getMonth() &&
          date.getFullYear() === date2.getFullYear()
        );
      });
      setTodaySubmitCase(todayCases);
    } else {
      const todayCases = submittedCases.filter((individualCase) => {
        const date2 = new Date(individualCase.createdAt);
        // Compare the dates
        return (
          date.getDate() === date2.getDate() &&
          date.getMonth() === date2.getMonth() &&
          date.getFullYear() === date2.getFullYear()
        );
      });
      setTodaySubmitCase(todayCases);
    }
    // Set today's submitted cases
  }, [submittedCases]);

  useEffect(() => {
    let date = new Date();
    if (loginData?.role === "businessAssociate") {
      const data = readyReport.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      // Filter cases submitted today
      const todayCases = data.filter((individualCase) => {
        const date2 = new Date(individualCase.createdAt);
        // Compare the dates
        return (
          date.getDate() === date2.getDate() &&
          date.getMonth() === date2.getMonth() &&
          date.getFullYear() === date2.getFullYear()
        );
      });

      // Set today's Ready Report
      setTodayReadyReport(todayCases);
    } else {
      // Filter cases submitted today
      const todayCases = readyReport.filter((individualCase) => {
        const date2 = new Date(individualCase.createdAt);
        // Compare the dates
        return (
          date.getDate() === date2.getDate() &&
          date.getMonth() === date2.getMonth() &&
          date.getFullYear() === date2.getFullYear()
        );
      });

      // Set today's Ready Report
      setTodayReadyReport(todayCases);
    }
  }, [readyReport]);

  const handleSubmitCase = () => {
    if (todaySubmitCase.length > 0) {
      dispatch(CaseActions.setTodaySubmittedCases(todaySubmitCase));
      navigate("/admin/todaysubmittedcases");
    } else {
      toast("Oops no submited cases for today!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#ff3333", // Set background color to red
          color: "white", // Set text color to white
        },
        transition: Bounce,
      });
    }
  };
  const handleReadyReport = () => {
    if (todayReadyReport.length > 0) {
      dispatch(CaseActions.setTodayReadyReport(todayReadyReport));
      navigate("/admin/todayreadyreport");
    } else {
      toast("Oops no ready reports are there !", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "red", // Set background color to red
          color: "white", // Set text color to white
        },
        transition: Bounce,
      });
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-6 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <ol
              aria-label="Breadcrumb"
              className="hidden md:flex items-center whitespace-nowrap min-w-0 gap-2"
            >
              <li className="text-sm">
                <a
                  className="flex items-center gap-2 align-middle text-default-800 font-medium transition-all hover:text-primary-500"
                  href="javascript:void(0)"
                >
                  Instavaluer
                  <FaChevronRight />
                </a>
              </li>
              <li className="text-sm">
                <a
                  className="flex items-center gap-2 align-middle text-default-800 font-medium transition-all hover:text-primary-500"
                  href="javascript:void(0)"
                >
                  Admin
                  <FaChevronRight />
                </a>
              </li>
              <li
                aria-current="page"
                className="text-sm font-medium text-primary-600 truncate hover:text-primary-500"
              >
                Dashboard
              </li>
            </ol>
          </div>
          <div
            className="grid md:grid-cols-2 xl:grid-cols-4 gap-6"
            style={{ marginBottom: "60px" }}
          >
            {
              loginData?.role !== 'businessAssociate' ?
                <>    <div
                  className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
                  style={{ backgroundColor: "#4d44b5" }}
                >
                  <div className="p-5">
                    <div className="flex flex-wrap items-center justify-between">
                      <div className="mb-3">
                        <span
                          className="text-base font-semibold text-default-900"
                          style={{ color: "#f2f2f2" }}
                        >
                          All Employees :
                        </span>
                        <span
                          className="inline-block ps-3"
                          style={{ color: "#f2f2f2", fontWeight: "600" }}
                        >
                          {filterEmployee.length}
                        </span>
                      </div>
                      <span
                        className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src="/assets/view-3d-businessman_23-2150709814.jpg"
                          alt
                          style={{
                            height: "100%",
                            width: "100%",
                            objectPosition: "center",
                          }}
                        />
                      </span>
                    </div>
                    <Link
                      to="/admin/employees/listemployees"
                      className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                      style={{ color: "#fff" }}
                    >
                      View data
                      <i
                        data-lucide="move-right"
                        className="h-4 w-4 inline align-middle ms-1"
                        style={{ color: "#fff" }}
                      />
                    </Link>
                  </div>
                </div>
                </> :
                <>
                </>
            }
            {
              loginData?.role !== 'businessAssociate' ?
                <>
                  <div
                    className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
                    style={{ backgroundColor: "#3399fe" }}
                  >
                    <div className="p-5">
                      <div className="flex flex-wrap items-center justify-between">
                        <div>
                          <p
                            className="text-base font-semibold text-default-900"
                            style={{ color: "#f2f2f2" }}
                          >
                            Today's Report
                          </p>
                          <span
                            className="inline-block text-sm font-medium text-default-600 mt-3"
                            style={{ color: "#f2f2f2" }}
                          >
                            Submitted Cases{" "}
                            <span className="ps-1">({todaySubmitCase.length})</span>
                          </span>
                          <div
                            className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                            onClick={() => handleSubmitCase()}
                            style={{ color: "#f2f2f2", textDecoration: "underline" }}
                          >
                            View data
                          </div>
                          <p>
                            <span
                              className="inline-block text-sm font-medium text-default-600 mt-3"
                              style={{ color: "#f2f2f2" }}
                            >
                              Ready Reports{" "}
                              <span className="ps-1">
                                ({todayReadyReport.length})
                              </span>{" "}
                            </span>
                          </p>
                          <div
                            className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                            onClick={() => handleReadyReport()}
                            style={{ color: "#f2f2f2" }}
                          >
                            View data
                            {/* <i data-lucide="move-right" className="h-4 w-4 inline align-middle ms-1" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                : <></>
            }
            {
              loginData?.role !== 'businessAssociate' ?
                <>
                  <div
                    className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
                    style={{ backgroundColor: "#f9b015" }}
                  >
                    <div className="p-5">
                      <div className="flex flex-wrap items-center justify-between">
                        <div>
                          <p
                            className="text-base font-semibold text-default-900"
                            style={{ color: "#f2f2f2" }}
                          >
                            MIS Report
                          </p>
                          <p>
                            <span
                              className="inline-block text-sm font-medium text-default-600 mt-3"
                              onClick={() => navigate("/admin/monthlyassignedcase")}
                              style={{ color: "#f2f2f2" }}
                            >
                              To Be Assign Cases{" "}
                              <span className="ps-1">
                                ({filterToBeAssignCases.length})
                              </span>
                            </span>
                          </p>
                          <p>
                            <span
                              className="inline-block text-sm font-medium text-default-600 mt-3"
                              onClick={() => navigate("/admin/monthlycasepending")}
                              style={{ color: "#f2f2f2" }}
                            >
                              Pending Cases{" "}
                              <span className="ps-1">
                                ({filterPendingCases.length})
                              </span>
                            </span>
                          </p>
                          <p>
                            <span
                              className="inline-block text-sm font-medium text-default-600 mt-3"
                              onClick={() => navigate("/admin/monthlysubmittedcase")}
                              style={{ color: "#f2f2f2" }}
                            >
                              Submitted Cases{" "}
                              <span className="ps-1">
                                ({filterSubmittedCases.length})
                              </span>
                            </span>
                          </p>
                          <p>
                            <span
                              className="inline-block text-sm font-medium text-default-600 mt-3"
                              onClick={() => navigate("/admin/monthlyreadyreport")}
                              style={{ color: "#f2f2f2" }}
                            >
                              Ready Reports{" "}
                              <span className="ps-1">
                                ({filterReadyReport.length})
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <></>
            }
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#e55353" }}
            >
              <div className="p-5">
                <div className="flex flex-wrap items-center justify-between">
                  <div>
                    <span className="text-base font-semibold text-default-900">
                      To Be Assign Cases :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {filterToBeAssignCases.length}
                    </span>
                  </div>
                  <span className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80">
                    <img
                      src="assets/school-be034c54.png"
                      alt
                      className="h-16"
                    />
                  </span>
                </div>
                <Link
                  to="/admin/assignedcases"
                  className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                >
                  View data
                  <i
                    data-lucide="move-right"
                    className="h-4 w-4 inline align-middle ms-1"
                  />
                </Link>
              </div>
            </div>
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#33cc33" }}
            >
              <div className="p-5">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="mb-3">
                    <span className="text-base font-semibold text-default-900">
                      Pending Cases :{" "}
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {filterPendingCases.length}
                    </span>
                  </div>
                  <span
                    className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src="/assets/16562.jpg"
                      alt
                      style={{
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                      }}
                    />
                  </span>
                </div>
                <Link
                  to="/admin/pendingcases"
                  className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                >
                  View data
                  <i
                    data-lucide="move-right"
                    className="h-4 w-4 inline align-middle ms-1"
                  />
                </Link>
              </div>
            </div>
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#009999" }}
            >
              <div className="p-5">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="mb-3">
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#f2f2f2" }}
                    >
                      Submitted Cases :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#f2f2f2", fontWeight: "600" }}
                    >
                      {filterSubmittedCases.length}
                    </span>
                  </div>
                  <span
                    className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src="/assets/submit.jpg"
                      alt
                      style={{
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                      }}
                    />
                  </span>
                </div>
                <Link
                  to="/admin/submittedcases"
                  className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                  style={{ color: "#f2f2f2" }}
                >
                  View data
                </Link>
              </div>
            </div>
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#ff9933" }}
            >
              <div className="p-5">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="mb-3">
                    <span className="text-base font-semibold text-default-900">
                      Report Ready :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ fontWeight: "600", color: "#000" }}
                    >
                      {filterReadyReport.length}
                    </span>
                  </div>
                  <span
                    className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src="/assets/checklist.jpg"
                      alt
                      style={{
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                      }}
                    />
                  </span>
                </div>
                <Link
                  to="/admin/readyreport"
                  className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                >
                  View data
                  <i
                    data-lucide="move-right"
                    className="h-4 w-4 inline align-middle ms-1"
                  />
                </Link>
              </div>
            </div>
            {loginData?.role !== "businessAssociate" ? (
              <div
                className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
                style={{ backgroundColor: "#0033cc" }}
              >
                <div className="p-5">
                  <div className="flex flex-wrap items-center justify-between">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: '100%'
                      }}
                    >
                      <div className="">
                        <span
                          className="text-base font-semibold text-default-900"
                          style={{ color: "#fff" }}
                        >
                          Gross Profit :
                        </span>
                        <span
                          className="inline-block ps-3"
                          style={{ fontWeight: "600", color: "#fff" }}
                        >
                          {totalrevenue}
                        </span>
                      </div>
                      <div>
                        <span
                          className="text-base font-semibold text-default-900"
                          style={{ color: "#fff" }}
                        >
                          Expenses :
                        </span>
                        <span
                          className="inline-block ps-3"
                          style={{ fontWeight: "600", color: "#fff" }}
                        >
                          {totalCost}
                        </span>
                      </div>
                      <div style={{ borderTop: "1px solid #fff" }}>
                        <span
                          className="text-base font-semibold text-default-900"
                          style={{ color: "#fff" }}
                        >
                          Profit :
                        </span>
                        <span
                          className="inline-block ps-3"
                          style={{ fontWeight: "600", color: "#fff" }}
                        >
                          {totalrevenue - totalCost}
                        </span>
                      </div>
                    </div>
                    <span
                      className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        src="/assets/money.png"
                        alt
                        style={{
                          height: "100%",
                          width: "100%",
                          objectPosition: "center",
                        }}
                      />
                    </span>
                  </div>
                  <Link
                    to="/admin/clientrevenue"
                    className="relative inline-block tracking-wide align-middle text-sm text-primary duration-500 border-b border-primary mt-2"
                    style={{ color: "#fff" }}
                  >
                    View data
                    <i
                      data-lucide="move-right"
                      className="h-4 w-4 inline align-middle ms-1"
                    />
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;
