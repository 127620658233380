import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";
import { CSVLink } from "react-csv";
import { get } from "jodit/esm/core/helpers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, TextField } from "@mui/material";
import { clientActions } from "../../redux/ClientAuthentication/ClientSlice";

const ClientRevenue = () => {
  const { loginData } = useSelector((store) => store.auth);
  const { readyReport, assignedAgent } = useSelector((store) => store.cases);
  const { totalClients } = useSelector((store) => store.client);

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredCase, setFilteredCase] = useState([]);
  const [loadData, setLoadData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    if (loginData?.role === "businessAssociate") {
      const data = readyReport.filter((indCase) =>
        loginData.permittedState.includes(indCase.state)
      );
      setFilteredData(data);
      setFilteredCase(data);
    } else {
      setFilteredData(readyReport);
      setFilteredCase(readyReport);
    }
  }, [readyReport]);

  useEffect(() => {
    let finalexport = [];
    let formdata = {};
    for (let index = 0; index < filteredData.length; index++) {
      const currentDate = new Date(filteredData[index].submittedAt);
      const createdDate = new Date(filteredData[index].createdAt);

      const element = filteredData[index];
      formdata = {
        No: index + 1,
        "Case Id": index + 1,
        Name: element.name,
        Mobile: element.name,
        Email: element.email,
        "Client Name": element.requesterName,
        "Client Branch": element.requesterBranch,
        "Client Phone": element.requesterPhone,
        "Revenue": element.revenue,
        "Cost": element.cost,
        "Loan No": element.loanAgreementNo,
        "Case Type": element.caseType,
        "Vehicle Type": element.assetType,
        "Vehicle Make": element.brand,
        "Vehicle Model": element.model,
        "Vehicle Variant": element.variant,
        "Reg No.": element.regdNo,
        "Chasis No.": element.chasisNo,
        "Engine No.": element.engNo,
        "Meter Reading": element.meterReading,
        "Date Of Reg": element.regdDate,
        "Fuel Type": element.fueltype,
        "Vehicle Color": element.vehicleColor,
        "Number Plate": element.numberPlate,
        "Insurance Expiry": element.otherDetails.insuranceExpiry,
        "Vehicle Exshowroom Price": element.marketPrice,
        "Estimated Market Price": element.valuation,
        "Vehicle Location": element.vehicleLocation,
        "Vehicle City": element.city,
        "Vehicle State": element.state,
        "Case Created Date": createdDate.toLocaleDateString(),
        "Case Created Time": createdDate.toLocaleTimeString(),
        "Field Service By": element.fieldServiceBy,
        "Field Service Done Date": currentDate.toLocaleDateString(),
        "Field Service Done Time": currentDate.toLocaleTimeString(),
        "Field Service Remark": element.yourRemark,
        "Pdf Report Url": element.reportUrl,
        "Selfie With Vehicle": element.selfieWithVehicle,
        "Video Url": element.videos,
      };
      if (element.fieldServiceBy === "executive") {
        formdata.fieldServiceByName = element.executive.name;
      } else if (element.fieldServiceBy === "agent") {
        formdata.fieldServiceByName = element.agent.name;
      } else if (element.fieldServiceBy === "client") {
        formdata.fieldServiceByName = element.client.name;
      } else if (element.fieldServiceBy === "customer") {
        formdata.fieldServiceByName = element.customer.name;
      }
      finalexport = [...finalexport, formdata];
    }
    setLoadData([...finalexport]);
  }, [filteredData]);

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);
    filterData(date, endDate);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);
    filterData(startDate, date);
  };

  const filterData = (start, end) => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      // Filter the cases based on date range
      const getAllData = filteredData;
      const filtered = getAllData.filter((item) => {
        const itemDate = new Date(item.submittedAt);
        return itemDate >= startDate && itemDate <= endDate;
      });

      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    if (clientName) {
      const dataByRequesterName = filteredCase.filter(
        (indreport) => indreport.requesterName === clientName
      );
      setFilteredData(dataByRequesterName);
    }
  }, [clientName, filteredData]);

  useEffect(() => {
    const getRevenue = async () => {
      const totalRevenue = filteredData
        .map((indData) => parseFloat(indData.revenue)) // Convert each indData.charges to a number
        .filter((charge) => !isNaN(charge)) // Filter out NaN values
        .reduce((accumulator, charge) => accumulator + charge, 0); // Sum up all valid charges

      await dispatch(clientActions.setRevenue(totalRevenue));
      setTotalRevenue(totalRevenue);
    };
    getRevenue();
    const getCost = async () => {
      const totalCost = filteredData
        .map((indData) => parseFloat(indData.cost)) // Convert each indData.charges to a number
        .filter((charge) => !isNaN(charge)) // Filter out NaN values
        .reduce((accumulator, charge) => accumulator + charge, 0); // Sum up all valid charges

      await dispatch(clientActions.setCost(totalCost));
      setTotalCost(totalCost);
    };
    getCost(); 
  }, [filteredData]);
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full" style={{position:'relative'}}>
        <div className="p-2 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <FormControl style={{ width: "210px" }}>
              <InputLabel id="demo-simple-Asset Type" className="inputcenter">
                Client List
              </InputLabel>
              <Select
                labelId="demo-simple-Asset Type"
                id="demo-simple-select"
                value={clientName}
                label="Asset Type"
                onChange={(e) => setClientName(e.target.value)}
                style={{ height: "35px", width: "200px" }}
                inputProps={{ style: { textAlign: "center" } }}
              >
                {totalClients.map((test, index) => (
                  <MenuItem value={test.name} key={index}>
                    {test.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* Start Date Input */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <label htmlFor="startDateInput" style={{ width: "50px" }}>
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDateInput"
                  value={startDate}
                  onChange={handleStartDateChange}
                  style={{ borderColor: "#4D44B5", borderRadius: "5px" }}
                />
              </div>
              {/* End Date Input */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <label htmlFor="endDateInput" style={{ width: "50px" }}>
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDateInput"
                  value={endDate}
                  onChange={handleEndDateChange}
                  style={{ borderColor: "#4D44B5", borderRadius: "5px" }}
                />
              </div>
            </div>
            <div className="p-1">
              <CSVLink
                data={loadData}
                filename={"instavaluer-report-data.csv"}
                onClick={() => {}}
              >
                <button
                  className="mt-0 btn btn-primary download-responses-button"
                  style={{
                    background: "#3270fc",
                    border: "1px solid #3270fc",
                    padding: "12px",
                    color: "#fff",
                    borderRadius: "3px",
                  }}
                >
                  Download Report
                </button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#ffcc00",
            border: "1px solid #ffcc00",
            padding: "10px 12px",
            borderRadius: "3px",
            color: "#000",
            width: "190px",
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            position:'fixed',
            bottom:'50px',
            right:'0px',
            zIndex:'999'
          }}
        >
          <div>Total Collection:₹{totalRevenue}</div>
          <div>Total Expenses:₹{totalCost}</div>
          <div style={{borderTop:'1px solid #000'}}>Revenue:₹{totalRevenue-totalCost}</div>
        </div>
        <div className="grid grid-cols-1" style={{ marginBottom: "60px" }}>
          <div className="shadow rounded-lg bg-white dark:bg-default-50">
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Reg No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Requester Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Requester Branch
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Asset Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Expense
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Collection
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        Submit Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Hypothecation Bank
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-dashed divide-default-200">
                    {filteredData &&
                      filteredData.map((individualReport, index) => (
                        <tr key={index}>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            <b>{index + 1}</b>
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.regdNo}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.requesterName}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.requesterBranch}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.assetType}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport?.cost}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport?.revenue}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.submittedAt.slice(0, 10)}
                          </td>
                          <td
                            className="px-6 py-3 text-center whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            <h6 className="text-sm font-semibold text-default-700">
                              {individualReport.name.length > 30 ? (
                                <>{individualReport.name.slice(0, 30)}...</>
                              ) : (
                                <>{individualReport.name.slice(0, 30)}</>
                              )}
                            </h6>
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.mobile}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.email.length > 18 ? (
                              <>{individualReport.email.slice(0, 18)}...</>
                            ) : (
                              <>{individualReport.email.slice(0, 18)}</>
                            )}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            {individualReport.state}
                          </td>
                          <td
                            className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "#fff",
                            }}
                          >
                            <div className="d-flex m-auto">
                              {individualReport.hypothecationBank}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
              {/* <h6 className="text-default-600">Showing 1 to 5 of 12</h6> */}
              {/* <nav className="flex items-center gap-1">
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-left text-base" />
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border rounded-md transition-all duration-200 bg-primary text-white border-primary"
                                    to="#"
                                    aria-current="page"
                                >
                                    1
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    2
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    ...
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    12
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-right text-base" />
                                </a>
                            </nav> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientRevenue;
