import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminPost, valiadteEmail, valiadteNumber } from "../../redux/Authentication/AuthSlice";
import { createEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, createCase, selfieWithVehicle, selfieWithVehicleUpload, sendVehicleNumber, uploadAnyDamage, uploadExtraImage, uploadExtraImage1, uploadExtraImage2, uploadExtraImage3, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage } from "../../redux/Cases/CaseSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import FourWheelerEditReport4 from "../Reports/FourWheelerEditReport4";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextField } from "@mui/material";
import ImageUpload from "./UploadImage";

const AddFarmEquipmentCase = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate()
    const { totalClients } = useSelector((store) => store.client);
    const { selfieWithVehicleImage, isSelfieWithAgentLoading, chasisImage, isChasisImageLoading,
        IsformatImageLoading,
        formatImage,
        isOpenBonnetLoading,
        openBonnet,
        isRight45ImageLoading,
        right45image,
        frontimage,
        isFrontImageLoading,
        frontUnderCarImage,
        isFrontUnderCarImageLoading,
        isLeft45Loading,
        left45image,
        isLeftImageLoading,
        leftImage,
        rearImage,
        isRearImageLoading,
        rearUnderPartImage,
        isRearUnderPartLoading,
        openDickeyImage,
        isOpenDickeyImageLoading,
        rightImage,
        isRightImageLoading,
        tyreImage,
        isTyreImageLoading,
        glassOutsideImage,
        isGlassOutsideImageLoading,
        glassInsideImage,
        isGlassInsideImageLoading,
        odometerImage,
        isOdometerImageLoading,
        rpmImage,
        isRpmImageLoading,
        anyDamage,
        isAnyDamageLoading,
        extraImage,
        isExtraImageLoading,
        extraImage1,
        isExtraImage1Loading,
        extraImage2,
        isExtraImage2Loading,
        extraImage3,
        isExtraImage3Loading,
        signatureImage,
        isSignatureImageLoading,
        rcFrontImage,
        isRcFrontImageLoading,
        rcBackImage,
        isRcBackImageLoading
    } = useSelector((store) => store.cases)
    const [assetType, setAssetType] = useState("");
    const [assetTypeError, setAssetTypeError] = useState("");
    const [caseType, setCaseType] = useState("");
    const [caseTypeError, setCaseTypeError] = useState("");
    const [valuationPurpose, setValuationPurpose] = useState('Assesment of fair market value');
    const [valuationPurposeError, setValuationPurposeError] = useState('');
    const [requesterID, setRequesterID] = useState("");
    const [requesterName, setRequesterName] = useState("");
    const [requesterNameError, setRequesterNameError] = useState("");
    const [requesterBranch, setRequesterBranch] = useState("");
    const [requesterBranchError, setRequesterBranchError] = useState("");
    const [requesterPhone, setRequesterPhone] = useState("");
    const [requesterPhoneError, setRequesterPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [Cnfemail, setCnfEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCnf, setMobileCnf] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [address, setAddress] = useState("")
    const [addressError, setAddressError] = useState("")
    const [state, setState] = useState("")
    const [stateError, setStateError] = useState("")
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState("")
    const [cityError, setCityError] = useState("")
    const [productType, setProductType] = useState("")
    const [productTypeError, setProductTypeError] = useState("")
    const [productBrand, setProductBrand] = useState("")
    const [productBrandError, setProductBrandError] = useState("")
    const [vehicleLocation, setVehicleLocation] = useState('')
    const [vehicleLocationError, setVehicleLocationError] = useState('')
    const [model, setModel] = useState("")
    const [ModelError, setModelError] = useState("")
    const [variant, setVariant] = useState("")
    const [variantError, setVariantError] = useState("")
    const [loanNo, setLoanNo] = useState("")
    const [loanNoError, setLoanNoError] = useState("")
    const [docVerified, setDocVerified] = useState('');
    const [regdOwner, setRegdOwner] = useState("")
    const [regdOwnerError, setRegdOwnerError] = useState("")
    const [regdDate, setRegdDate] = useState("")
    const [regdDateError, setRegdDateError] = useState("")
    const [mfgDate, setMfgdate] = useState('');
    const [numberPlateType, setNumberPlateType] = useState('');
    const [regdNo, setRegdNo] = useState("")
    const [regdNoError, setRegdNoError] = useState("")
    const [vehicleData, setVehicleData] = useState({});
    const [dataFetched, setDataFetched] = useState(false)
    const [engNo, setEngNo] = useState("")
    const [engNoError, setEngNoError] = useState("")
    const [chasisNo, setChasisNo] = useState("")
    const [chasisNoError, setChasisNoError] = useState("")
    const [bodyType, setBodyType] = useState("")
    const [bodyTypeError, setBodyTypeError] = useState("")
    const [meterReading, setMeterReading] = useState('');
    const [fueltype, setFueltype] = useState("")
    const [fueltypeError, setFueltypeError] = useState("")
    const [vehicleColor, setVehicleColor] = useState("")
    const [vehicleColorError, setVehicleColorError] = useState("")
    const [ownerSerialNo, setOwnerSerialNo] = useState("")
    const [ownerSerialNoError, setOwnerSerialNoError] = useState("")
    const [hypothecationBank, setHypothecationBank] = useState("")
    const [hypothecationBankError, setHypothecationBankError] = useState("")
    const [insuranceCompany, setInsuranceCompany] = useState('');
    const [insuranceStatus, setInsuranceStatus] = useState('');
    const [cubicCapacity, setCubicCapacity] = useState("")
    const [cubicCapacityError, setCubicCapacityError] = useState("")
    const [seatingCapacity, setSeatingCapacity] = useState("")
    const [eatingCapacityError, setSeatingCapacityError] = useState("")
    const [vehicleWeight, setVehicleWeight] = useState("")
    const [vehicleWeightError, setVehicleWeightError] = useState("")

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    const [videoRecord, setVideoRecord] = useState('')

    const [role, setRole] = useState("")
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [tractorApplication, setTractorApplication] = useState("");
    const [powerHp, setPowerHp] = useState("");
    const [unladenWeight, setUnladenWeight] = useState("");
    const [fitnessExpiryDate, setFitnessExpiryDate] = useState("");
    const [fitnessExpiryDateError, setFitnessExpiryDateError] = useState("");
    const [roadtaxValidityDate, setRoadtaxValidityDate] = useState("");
    const [roadtaxValidityDateError, setRoadtaxValidityDateError] = useState("");
    const [engineStatus, setEngineStatus] = useState("");
    const [engineStatusError, setEngineStatusError] = useState("");
    const [engineCondition, setEngineCondition] = useState("");
    const [engineConditionError, setEngineConditionError] = useState("");
    const [transmission, setTransmission] = useState("");
    const [transmissionError, setTransmissionError] = useState("");
    const [transmissionCondition, setTransmissionCondition] = useState("");
    const [transmissionConditionError, setTransmissionConditionError] =
        useState("");
    const [steering, setSteering] = useState("");
    const [steeringError, setSteeringError] = useState("");
    const [brakes, setBrakes] = useState("");
    const [brakesError, setBrakesError] = useState("");
    const [seatCondition, setSeatCondition] = useState("");
    const [seatConditionError, setSeatConditionError] = useState("");
    const [bodyCondition, setBodyCondition] = useState("");
    const [bodyConditionError, setBodyConditionError] = useState("");
    const [wheelsType, setWheelType] = useState("");
    const [wheelsTypeError, setWheelTypeError] = useState("");
    const [undercarriageCondition, setUndercarriageCondition] = useState("");
    const [undercarriageConditionError, setUndercarriageConditionError] =
        useState("");
    const [paintCondition, setPaintCondition] = useState("");
    const [paintConditionError, setPaintConditionError] = useState("");
    const [lhFront, setLhFront] = useState("");
    const [lhFrontError, setLhFrontError] = useState("");
    const [rhFront, setRhFront] = useState("");
    const [rhFrontError, setRhFrontError] = useState("");
    const [lhRear, setLhRear] = useState("");
    const [lhRearError, setLhRearError] = useState("");
    const [rhRear, setRhRear] = useState("");
    const [rhRearError, setRhRearError] = useState("");
    const [spare, setSpare] = useState("");
    const [spareError, setSpareError] = useState("");
    const [totalTyres, setTotalTyres] = useState("");
    const [totalTyresSpareError, setTotalTyresSpareError] = useState("");
    const [availableTyre, setAvailableTyre] = useState("");
    const [availableTyreError, setAvailableTyreError] = useState("");
    const [missingTyre, setMissingTyre] = useState("");
    const [missingTyreError, setMissingTyreError] = useState("");
    const [spareTyre, setSpareTyre] = useState("");
    const [spareTyreError, setSpareTyreError] = useState("");
    const [battery, setBattery] = useState("");
    const [batteryError, setBatteryError] = useState("");
    const [batteryNumber, setBatteryNumber] = useState("");
    const [batteryNumberError, setBatteryNumberError] = useState("");
    const [starterMotor, setStarterMotor] = useState("");
    const [starterMotorError, setStarterMotorError] = useState("");
    const [instrumentPanel, setInstrumentPanel] = useState("");
    const [instrumentPanelError, setInstrumentPanelError] = useState("");
    const [headLamp, setHeadLamp] = useState("");
    const [headLampError, setHeadLampError] = useState("");
    const [tailLamp, setTailLamp] = useState("");
    const [tailLampError, setTailLampError] = useState("");
    const [workLampsNumbers, setWorkLampsNumbers] = useState("");
    const [workLampsNumbersError, setWorkLampsNumbersError] = useState("");
    const [workLampsCondition, setWorkLampsCondition] = useState("");
    const [workLampsConditionError, setWorkLampsConditionError] = useState("");
    const [electricalsCondition, setElectricalsCondition] = useState("");
    const [electricalsConditionError, setElectricalsConditionError] =
        useState("");
    const [odometerType, setOdometerType] = useState("");
    const [odometerTypeError, setOdometerTypeError] = useState("");
    const [anyAttachmentImplement, setAnyAttachmentImplement] = useState("");
    const [anyAttachmentImplementError, setAnyAttachmentImplementError] =
        useState("");
    const [nameOfAttachmentImplement, setNameOfAttachmentImplement] =
        useState("");
    const [nameOfAttachmentImplementError, setNameOfAttachmentImplementError] =
        useState("");
    const [others, setOthers] = useState("");
    const [othersError, setOthersError] = useState("");
    const [airCompressor, setAirCompressor] = useState("");
    const [airCompressorError, setAirCompressorError] = useState("");
    const [toolKit, setToolKit] = useState("");
    const [toolKitError, setToolKitError] = useState("");
    const [lift, setLift] = useState("");
    const [liftError, setLiftError] = useState("");
    const [tipper, setTipperStatus] = useState("");
    const [tipperError, setTipperStatusError] = useState("");
    const [hitch, setHitch] = useState("");
    const [hitchError, setHitchError] = useState("");
    const [powerSteering, setPowerSteering] = useState("");
    const [powerSteeringError, setPowerSteeringError] = useState("");
    const [whetherAccidental, setWhetherAccidental] = useState("");
    const [whetherAccidentalError, setWhetherAccidentalError] = useState("");
    const [levelOfMaintenance, setLevelOfMaintenance] = useState("");
    const [levelOfMaintenanceError, setLevelOfMaintenanceError] = useState("");
    const [otherRepairsIfAny, setOtherRepairsIfAny] = useState("");
    const [otherRepairsIfAnyError, setOtherRepairsIfAnyError] = useState("");

    const [yourRemark, setYourRemark] = useState("");
    const [exShowRoomPrice, setExShowRoomPrice] = useState("");
    const [exShowRoomPriceError, setExShowRoomPriceError] = useState("");
    const [estimatedPrice, setEstimatedPrice] = useState("");
    const [estimatedPriceError, setEstimatedPriceError] = useState("");

    const [buttonLoading, setButtonLoading] = useState(false)

    const [clientList, setClientList] = useState([])
    useEffect(() => {
        const client = totalClients.map((indClient, index) => ({
            id: index,
            _id: indClient._id,
            name: indClient.name,
            phone: indClient.phone,
            organisation: indClient.organisation
        }));
        setClientList(client);
    }, [totalClients])
    const handleRequester = (e) => {
        const { organisation, phone, _id } = e.target.value
        setRequesterID(_id)
        // setRequesterBranch(organisation)
        setRequesterPhone(phone)
    }
    const selectCaseType = [
        {
            id: 1,
            name: "Revaluation",
        },
        {
            id: 2,
            name: "Repossession",
        },
        {
            id: 3,
            name: "Retail",
        },
        {
            id: 4,
            name: "Insurance Idv",
        },
        {
            id: 5,
            name: "Repurchase",
        },
    ];

    const selectRemarkType = [
        {
            id: 1,
            name: "Poor",
        },
        {
            id: 2,
            name: "Average",
        },
        {
            id: 3,
            name: "Satisfactory",
        },
        {
            id: 4,
            name: "Good",
        },
        {
            id: 5,
            name: "Excellent",
        },
    ];

    const selectRemarkChange = (e) => {
        const { value } = e.target;
        setYourRemark(value);
    };
    const selectCaseChange = (e) => {
        const { value } = e.target;
        setCaseType(value);
    };
    const selectAssetType = [
        {
            id: 1,
            name: "Two Wheeler",
        },
        {
            id: 2,
            name: "Four Wheeler",
        },
        {
            id: 3,
            name: "Commercial",
        },
        {
            id: 4,
            name: "Construction Equipment",
        },
        {
            id: 5,
            name: "Three Wheeler",
        },
        {
            id: 6,
            name: "Farm Equipment",
        },
    ];
    const selectAssetChange = (e) => {
        const { value } = e.target;
        setAssetType(value);
    };

    const handleRequesterPhn = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setRequesterPhone(value)
    }

    const stateList = [
        { id: 1, name: "Andhra Pradesh" },
        { id: 2, name: "Arunachal Pradesh" },
        { id: 3, name: "Assam" },
        { id: 4, name: "Bihar" },
        { id: 5, name: "Chhattisgarh" },
        { id: 6, name: "Goa" },
        { id: 7, name: "Gujarat" },
        { id: 8, name: "Haryana" },
        { id: 9, name: "Himachal Pradesh" },
        { id: 10, name: "Jharkhand" },
        { id: 11, name: "Karnataka" },
        { id: 12, name: "Kerala" },
        { id: 13, name: "Madhya Pradesh" },
        { id: 14, name: "Maharashtra" },
        { id: 15, name: "Manipur" },
        { id: 16, name: "Meghalaya" },
        { id: 17, name: "Mizoram" },
        { id: 18, name: "Nagaland" },
        { id: 19, name: "Odisha" },
        { id: 20, name: "Punjab" },
        { id: 21, name: "Rajasthan" },
        { id: 22, name: "Sikkim" },
        { id: 23, name: "Tamil Nadu" },
        { id: 24, name: "Telangana" },
        { id: 25, name: "Tripura" },
        { id: 26, name: "Uttar Pradesh" },
        { id: 27, name: "Uttarakhand" },
        { id: 28, name: "West Bengal" },
        { id: 29, name: "Andaman and Nicobar Islands" },
        { id: 30, name: "Chandigarh" },
        { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
        { id: 32, name: "Delhi" },
        { id: 33, name: "Ladakh" },
        { id: 34, name: "Lakshadweep" },
        { id: 35, name: "Puducherry" }
    ];
    const selectStateChange = (e) => {
        const { value } = e.target;
        setState(value);
    };
    useEffect(() => {
        if (state) {
            const formData = {
                country: 'India',
                state: state.toLocaleUpperCase(),
            };
            const getCity = async () => {
                try {
                    const config = {
                        headers: { 'Content-Type': 'application/json' },
                    };
                    const url = 'https://countriesnow.space/api/v0.1/countries/state/cities';
                    const resp = await axios.post(url, formData, config);
                    if (!resp.data.error) {
                        const citiesData = resp.data.data.map((city, index) => ({
                            id: index + 1,
                            name: city.toLowerCase().replace(/\s/g, '_'),
                        }));
                        setCityList(citiesData);
                    } else {
                        console.error("Error in response:", resp.data.error); // Log the error for debugging
                    }
                } catch (error) {
                    console.error("Error:", error); // Log any other errors for debugging
                }
            };

            getCity();
        }
    }, [state]);

    const selectCityChange = (e) => {
        const { value } = e.target;
        setCity(value);
    };

    const handleRegdNo = async (e) => {
        const inputValue = e.target.value.toUpperCase();
        setRegdNo(inputValue);
        setDataFetched(false); // Reset data fetched status

        if (inputValue.length === 9 || inputValue.length === 10) {
            try {
                // let newData = await fetchVehicleData(inputValue);
                const formData = {
                    "regdNo": inputValue,
                }
                const vehicle = await dispatch(sendVehicleNumber(formData))
                setVehicleData(vehicle.payload.response.result);
                setDataFetched(true);
            } catch (error) {
                setVehicleData(null); // Reset vehicle data
            }
        } else {
            setVehicleData(null); // Reset vehicle data if input length is invalid
        }
    };

    useEffect(() => {
        // Fetch data again if required properties are null after initial fetch
        if (dataFetched && vehicleData) {
            const {
                rcFinancer,
                vehicleTaxUpto,
                permitValidUpto,
                permitType,
                vehicleSeatCapacity,
                grossVehicleWeight,
                regAuthority
            } = vehicleData;

            if (
                !rcFinancer ||
                !vehicleTaxUpto ||
                !permitValidUpto ||
                !permitType ||
                !vehicleSeatCapacity ||
                !grossVehicleWeight ||
                !regAuthority
            ) {
                // Required properties are still null, fetch again
                handleRegdNo({ target: { value: regdNo } });
            }
        }
    }, [dataFetched, vehicleData, regdNo]);

    const documents = [
        { name: 'Verified', id: 1 },
        { name: 'Not Verified', id: 2 },
    ];
    const selectDocuementVerifiedTypeChange = (e) => {
        const { value } = e.target;
        setDocVerified(value);
    };
    const number = [
        { name: 'White', id: 1 },
        { name: 'Yellow', id: 2 },
        { name: 'Blue', id: 3 },
        { name: 'Black', id: 4 },
    ];
    const selectNumberPlateTypeChange = (e) => {
        const { value } = e.target;
        setNumberPlateType(value);
    };
    const options = [
        { name: 'Yes', id: 1 },
        { name: 'No', id: 2 },
    ];
    const odometer = [
        { name: 'Digital', id: 1 },
        { name: 'Normal', id: 2 },
        { name: 'Mechanical', id: 3 },
    ];
    const application = [
        { name: 'Agriculture', id: 1 },
    ];
    const attachment = [
        { name: 'One Trolly', id: 1 },
        { name: 'Two Trolly', id: 2 },
    ];
    const Features = [
        { name: "Manual", id: 1 },
        { name: "Automatic", id: 3 },
    ];
    const condition = [
        { name: "Poor", id: 1 },
        { name: "Average", id: 2 },
        { name: "Satisfactory", id: 3 },
        { name: "Good", id: 4 },
        { name: "Excellent", id: 5 },
        { name: "NA", id: 6 },
    ];
    const vehcileBodyType = [
        { name: 'PASSENGER', id: 1 },
        { name: 'GOODS', id: 2 },
    ];
    const reqCondition = [
        { name: "Poor", id: 1 },
        { name: "Average", id: 2 },
        { name: "Satisfactory", id: 3 },
        { name: "Good", id: 4 },
        { name: "Excellent", id: 5 }
    ];
    const list = [
        { name: "10", id: 1 },
        { name: "20", id: 2 },
        { name: "30", id: 3 },
        { name: "40", id: 4 },
        { name: "50", id: 5 },
        { name: "60", id: 6 },
        { name: "70", id: 7 },
        { name: "80", id: 8 },
        { name: "90", id: 9 },
        { name: "100", id: 10 },
    ];
    const qualityList = [
        { name: "Original", id: 1 },
        { name: "Replaced", id: 2 },
    ];
    const wheelTypeList = [
        { name: "Alloy", id: 1 },
        { name: "Steel", value: 2 },
        { name: "Rim", value: 3 },
    ];
    const carSteeringTypeList = [
        { name: 'RACK AND PINION STEERING', id: 1 },
        { name: 'POWER STEERING', id: 2 },
        { name: 'RECIRCULATING BALL STEERING', id: 3 },
    ];
    const selectInsuranceStatusChange = (e) => {
        const { value } = e.target;
        setInsuranceStatus(value);
    };

    const handleFileChange = (event) => {
        setSelectedVideo(event.target.files[0])
    };

    const handleUpload = async () => {
        setShowLoader(true);
        if (selectedVideo) {
          const formData = new FormData();
          formData.append("file", selectedVideo);
          try {
            const res = await axios.post(`${Imageurl}/upload`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            setVideoRecord(res.data.filePath);
            document.getElementById("videouploading").value = "";
            setShowLoader(false);
            alert('file uploaded')
          } catch (error) {
            console.error('Error uploading file:', error);
          }
        } else {
          console.log("No video selected.");
        }
      };
    
      const handleSelfieWithVehicleChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        await dispatch(selfieWithVehicleUpload(formData));
      };
      const handleChasisChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        dispatch(UploadChasisImage(formData));
      };
      const handleFormatChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        dispatch(uploadformatImage(formData));
      };
      const handleOpenBonnetChange = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(UploadOpenBonnet(formData));
      };
      const handleright45Change = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        dispatch(UploadRight45Image(formData));
      };
      const frontImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(UploadFrontImage(formData));
      };
      const frontUnderCarImg = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadFrontUnderCar(formData));
      };
      const handlesetLeft45Image = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadLeft45(formData));
      };
      const handlesetLeftImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadLeftImage(formData));
      };
      const handlesetRearImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRearImage(formData));
      };
      const handlerearUnderPartImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(UploadRearUnderPart(formData));
      };
      const handlerearOpenDickeyImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadOpenDickey(formData));
      };
      const HandlerightImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRightImage(formData));
      };
      const handlesetTyreImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadTyreImage(formData));
      };
      const handleGlassOutsideImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadGlassOutside(formData));
      };
      const handleGlassInsideImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadGlassInsideImage(formData));
      };
      const handleOdometerImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadOdometerImage(formData));
      };
      const handleRpmImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRpmImage(formData));
      };
      const handleAnyDamage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadAnyDamage(formData));
      };
      const handleExtraImage = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage(formData));
      };
      const handleExtraImage1 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage1(formData));
      };
      const handleExtraImage2 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage2(formData));
      };
      const handleExtraImage3 = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadExtraImage3(formData));
      };
      const handleSignature = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadSignature(formData));
      };
      const handleFrontRc = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRcFront(formData));
      };
      const handleBackRc = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]); // Adjust if you want to handle multiple files
        dispatch(uploadRcBackImage(formData));
      };
    

    const typeList = [
        { id: 1, name: 'Manual' },
        { id: 2, name: 'Auto' },
    ];

    const [btnPress, setbtnPress] = useState(false);

    const selecttractorApplicationChange = (e) => {
        const { value } = e.target;
        setTractorApplication(value);
    };
    const selectEngineAvailableChange = (e) => {
        const { value } = e.target;
        setEngineStatus(value);
    };
    const selectEngineConditionChange = (e) => {
        const { value } = e.target;
        setEngineCondition(value);
    };
    const selectTransmissionChange = (e) => {
        const { value } = e.target;
        setTransmission(value);
    };
    const selectTransmissionConditionChange = (e) => {
        const { value } = e.target;
        setTransmissionCondition(value);
    };
    const selectSteeringChange = (e) => {
        const { value } = e.target;
        setSteering(value);
    };
    const selectBrakesChange = (e) => {
        const { value } = e.target;
        setBrakes(value);
    };
    const selectSeatConditionChange = (e) => {
        const { value } = e.target;
        setSeatCondition(value);
    };
    const selectBodyConditionChange = (e) => {
        const { value } = e.target;
        setBodyCondition(value);
    };
    const selectUndercarriageConditionChange = (e) => {
        const { value } = e.target;
        setUndercarriageCondition(value);
    };
    const selectPaintConditionChange = (e) => {
        const { value } = e.target;
        setPaintCondition(value);
    };
    const selectLhFrontChange = (e) => {
        const { value } = e.target;
        setLhFront(value);
    };
    const selectRhFrontChange = (e) => {
        const { value } = e.target;
        setRhFront(value);
    };
    const selectLhRearChange = (e) => {
        const { value } = e.target;
        setLhRear(value);
    };
    const selectRhRearChange = (e) => {
        const { value } = e.target;
        setRhRear(value);
    };
    const selectSpareChange = (e) => {
        const { value } = e.target;
        setSpare(value);
    };
    const selectBatteryChange = (e) => {
        const { value } = e.target;
        setBattery(value);
    };
    const selectStarterMotorChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
    };
    const selectInstrumentPanelChange = (e) => {
        const { value } = e.target;
        setInstrumentPanel(value);
    };
    const selectHeadLampChange = (e) => {
        const { value } = e.target;
        setHeadLamp(value);
    };
    const selectTailLampChange = (e) => {
        const { value } = e.target;
        setTailLamp(value);
    };
    const selectWorkLampsConditionChange = (e) => {
        const { value } = e.target;
        setWorkLampsCondition(value);
    };
    const selectElectricalsConditionChange = (e) => {
        const { value } = e.target;
        setElectricalsCondition(value);
    };
    const selectOdometerTypeChange = (e) => {
        const { value } = e.target;
        setOdometerType(value);
    };
    const selectAnyAttachmentImplementChange = (e) => {
        const { value } = e.target;
        setAnyAttachmentImplement(value);
    };
    const selectNameOfAttachmentImplementChange = (e) => {
        const { value } = e.target;
        setNameOfAttachmentImplement(value);
    };
    const selectOthersChange = (e) => {
        const { value } = e.target;
        setOthers(value);
    };
    const selectAirCompressorChange = (e) => {
        const { value } = e.target;
        setAirCompressor(value);
    };
    const selectToolKitChange = (e) => {
        const { value } = e.target;
        setToolKit(value);
    };
    const selectLiftChange = (e) => {
        const { value } = e.target;
        setLift(value);
    };
    const selectTipperStatusChange = (e) => {
        const { value } = e.target;
        setTipperStatus(value);
    };
    const selectHitchChange = (e) => {
        const { value } = e.target;
        setHitch(value);
    };
    const selectPowerSteeringChange = (e) => {
        const { value } = e.target;
        setPowerSteering(value);
    };
    const selectWhetherAccidentalChange = (e) => {
        const { value } = e.target;
        setWhetherAccidental(value);
    };
    const selectLevelOfMaintenanceChange = (e) => {
        const { value } = e.target;
        setLevelOfMaintenance(value);
    };
    const selectOtherRepairsIfAnyChange = (e) => {
        const { value } = e.target;
        setOtherRepairsIfAny(value);
    };


    useEffect(() => {
        if (btnPress === true) {
            if (name === "") {
                setNameError("please enter title");
            } else {
                setNameError("");
            }
            if (Cnfemail === "") {
                setEmailError("please enter email");
            } else {
                setEmailError("");
            }
            if (mobileCnf.length !== 10) {
                setMobileError("please enter mobile");
            } else {
                setMobileError("");
            }
        }
    }, [btnPress, name, Cnfemail, mobileCnf]);

    const setNumberChange = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobileError("");
        setMobile(value);
        if (value.length === 10) {
            // const verifyNumber = await dispatch(valiadteNumber(value));
            // if (verifyNumber.payload.success) {
            //     setMobileError("Number already exist");
            // } else {
            // }
            setMobileError("");
            setMobileCnf(value);
        } else {
            setMobileError("Please Enter Valid Number");
        }
    };

    const emailChange = async (e) => {
        const value = e;
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(value);
        setEmailError("");
        if (value.length > 0) {
            if (value.match(mailformat)) {
                // const verifyEmail = await dispatch(valiadteEmail(value));
                // if (verifyEmail.payload.success) {
                //     setEmailError("Number already exist");
                // } else {
                // }
                setEmailError("");
                setCnfEmail(value);
            } else {
                setEmailError("please enter correct Email format");
            }
        } else {
            setEmailError("please enter correct Email format");
        }
    };

    const cancelClick = async (e) => {
        e.preventDefault();
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true)
        if (name === "") {
            setNameError("please enter title");
        } else {
            setNameError("");
        }
        if (Cnfemail === "") {
            setEmailError("please enter email");
        } else {
            setEmailError("");
        }
        if (mobileCnf.length !== 10) {
            setMobileError("please enter mobile");
        } else {
            setMobileError("");
        }
        if (name !== "" && Cnfemail !== "" && mobileCnf !== "") {
            const formData = {
                assetType: assetType,
                caseType: caseType,
                valuationPurpose: valuationPurpose,
                vehicleLocation: vehicleLocation,
                requesterID: requesterID,
                requesterName: requesterName,
                requesterBranch: requesterBranch,
                requesterPhone: requesterPhone,
                name: name,
                mobile: mobileCnf,
                email: Cnfemail,
                address: address,
                state: state,
                city: city,
                regdNo: regdNo,
                productType: productType,
                brand: productBrand,
                model: model,
                variant: variant,
                loanAgreementNo: loanNo,
                documentVerification: docVerified,
                regdOwner: regdOwner,
                regdDate: regdDate,
                numberPlate: numberPlateType,
                engNo: engNo,
                chasisNo: chasisNo,
                bodyType: bodyType,
                manufacturingDate: mfgDate,
                fueltype: fueltype,
                vehicleColor: vehicleColor,
                ownerSerialNo: ownerSerialNo,
                hypothecationBank: hypothecationBank,
                cubicCapacity: cubicCapacity,
                seatingCapacity: seatingCapacity,
                vehicleWeight: vehicleWeight,
                videos: videoRecord,
                images: {
                    selfieWithVehicle: selfieWithVehicleImage,
                    chasisImage: chasisImage,
                    format: formatImage,
                    openBonnet: openBonnet,
                    right45: right45image,
                    frontImage: frontimage,
                    frontUnderCar: frontUnderCarImage,
                    left45: left45image,
                    leftImage: leftImage,
                    rearImage: rearImage,
                    rearUnderPart: rearUnderPartImage,
                    openDickey: openDickeyImage,
                    rightImage: rightImage,
                    tyreImage: tyreImage,
                    glassOutside: glassOutsideImage,
                    glassInside: glassInsideImage,
                    odometerImage: odometerImage,
                    rpmImage: rpmImage,
                    anyDamage: anyDamage,
                    extraImage: extraImage,
                    extraImage1: extraImage1,
                    extraImage2: extraImage2,
                    extraImage3: extraImage3,
                },
                signNdoc: {
                    sign: signatureImage,
                    rcFront: rcFrontImage,
                    rcBack: rcBackImage,
                },
                farmEquipment: {
                    tractorApplication: tractorApplication,
                    powerHp: powerHp,
                    unladenWeight: unladenWeight,
                    fitnessExpiryDate: fitnessExpiryDate,
                    roadtaxValidityDate: roadtaxValidityDate,
                    engineCondition: engineCondition,
                    transmissionType: transmission,
                    transmissionCondition: transmissionCondition,
                    steering: steering,
                    brakes: brakes,
                    seatCondition: seatCondition,
                    bodyCondition: bodyCondition,
                    wheelsType: wheelsType,
                    undercarriageCondition: undercarriageCondition,
                    paintCondition: paintCondition,
                    lhFront: lhFront,
                    rhFront: rhFront,
                    lhRear: lhRear,
                    rhRear: rhRear,
                    spare: spare,
                    totalTyre: totalTyres,
                    availableTyre: availableTyre,
                    missingTyre: missingTyre,
                    spareTyre: spareTyre,
                    battery: battery,
                    batteryNo: batteryNumber,
                    starterMotor: starterMotor,
                    instrumentPanel: instrumentPanel,
                    headLamp: headLamp,
                    tailLamp: tailLamp,
                    workLampNo: workLampsNumbers,
                    workLampCondition: workLampsCondition,
                    electricalCondition: electricalsCondition,
                    odometerType: odometerType,
                    attachment: anyAttachmentImplement,
                    attachmentName: nameOfAttachmentImplement,
                    otherAttachment: others,
                    airCompressor: airCompressor,
                    ToolKit: toolKit,
                    lift: lift,
                    tipper: tipper,
                    hitch: hitch,
                    powerSteering: powerSteering,
                    accidental: whetherAccidental,
                    maintenanceLevel: levelOfMaintenance,
                    otherRepair: otherRepairsIfAny
                },
                yourRemark: yourRemark,
                valuation: Number(estimatedPrice),
                marketPrice: Number(exShowRoomPrice),
                status: 4,
                statusText: "Case Submitted",
            };
            const newcase = await dispatch(createCase(formData))
            if (newcase.payload.success) {
                alert("Case created successfully");
                dispatch(CaseActions.afterPost())
                setEmail("");
                setCnfEmail("");
                setMobile("");
                setMobileCnf("");
                setName("");
                setRole("")
                setAddress("")
                setState("")
                setCity("")
                setProductType("")
                setProductBrand("")
                setModel("")
                setVariant("")
                setLoanNo("")
                setDocVerified("")
                setRegdOwner("")
                setRegdDate("")
                setMfgdate("")
                setNumberPlateType("")
                setRegdNo("")
                setEngNo("")
                setChasisNo("")
                setBodyType("")
                setFueltype("")
                setVehicleColor("")
                setOwnerSerialNo("")
                setHypothecationBank("")
                setCubicCapacity("")
                setSeatingCapacity("")
                setVehicleWeight("")
                setButtonLoading(false)
                navigate('/admin/submittedcases')
                // setAccidental("")
            }
        } else {
            setbtnPress(true);
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                            style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                                Add Farm Equipment Case Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Case Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-select-label">Case Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={caseType}
                                            label="Case Type"
                                            onChange={(e) => selectCaseChange(e)}
                                        >
                                            {selectCaseType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fAssetType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Asset Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Asset-Type">Asset Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Asset-Type"
                                            id="demo-simple-select"
                                            value={assetType}
                                            label="Asset Type"
                                            onChange={(e) => selectAssetChange(e)}
                                        >
                                            {selectAssetType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fValuationPurpose"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Purpose Of Valuation *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Purpose Of Valuation"
                                            variant="outlined"
                                            className={
                                                valuationPurposeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={valuationPurpose}
                                            onChange={(e) => setValuationPurpose(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Valuation Requested By *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-requesterName" className="inputcenter">Valuation Requested By</InputLabel>
                                        <Select
                                            labelId="demo-simple-requesterName"
                                            id="demo-simple-select"
                                            value={requesterName}
                                            label="requesterName"
                                            onChange={(e) => { handleRequester(e); setRequesterName(e.target.value.name) }}
                                            style={{ height: '38px' }}
                                            inputProps={{ style: { textAlign: 'center' } }}
                                        >
                                            {clientList.map((test, index) =>
                                                <MenuItem value={test} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Name"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterName}
                                            onChange={(e) => setRequesterName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Branch Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Branch Name"
                                            variant="outlined"
                                            className={
                                                requesterBranchError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterBranch}
                                            onChange={(e) => setRequesterBranch(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Phone *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Phone"
                                            variant="outlined"
                                            className={
                                                requesterPhoneError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterPhone}
                                            onChange={(e) => handleRequesterPhn(e)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Name"
                                            variant="outlined"
                                            className={
                                                nameError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer  Phone *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Phone"
                                            variant="outlined"
                                            className={
                                                mobileError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={mobile}
                                            onChange={(e) => setNumberChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Email *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Email"
                                            variant="outlined"
                                            className={
                                                emailError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={email}
                                            onChange={(e) => emailChange(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="Address"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Address *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Address"
                                            variant="outlined"
                                            className={
                                                addressError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fState"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Select State *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-State-Type">State Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-State-Type"
                                            id="demo-simple-select"
                                            value={state}
                                            label="State"
                                            onChange={(e) => selectStateChange(e)}
                                        >
                                            {stateList.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fCity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Select City *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-City-Type">City Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-City-Type"
                                            id="demo-simple-select"
                                            value={city}
                                            label="City"
                                            onChange={(e) => selectCityChange(e)}

                                        >
                                            {cityList.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RegdNo *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd No"
                                            variant="outlined"
                                            className={
                                                regdNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={regdNo}
                                            onChange={(e) => setRegdNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehcileLocation"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Location *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                vehicleLocationError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleLocation}
                                            onChange={(e) => setVehicleLocation(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="productType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle brand *
                                    </label>
                                    <TextField
                                        label="Vehicle Brand"
                                        variant="outlined"
                                        className={
                                            productBrandError !== ""
                                                ? "textfield error_class"
                                                : "textfield"
                                        }
                                        style={{ marginTop: '5px' }}
                                        type="text"
                                        id="text"
                                        value={productBrand}
                                        onChange={(e) => setProductBrand(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="model"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle model *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                ModelError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={model}
                                            onChange={(e) => setModel(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="variant"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle variant *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle variant"
                                            variant="outlined"
                                            className={
                                                variantError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={variant}
                                            onChange={(e) => setVariant(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="loanNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Loan Agreement No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Loan Agreement No"
                                            variant="outlined"
                                            className={
                                                loanNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={loanNo}
                                            onChange={(e) => setLoanNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDocVerified"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Document Verified *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Document-Verified">Doc Verified</InputLabel>
                                        <Select
                                            labelId="demo-simple-Document-Verified"
                                            id="demo-simple-select"
                                            value={docVerified}
                                            label="Doc Verified"
                                            onChange={(e) => selectDocuementVerifiedTypeChange(e)}

                                        >
                                            {documents.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Regd Owner *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd Owner"
                                            variant="outlined"
                                            className={
                                                regdOwnerError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={regdOwner}
                                            onChange={(e) => setRegdOwner(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Regd Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            regdDateError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="regdDate"
                                        placeholder="Enter product regd date"
                                        value={regdDate}
                                        onChange={(e) => setRegdDate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fManufacturingDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Manufacturing Date <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fManufacturingDate"
                                        placeholder="Enter Manufacturing Date"
                                        value={mfgDate}
                                        onChange={(e) => setMfgdate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fNumberPlate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Number Plate Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Number-Plate-Type">Number Plate Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Number-Plate-Type"
                                            id="demo-simple-select"
                                            value={numberPlateType}
                                            label="Number Plate Type"
                                            onChange={(e) => selectNumberPlateTypeChange(e)}

                                        >
                                            {number.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="engNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Engine No"
                                            variant="outlined"
                                            className={
                                                engNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={engNo}
                                            onChange={(e) => setEngNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="chasisNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Chasis No"
                                            variant="outlined"
                                            className={
                                                chasisNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={chasisNo}
                                            onChange={(e) => setChasisNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="bodyType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Type *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Body Type"
                                            variant="outlined"
                                            className={
                                                bodyTypeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={bodyType}
                                            onChange={(e) => setBodyType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fMeterReading"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Meter Reading *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Meter Reading"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={meterReading}
                                            onChange={(e) => setMeterReading(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fueltype"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fuel Type*
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Fuel Type"
                                            variant="outlined"
                                            className={
                                                fueltypeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={fueltype}
                                            onChange={(e) => setFueltype(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fueltype"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Color *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Color"
                                            variant="outlined"
                                            className={
                                                vehicleColorError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleColor}
                                            onChange={(e) => setVehicleColor(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehicleWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Gross Weight Of Vehicle *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Gross Weight Of Vehicle"
                                            variant="outlined"
                                            className={
                                                vehicleWeightError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleWeight}
                                            onChange={(e) => setVehicleWeight(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="ownerSerialNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Owner Serial No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Owner Serial No"
                                            variant="outlined"
                                            className={
                                                ownerSerialNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={ownerSerialNo}
                                            onChange={(e) => setOwnerSerialNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="ownerSerialNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Hypothecation Bank Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank"
                                            variant="outlined"
                                            className={
                                                hypothecationBankError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={hypothecationBank}
                                            onChange={(e) => setHypothecationBank(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceCompany"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Company Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={insuranceCompany}
                                            onChange={(e) => setInsuranceCompany(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Expired *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Insurance-Expired">Insurance Expired</InputLabel>
                                        <Select
                                            labelId="demo-simple-Insurance-Expired"
                                            id="demo-simple-select"
                                            value={insuranceStatus}
                                            label="Insurance Status"
                                            onChange={(e) => selectInsuranceStatusChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="cubicCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Cubic  Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Cubic Capacity"
                                            variant="outlined"
                                            className={
                                                cubicCapacityError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={cubicCapacity}
                                            onChange={(e) => setCubicCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="seatingCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seating Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Seating Capacity"
                                            variant="outlined"
                                            className={
                                                eatingCapacityError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={seatingCapacity}
                                            onChange={(e) => setSeatingCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Video *
                                    </label>
                                    {
                                        showLoader === true ? (<>
                                            <div style={{ height: '250px', width: '50%', backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        </>) : (<>
                                            <iframe
                                                title="Video Player"
                                                width="50%" // Set width to 100% to fill the container
                                                height="250px" // Set height to auto to maintain aspect ratio
                                                src={videoRecord}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        </>)
                                    }
                                    <input type="file" id='videouploading' onChange={handleFileChange} accept="video/*" />
                                    {
                                        selectedVideo ? (
                                            <div>
                                                <button onClick={handleUpload} style={{ backgroundColor: '#ff6600', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>) : (
                                            <div>
                                                <button style={{ backgroundColor: '#ff6600', opacity: '.5', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>)
                                    }
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <ImageUpload />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehicleWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Selfie With Vehicle *
                                    </label>
                                    <input type="file" onChange={handleSelfieWithVehicleChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isSelfieWithAgentLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={selfieWithVehicleImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="Chasis Image"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis Image *
                                    </label>
                                    <input type="file" onChange={handleChasisChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isChasisImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={chasisImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="formatfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Product Format Image *
                                    </label>
                                    <input type="file" onChange={handleFormatChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {IsformatImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={formatImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="openBonnetfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Bonnet Image *
                                    </label>
                                    <input type="file" onChange={handleOpenBonnetChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOpenBonnetLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={openBonnet} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="openBonnetfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right 45* Image *
                                    </label>
                                    <input type="file" onChange={handleright45Change}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRight45ImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={right45image} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Image *
                                    </label>
                                    <input type="file" onChange={frontImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isFrontImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={frontimage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontUnderCar"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Under Car Image *
                                    </label>
                                    <input type="file" onChange={frontUnderCarImg}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isFrontUnderCarImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={frontUnderCarImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="left45file"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left 45* Image *
                                    </label>
                                    <input type="file" onChange={handlesetLeft45Image}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isLeft45Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={left45image} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="leftfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Left Image *
                                    </label>
                                    <input type="file" onChange={handlesetLeftImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isLeftImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={leftImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Rear Image *
                                    </label>
                                    <input type="file" onChange={handlesetRearImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRearImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rearImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearunderpartfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Rear Under Part Image *
                                    </label>
                                    <input type="file" onChange={handlerearUnderPartImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRearUnderPartLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rearUnderPartImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearunderpartfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Dickey Image *
                                    </label>
                                    <input type="file" onChange={handlerearOpenDickeyImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOpenDickeyImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={openDickeyImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="handlerightImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Handle Right Image *
                                    </label>
                                    <input type="file" onChange={HandlerightImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRightImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rightImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="tyreImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tyre Image *
                                    </label>
                                    <input type="file" onChange={handlesetTyreImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isTyreImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={tyreImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="glassOutside"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Glass Outside Image*
                                    </label>
                                    <input type="file" onChange={handleGlassOutsideImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isGlassOutsideImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={glassOutsideImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="glassOutside"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Glass Inside Image*
                                    </label>
                                    <input type="file" onChange={handleGlassInsideImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isGlassInsideImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={glassInsideImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="odometer"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Image*
                                    </label>
                                    <input type="file" onChange={handleOdometerImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOdometerImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={odometerImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rpmFile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RPM Image*
                                    </label>
                                    <input type="file" onChange={handleRpmImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRpmImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rpmImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Any Damage *
                                    </label>
                                    <input type="file" onChange={handleAnyDamage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isAnyDamageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={anyDamage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Damage *
                                    </label>
                                    <input type="file" onChange={handleExtraImage}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 1 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage1}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage1Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage1} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 2 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage2}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage2Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage2} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 3 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage3}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage3Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage3} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="signature"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Your Signature*
                                    </label>

                                    <input type="file" onChange={handleSignature}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isSignatureImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={signatureImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontRc"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Frontside of your RC*
                                    </label>
                                    <input type="file" onChange={handleFrontRc}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRcFrontImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rcFrontImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="backRc"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Frontside of your RC*
                                    </label>
                                    <input type="file" onChange={handleBackRc}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRcBackImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rcBackImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTractorApplication"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tractor Application *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Tractor-Application">Tractor Application</InputLabel>
                                        <Select
                                            labelId="demo-simple-Tractor-Application"
                                            id="demo-simple-select"
                                            value={tractorApplication}
                                            label="TractorApplication"
                                            onChange={(e) => selecttractorApplicationChange(e)}

                                        >
                                            {application.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fPowerHp"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Power Hp *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Power Hp"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={powerHp}
                                            onChange={(e) => setPowerHp(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fUnladenWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Unladen Weight *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Unladen Weight"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={unladenWeight}
                                            onChange={(e) => setUnladenWeight(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFitnessExpiryDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fitness Expiry Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fFitnessExpiryDate"
                                        placeholder="Enter Fitness Expiry Date"
                                        value={fitnessExpiryDate}
                                        onChange={(e) => setFitnessExpiryDate(e.target.value)}
                                    />

                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRoadTaxValidity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Road Tax Validity Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fRoadTaxValidity"
                                        placeholder="Enter Road Tax Validity"
                                        value={roadtaxValidityDate}
                                        onChange={(e) => setRoadtaxValidityDate(e.target.value)}
                                    />

                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEngineAvailable"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Available *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-engineStatus">Engine Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-engineStatus"
                                            id="demo-simple-select"
                                            value={engineStatus}
                                            label="engineStatus"
                                            onChange={(e) => selectEngineAvailableChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="engineCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-engineCondition">Engine Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-engineCondition"
                                            id="demo-simple-select"
                                            value={engineCondition}
                                            label="engineCondition"
                                            onChange={(e) => selectEngineConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="transmission"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Transmission Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-transmission">Transmission Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-transmission"
                                            id="demo-simple-select"
                                            value={transmission}
                                            label="transmission"
                                            onChange={(e) => selectTransmissionChange(e)}
                                        >
                                            {Features.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="transmissionCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Transmission Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-transmissionCondition">Transmission Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-transmissionCondition"
                                            id="demo-simple-select"
                                            value={transmissionCondition}
                                            label="transmissionCondition"
                                            onChange={(e) => selectTransmissionConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="steering"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Steering *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-steering">Steering</InputLabel>
                                        <Select
                                            labelId="demo-simple-steering"
                                            id="demo-simple-select"
                                            value={steering}
                                            label="steering"
                                            onChange={(e) => selectSteeringChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="brakes"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Brakes <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-brakes">Brakes</InputLabel>
                                        <Select
                                            labelId="demo-simple-brakes"
                                            id="demo-simple-select"
                                            value={brakes}
                                            label="brakes"
                                            onChange={(e) => selectBrakesChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="seatCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seat Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-seatCondition">Seat Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-seatCondition"
                                            id="demo-simple-select"
                                            value={seatCondition}
                                            label="seatCondition"
                                            onChange={(e) => selectSeatConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="bodyCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-bodyCondition">Body Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-bodyCondition"
                                            id="demo-simple-select"
                                            value={bodyCondition}
                                            label="bodyCondition"
                                            onChange={(e) => selectBodyConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="undercarriageCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Undercarriage Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-undercarriageCondition">Undercarriage Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-undercarriageCondition"
                                            id="demo-simple-select"
                                            value={undercarriageCondition}
                                            label="undercarriageCondition"
                                            onChange={(e) => selectUndercarriageConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="paintCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Paint Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-paintCondition">Paint Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-paintCondition"
                                            id="demo-simple-select"
                                            value={paintCondition}
                                            label="paintCondition"
                                            onChange={(e) => selectPaintConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lhFront"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Lh Front <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-lhFront">Lh Front</InputLabel>
                                        <Select
                                            labelId="demo-simple-lhFront"
                                            id="demo-simple-select"
                                            value={lhFront}
                                            label="lhFront"
                                            onChange={(e) => selectLhFrontChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rhFront"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rh Front <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-rhFront">Rh Front</InputLabel>
                                        <Select
                                            labelId="demo-simple-rhFront"
                                            id="demo-simple-select"
                                            value={rhFront}
                                            label="rhFront"
                                            onChange={(e) => selectRhFrontChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lhRear"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Lh Rear <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-lhRear">Lh Rear</InputLabel>
                                        <Select
                                            labelId="demo-simple-lhRear"
                                            id="demo-simple-select"
                                            value={lhRear}
                                            label="lhRear"
                                            onChange={(e) => selectLhRearChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rhRear"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rh Rear <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-rhRear">Rh Rear</InputLabel>
                                        <Select
                                            labelId="demo-simple-rhRear"
                                            id="demo-simple-select"
                                            value={rhRear}
                                            label="rhRear"
                                            onChange={(e) => selectRhRearChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="spare"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Spare <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-spare">Spare</InputLabel>
                                        <Select
                                            labelId="demo-simple-spare"
                                            id="demo-simple-select"
                                            value={spare}
                                            label="spare"
                                            onChange={(e) => selectSpareChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTotalTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Total Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Total Tyres"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={totalTyres}
                                            onChange={(e) => setTotalTyres(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="availableTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Available Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Available Tyre"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={availableTyre}
                                            onChange={(e) => setAvailableTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="missingTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Missing Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Missing Tyre"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={missingTyre}
                                            onChange={(e) => setMissingTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="spareTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Spare Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Spare Tyre"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={spareTyre}
                                            onChange={(e) => setSpareTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="battery"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Battery *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-battery">Battery</InputLabel>
                                        <Select
                                            labelId="demo-simple-battery"
                                            id="demo-simple-select"
                                            value={battery}
                                            label="battery"
                                            onChange={(e) => selectBatteryChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="batteryNumber"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Battery Number *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Battery Number"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={batteryNumber}
                                            onChange={(e) => setBatteryNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="starterMotor"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Starter Motor *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-starterMotor">Starter Motor</InputLabel>
                                        <Select
                                            labelId="demo-simple-starterMotor"
                                            id="demo-simple-select"
                                            value={starterMotor}
                                            label="starterMotor"
                                            onChange={(e) => setStarterMotor(e.target.value)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="instrumentPanel"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Instrument Panel *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-instrumentPanel">Instrument Panel</InputLabel>
                                        <Select
                                            labelId="demo-simple-instrumentPanel"
                                            id="demo-simple-select"
                                            value={instrumentPanel}
                                            label="instrumentPanel"
                                            onChange={(e) => selectInstrumentPanelChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="headLamp"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Head Lamp *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-headLamp">Head Lamp</InputLabel>
                                        <Select
                                            labelId="demo-simple-headLamp"
                                            id="demo-simple-select"
                                            value={headLamp}
                                            label="headLamp"
                                            onChange={(e) => selectHeadLampChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="tailLamp"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tail Lamp *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-tailLamp">Tail Lamp</InputLabel>
                                        <Select
                                            labelId="demo-simple-tailLamp"
                                            id="demo-simple-select"
                                            value={tailLamp}
                                            label="tailLamp"
                                            onChange={(e) => selectTailLampChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="workLampsNumbers"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Working Lamps Numbers *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Working Lamps Numbers"
                                            variant="outlined"
                                            className={
                                                "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={workLampsNumbers}
                                            onChange={(e) => setWorkLampsNumbers(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="workLampsCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Work Lamps Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-workLampsCondition">Working Lamps Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-workLampsCondition"
                                            id="demo-simple-select"
                                            value={workLampsCondition}
                                            label="workLampsCondition"
                                            onChange={(e) => selectWorkLampsConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="electricalsCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Electricals Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-electricalsCondition">Electricals Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-electricalsCondition"
                                            id="demo-simple-select"
                                            value={electricalsCondition}
                                            label="electricalsCondition"
                                            onChange={(e) => selectElectricalsConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="odometerType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-odometerType">Odometer Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-odometerType"
                                            id="demo-simple-select"
                                            value={odometerType}
                                            label="odometerType"
                                            onChange={(e) => selectOdometerTypeChange(e)}
                                        >
                                            {odometer.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyAttachmentImplement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Any Attachment/Implement *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-anyAttachmentImplement">Any Attachment/Implement</InputLabel>
                                        <Select
                                            labelId="demo-simple-anyAttachmentImplement"
                                            id="demo-simple-select"
                                            value={anyAttachmentImplement}
                                            label="anyAttachmentImplement"
                                            onChange={(e) => selectAnyAttachmentImplementChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="nameOfAttachmentImplement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Name Of Attachment/Implement *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-nameOfAttachmentImplement">Name Of Attachment/Implement</InputLabel>
                                        <Select
                                            labelId="demo-simple-nameOfAttachmentImplement"
                                            id="demo-simple-select"
                                            value={nameOfAttachmentImplement}
                                            label="nameOfAttachmentImplement"
                                            onChange={(e) => selectNameOfAttachmentImplementChange(e)}
                                        >
                                            {attachment.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fOthers"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Others *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-others">Others</InputLabel>
                                        <Select
                                            labelId="demo-simple-others"
                                            id="demo-simple-select"
                                            value={others}
                                            label="others"
                                            onChange={(e) => selectOthersChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="airCompressor"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Air Compressor *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-airCompressor">Air Compressor</InputLabel>
                                        <Select
                                            labelId="demo-simple-airCompressor"
                                            id="demo-simple-select"
                                            value={airCompressor}
                                            label="airCompressor"
                                            onChange={(e) => selectAirCompressorChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="toolKit"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tool Kit *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-toolKit">Tool Kit</InputLabel>
                                        <Select
                                            labelId="demo-simple-toolKit"
                                            id="demo-simple-select"
                                            value={toolKit}
                                            label="toolKit"
                                            onChange={(e) => selectToolKitChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lift"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Lift *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-lift">Lift</InputLabel>
                                        <Select
                                            labelId="demo-simple-lift"
                                            id="demo-simple-select"
                                            value={lift}
                                            label="lift"
                                            onChange={(e) => selectLiftChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="tipper"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tipper *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-tipper">Tipper</InputLabel>
                                        <Select
                                            labelId="demo-simple-tipper"
                                            id="demo-simple-select"
                                            value={tipper}
                                            label="tipper"
                                            onChange={(e) => selectTipperStatusChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="hitch"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Hitch *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-hitch">Hitch</InputLabel>
                                        <Select
                                            labelId="demo-simple-hitch"
                                            id="demo-simple-select"
                                            value={hitch}
                                            label="hitch"
                                            onChange={(e) => selectHitchChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="powerSteering"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        PowerSteering *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-powerSteering">Power Steering</InputLabel>
                                        <Select
                                            labelId="demo-simple-powerSteering"
                                            id="demo-simple-select"
                                            value={powerSteering}
                                            label="powerSteering"
                                            onChange={(e) => selectPowerSteeringChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="whetherAccidental"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Whether Accidental *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-whetherAccidental">Whether Accidental</InputLabel>
                                        <Select
                                            labelId="demo-simple-whetherAccidental"
                                            id="demo-simple-select"
                                            value={whetherAccidental}
                                            label="whetherAccidental"
                                            onChange={(e) => selectWhetherAccidentalChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="levelOfMaintenance"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Level Of Maintenance *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-levelOfMaintenance">Level Of Maintenance</InputLabel>
                                        <Select
                                            labelId="demo-simple-levelOfMaintenance"
                                            id="demo-simple-select"
                                            value={levelOfMaintenance}
                                            label="levelOfMaintenance"
                                            onChange={(e) => selectLevelOfMaintenanceChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="otherRepairsIfAny"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Other RepairsIfAny *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-otherRepairsIfAny">Other Repairs If Any</InputLabel>
                                        <Select
                                            labelId="demo-simple-otherRepairsIfAny"
                                            id="demo-simple-select"
                                            value={otherRepairsIfAny}
                                            label="otherRepairsIfAny"
                                            onChange={(e) => selectOtherRepairsIfAnyChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Your Remark On Vehicle *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-yourRemark">Your Remark</InputLabel>
                                        <Select
                                            labelId="demo-simple-yourRemark"
                                            id="demo-simple-select"
                                            value={yourRemark}
                                            label="yourRemark"
                                            onChange={(e) => selectRemarkChange(e)}
                                        >
                                            {selectRemarkType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fExShowRoomPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ex-ShowRoom Price *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Ex-ShowRoom Price"
                                            variant="outlined"
                                            className={
                                                exShowRoomPriceError === ""
                                                    ? `textfield`
                                                    : `textfield error_class`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={exShowRoomPrice}
                                            onChange={(e) => setExShowRoomPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEstimatedPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Estimated Price *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Estimated Price"
                                            variant="outlined"
                                            className={
                                                estimatedPriceError === ""
                                                    ? `textfield`
                                                    : `textfield error_class`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={estimatedPrice}
                                            onChange={(e) => setEstimatedPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px' }}>
                        <button
                            onClick={(e) => cancelClick(e)}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                            style={{ outline: 'none', border: 'none', color: '#fff', backgroundColor: 'red' }}
                        >
                            <i className="ph-bold ph-x text-lg" /> cancel
                        </button>
                        {
                            buttonLoading ?
                                <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    style={{ background: "grey", outline: 'none', border: 'none' }}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Submitting
                                </button>
                                : <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    onClick={(e) => handlesubmit(e)}
                                    style={{ background: "#4D44B5", outline: 'none', border: 'none' }}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Submit
                                </button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddFarmEquipmentCase;
