import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { updateIndividualCase } from "../../redux/Cases/CaseSlice";
import moment from "moment-timezone";
import { Modal, Button } from "antd";
import { Baseurl,Imageurl } from "../../config/BaseUrl";
import axios from "axios";

const PendingCase = () => {
    const { loginData } = useSelector((store) => store.auth);
    const { pendingCases } = useSelector((store) => store.cases);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [filteredCase, setFilteredCase] = useState([]);

    const [isModalCaseOpen, setIsModalCaseOpen] = useState(false);
    const [selectedDeleteCase, setSelectedDeleteCase] = useState('');

    const showDeleteModal = (data) => {
        setSelectedDeleteCase(data)
        setIsModalCaseOpen(true);
    };

    const handleDeleteOk = () => {
        setIsModalCaseOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsModalCaseOpen(false);
    };
    const updateDeleteClick = async () => {
        const url = `${Baseurl}/api/v1/cases/deletecase/${selectedDeleteCase._id}`
        const resp = await axios.delete(url)
        if (resp.data.success) {
            setIsModalCaseOpen(false);
            setSelectedDeleteCase('')
            window.location.reload()
        }
    }


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCase, setSelectedCase] = useState('');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const updateClick = async () => {
        const formData = {
            _id: selectedCase._id,
            status: 1,
            statusText: "Case Id Created"
        }
        const newcase = await dispatch(updateIndividualCase(formData))
        if (newcase.payload.success) {
            setIsModalOpen(false);
            window.location.reload()
        }

    }

    useEffect(() => {
        const getAllReports = pendingCases.slice().reverse()
        if (loginData?.role === 'businessAssociate') {
            const data = getAllReports.filter((indCase) =>
                loginData.permittedState.includes(indCase.state) && indCase.businessAssociate?.id === loginData._id
            )
            setFilterData(data)
            setFilteredCase(data)
        } else {
            setFilterData(getAllReports)
            setFilteredCase(getAllReports)
        }
    }, [pendingCases])


    const handleSearch = (e) => {
        if (search.trim() !== '') {
            const filterBySearch = filterData.filter((indData) =>
                indData.regdNo.toUpperCase() === search.trim().toUpperCase()
            )
            setFilterData(filterBySearch)
        } else {
            alert('enter vehicle no.')
        }
    }

    const handleReset = () => {
        setFilterData(filteredCase)
        setSearch('')
    }
    const handleSubmit = async (admin) => {
        navigate(`/admin/uploadphotos/${admin._id}`)
    };
    const handleWithdraw = async (data) => {
        setSelectedCase(data)
        showModal()
    }
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
                            <input type="text" placeholder="Enter vehicle no. " value={search} onChange={(e) => setSearch(e.target.value)} style={{ border: '1px solid #dd5536', borderRadius: '10px' }} />
                            <button onClick={() => handleSearch()} className="text-white px-4 py" style={{ padding: '10px 15px', backgroundColor: '#e6632e', borderRadius: '10px' }}>Search </button>
                            <button onClick={() => handleReset()} className="text-white px-4 py" style={{ padding: '10px 15px', backgroundColor: '#E20808', borderRadius: '10px' }}>Reset </button>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50">
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                State
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Reg No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Status
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Agent
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Action
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Action
                                            </th>
                                            {
                                                loginData?.role !== 'businessAssociate' &&
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                                >
                                                    Action
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filterData &&
                                            filterData
                                                .map((admin, index) => (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            <b>{admin.caseId}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-center whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            <h6 className="text-sm font-semibold text-default-700">
                                                                {admin.name.length > 30 ? (
                                                                    <>{admin.name.slice(0, 30)}...</>
                                                                ) : (
                                                                    <>{admin.name.slice(0, 30)}</>
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.mobile}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {moment(admin.createdAt)
                                                                .tz("Asia/Kolkata")
                                                                .format("YYYY-MM-DD HH:mm:ss")}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.email.length > 18 ? (
                                                                <>{admin.email.slice(0, 18)}...</>
                                                            ) : (
                                                                <>{admin.email.slice(0, 18)}</>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.state}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.regdNo}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.statusText}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            {admin.agent && (
                                                                <>
                                                                    {admin.agent.name}
                                                                </>
                                                            )}
                                                            {admin.executive && (
                                                                <>
                                                                    {admin.executive.name}
                                                                </>
                                                            )}
                                                            {admin.customer && (
                                                                <>
                                                                    {admin.customer.name}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            <div className="flex items-center justify-center gap-2">
                                                                <button className="inline-flex items-center justify-center h-9 w-20 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                                                    onClick={() => handleWithdraw(admin)}
                                                                >
                                                                    Withdraw
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                                                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                        >
                                                            <div className="flex items-center justify-center gap-2">
                                                                <button className="inline-flex items-center justify-center h-9 w-20 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                                                    onClick={() => handleSubmit(admin)}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </td>
                                                        {
                                                            loginData?.role !== 'businessAssociate' &&
                                                            <td className="whitespace-nowrap py-1 px-3 text-center text-sm font-medium"
                                                                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                                                            >
                                                                <div className="flex items-center justify-center gap-2">
                                                                    <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                                                        onClick={() => showDeleteModal(admin)}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
                            <h6 className="text-default-600">Showing {filterData.length} results</h6>
                        </div>
                    </div>
                </div>
                <Modal
                    title="Case Withdraw"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={[
                        <Button
                            style={{
                                padding: "10px 15px",
                                color: "#fff",
                                backgroundColor: "#FF0000",
                            }}
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </Button>,
                        <Button
                            style={{
                                padding: "10px 15px",
                                color: "#fff",
                                backgroundColor: "#04681e",
                            }}
                            onClick={(e) => updateClick(e)}
                        >
                            Confirm
                        </Button>,
                    ]}
                >
                    <p>Are you sure you want to withdraw the case of<span style={{ color: 'red' }}> {selectedCase.requesterName}</span></p>
                </Modal>
                <Modal title="Delete Case"
                    open={isModalCaseOpen}
                    onOk={handleDeleteOk}
                    onCancel={handleDeleteCancel}
                    footer={[
                        <Button
                            style={{
                                padding: "10px 15px",
                                color: "#fff",
                                backgroundColor: "#FF0000",
                            }}
                            onClick={() => handleDeleteCancel()}
                        >
                            Cancel
                        </Button>,
                        <Button
                            style={{
                                padding: "10px 15px",
                                color: "#fff",
                                backgroundColor: "#04681e",
                            }}
                            onClick={(e) => updateDeleteClick(e)}
                        >
                            Confirm
                        </Button>,
                    ]}
                >
                    <p>Are you sure you want to delete case of {selectedDeleteCase.requesterName}</p>
                </Modal>
            </div>
        </>
    )
}

export default PendingCase